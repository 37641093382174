/*-----------------------------------------------------------------------------------
    
    Template Name: Young - eCommerce HTML Template
    Version: 1.2
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Section title style
    5. Product style
    6. Banner style
    7. Video style
    8. Blog style
    9. Subscribe style
    10. Footer style
    11. Instagram style
    12. Service style
    13. Categories style
    14. Brand logo style
    15. Testimonial style
    16. Bookstore style
    17. Others style
    18. About style
    19. Product details style
    20. Breadcrumb style
    22. Shop style
    23. Sidebar style
    24. Cart style
    25. Order tracking style
    26. Login register style
    27. My account style
    28. Checkout style
    29. Coming soon style
    30. Contact style
    31. Faq style
    32. Compare style
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Fredoka+One|Playfair+Display:400,400i,700,700i,900|Poppins:200,300,400,500,600,700,800|Rufina:400,700|Zilla+Slab:300,400,500,600,700&display=swap");
/*-- Common Style --*/
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.row {
  margin-right: calc(var(--bs-gutter-x) * -.6);
  margin-left: calc(var(--bs-gutter-x) * -.6);
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .6);
  padding-left: calc(var(--bs-gutter-x) * .6);
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-right: var(--bs-gutter-x, 0.9rem);
  padding-left: var(--bs-gutter-x, 0.9rem);
}

:root {
  scroll-behavior: unset;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #393939;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #393939;
  font-weight: 400;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #393939;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #393939;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.position-relative {
  position: relative;
}

.border-top-1 {
  border-top: 1px solid #d8d8d8;
}

.border-top-2 {
  border-top: 1px solid #b6b6b6;
}

.border-top-3 {
  border-top: 1px solid #8b8b8b;
}

.border-top-4 {
  border-top: 1px solid #454545;
}

.round-border-1 {
  border: 1px solid #979797;
}

.border-bottom-1 {
  border-bottom: 1px solid #d8d8d8;
}

a:hover {
  color: #ff6e21;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .mt-100 {
    margin-top: 50px;
  }
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-175 {
  margin-top: 175px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-37 {
  margin-bottom: 37px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-85 {
  padding-top: 85px;
}

@media only screen and (max-width: 767px) {
  .pt-85 {
    padding-top: 45px;
  }
}

.pt-80 {
  padding-top: 80px;
}

@media only screen and (max-width: 767px) {
  .pt-80 {
    padding-top: 50px;
  }
}

.pt-95 {
  padding-top: 95px;
}

@media only screen and (max-width: 767px) {
  .pt-95 {
    padding-top: 45px;
  }
}

.pt-90 {
  padding-top: 90px;
}

@media only screen and (max-width: 767px) {
  .pt-90 {
    padding-top: 40px;
  }
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 50px;
  }
}

.pt-105 {
  padding-top: 105px;
}

@media only screen and (max-width: 767px) {
  .pt-105 {
    padding-top: 45px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-110 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 50px;
  }
}

.pt-115 {
  padding-top: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-115 {
    padding-top: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-115 {
    padding-top: 45px;
  }
}

.pt-120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-120 {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 50px;
  }
}

.pt-125 {
  padding-top: 125px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-125 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-125 {
    padding-top: 45px;
  }
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-130 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-130 {
    padding-top: 50px;
  }
}

.pt-135 {
  padding-top: 135px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .pb-90 {
    padding-bottom: 40px;
  }
}

.pb-95 {
  padding-bottom: 95px;
}

@media only screen and (max-width: 767px) {
  .pb-95 {
    padding-bottom: 45px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 50px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-110 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 40px;
  }
}

.pb-115 {
  padding-bottom: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-115 {
    padding-bottom: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-115 {
    padding-bottom: 45px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 50px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-125 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-125 {
    padding-bottom: 45px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-130 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-130 {
    padding-bottom: 50px;
  }
}

.pb-135 {
  padding-bottom: 135px;
}

@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 86px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

/*************************
    Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #737373;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #737373;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #737373;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-md-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-5.custom-col-xs-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-sm-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #262626;
  color: #fff;
  right: 50px;
  bottom: 53px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
    right: 20px;
  }
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 22px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}

#scrollUp:hover {
  background-color: #ff6e21;
}

.height-100vh {
  height: 100vh;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

/*--
    Background Color
------------------------------------------*/
.bg-white {
  background-color: #fff;
}

.bg-gray {
  background-color: #f9f9f9;
}

.bg-gray-2 {
  background-color: #f9faff;
}

.bg-gray-3 {
  background-color: #fffdf9;
}

.bg-gray-4 {
  background-color: #f2f7f8;
}

.bg-black {
  background-color: #1b1b1b;
}

.bg-black-2 {
  background-color: #161616;
}

.bg-blue {
  background-color: #1631bd;
}

.bg-tan {
  background-color: #e8e3d7;
}

.bg-light-green {
  background-color: #e6f6f2;
}

.bg-light-pink {
  background-color: #fcf5f5;
}

.bg-light-apricot {
  background-color: #ffc68b;
}

.bg-light-yellow {
  background-color: #fff6e1;
}

.bg-light-yellow-2 {
  background-color: #fff0cd;
}

.bg-theme-color-yellow {
  background-color: #ff6e21;
}

.bg-theme-color-yellow-2 {
  background-color: #f2c456;
}

.bg-theme-color-green-2 {
  background-color: #52e3b9;
}

.default-overlay {
  position: relative;
}

.default-overlay:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .res-white-overly-xs {
    position: relative;
  }
  .res-white-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 9;
  }
}

@media only screen and (max-width: 767px) {
  .res-black-overly-xs {
    position: relative;
  }
  .res-black-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000;
    opacity: .5;
    z-index: 9;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-white-overly-md {
    position: relative;
  }
  .res-white-overly-md:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

.padding-55-row-col .row {
  margin-right: -27.5px;
  margin-left: -27.5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-55-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-55-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-55-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-55-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-55-row-col .row div[class^="col-"] {
  padding-left: 27.5px;
  padding-right: 27.5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-55-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-55-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-55-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-55-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-60-row-col .row {
  margin-right: -30px;
  margin-left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-60-row-col .row div[class^="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-37-row-col .row {
  margin-right: -18.5px;
  margin-left: -18.5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-37-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-37-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-37-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-37-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-37-row-col .row div[class^="col-"] {
  padding-left: 18.5px;
  padding-right: 18.5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-37-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-37-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-37-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-37-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-46-row-col .row {
  margin-right: -23px;
  margin-left: -23px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-46-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-46-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-46-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-46-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-46-row-col .row div[class^="col-"] {
  padding-left: 23px;
  padding-right: 23px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-46-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-46-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-46-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-46-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-20-row-col .row {
  margin-right: -10px;
  margin-left: -10px;
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-20-row-col .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-52-row-col .row {
  margin-right: -26px;
  margin-left: -26px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-52-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-52-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-52-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-52-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-52-row-col .row div[class^="col-"] {
  padding-left: 26px;
  padding-right: 26px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-52-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-52-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-52-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-52-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-44-row-col .row {
  margin-right: -22px;
  margin-left: -22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-44-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-44-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-44-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-44-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-44-row-col .row div[class^="col-"] {
  padding-left: 22px;
  padding-right: 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-44-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-44-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-44-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-44-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.custom-d-flex {
  display: flex;
}

.height-100-percent {
  height: 100%;
}

.width-100-percent {
  width: 100%;
}

.section-padding-1 .container-fluid {
  padding: 0 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-2 .container-fluid {
  padding: 0 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-3 .container-fluid {
  padding: 0 200px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-3 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-3 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-3 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-4 .container-fluid {
  padding: 0 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-4 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-4 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-4 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-5 .container-fluid {
  padding: 0 145px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-5 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-5 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-5 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-6 .container-fluid {
  padding: 0 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-6 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-6 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-6 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-7 .container-fluid {
  padding: 0 230px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-7 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-7 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-7 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-8 .container-fluid {
  padding: 0 160px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-8 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-8 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-8 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-9 .container-fluid {
  padding: 0 340px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-9 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-9 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-9 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-9 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-9 .container-fluid {
    padding: 0 15px;
  }
}

.btn-hover a {
  position: relative;
  transition: all .5s ease-in-out 0s;
}

.btn-hover a:hover {
  color: #fff;
}

.btn-hover a::before, .btn-hover a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}

.btn-hover a::after {
  width: 0;
  left: auto;
  right: 0;
}

.btn-hover a.bg-black-hover::after {
  background: #000;
}

.btn-hover a.bg-green-hover::after {
  background: #3ad9b1;
}

.btn-hover a.theme-color-yellow-2-hover::after {
  background: #f2c456;
}

.btn-hover a.theme-color-yellow::after {
  background: #ff6e21;
}

.btn-hover a.black-color::after {
  background: #373737;
}

.btn-hover a.white-color::after {
  background: #fff;
}

.btn-hover a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

.btn-hover.btn-hover-radious a::before, .btn-hover.btn-hover-radious a::after {
  border-radius: 50px;
}

.btn-hover.hover-border-none a.black-color::after {
  border-radius: 5px;
}

.btn-hover.hover-border-none a:hover {
  border: none;
}

.default-mlr-120 {
  margin: 0 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .default-mlr-120 {
    margin: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .default-mlr-120 {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .default-mlr-120 {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .default-mlr-120 {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .default-mlr-120 {
    margin: 0 15px;
  }
}

.btn-style-3 a {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-3 a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a {
    font-size: 14px;
  }
}

.btn-style-3 a.font-dec {
  font-size: 12px;
}

.btn-style-3 a.bs3-weight-dec {
  font-weight: 400;
}

.btn-style-3 a.bs3-white-text {
  color: #fff;
}

.btn-style-3 a.bs3-white-text:hover {
  color: #393939;
}

.btn-style-3 a.bs3-black-text {
  color: #1b1b1b;
}

.btn-style-3 a.bs3-black-text:hover {
  color: #fff;
}

.btn-style-3 a.bs3-gray-text {
  color: #8b8b8b;
}

.btn-style-3 a.bs3-gray-text:hover {
  color: #fff;
}

.btn-style-3 a.bs3-yellow-bg {
  background-color: #ff6e21;
}

.btn-style-3 a.bs3-pink-bg {
  background-color: #ef8da1;
}

.btn-style-3 a.bs3-gray-bg {
  background-color: #e8e8e8;
}

.btn-style-3 a.bs3-white-bg {
  background-color: #fff;
}

.btn-style-3 a.bs3-green3-bg {
  background-color: #3cd53f;
}

.btn-style-3 a.bs3-ptb {
  padding: 21px 56px 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-3 a.bs3-ptb {
    padding: 15px 40px 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-3 a.bs3-ptb {
    padding: 15px 40px 19px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.bs3-ptb {
    padding: 13px 35px 17px;
  }
}

.btn-style-3 a.bs3-ptb-2 {
  padding: 12px 30px 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-3 a.bs3-ptb-2 {
    padding: 10px 20px 13px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.bs3-ptb-2 {
    padding: 9px 20px 11px;
  }
}

.btn-style-3 a.bs3-ptb-3 {
  padding: 7px 25px 11px;
}

.btn-style-3 a.bs3-ptb-4 {
  padding: 20px 54px 24px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.bs3-ptb-4 {
    padding: 16px 40px 20px;
  }
}

.btn-style-3 a.bs3-ptb-4.hover-text-white:hover {
  color: #fff;
}

.btn-style-3 a.bs3-ptb-5 {
  padding: 21px 64px 21px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.bs3-ptb-5 {
    padding: 15px 40px 15px;
  }
}

.btn-style-3 a.bs3-ptb-5.hover-text-white:hover {
  color: #fff;
}

.btn-style-3 a.bs3-border-radius {
  border-radius: 50px;
}

.btn-style-3 a.bs3-border-2-pixel {
  border: 2px solid #1b1b1b;
  transition: all 0.4s;
}

.btn-style-3 a.bs3-border-2-pixel:hover {
  border: 2px solid #f2c456;
}

.btn-hover-2 a {
  position: relative;
}

.btn-hover-2 a::after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50px;
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.4s;
}

.btn-hover-2 a.ptb-2-white-hover::after {
  background: #fff;
}

.btn-hover-2 a.ptb-3-black-hover::after {
  background: #1b1b1b;
}

.btn-hover-2 a.ptb-2-theme-hover::after {
  background: #ff6e21;
}

.btn-hover-2 a.ptb-2-yellow-2-hover::after {
  background: #f2c456;
}

.btn-hover-2 a.bs3-hover-green2-bg::after {
  background: #52e3b9;
}

.btn-hover-2 a:hover::after {
  width: 100%;
  opacity: 1;
}

.btn-style-4 a {
  display: inline-block;
  line-height: 1;
  border: 2px solid #1d1d1d;
  color: #1d1d1d;
  font-weight: bold;
}

.btn-style-4.btn-style-4-ptb1 a {
  padding: 14px 26px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-4.btn-style-4-ptb1 a {
    padding: 10px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-4.btn-style-4-ptb1 a {
    padding: 10px 12px;
  }
}

/* img bounce */
@-webkit-keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* img bounce 2 */
@-webkit-keyframes bounce-img-2 {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes bounce-img-2 {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* img bounce 3 */
@-webkit-keyframes bounce-img-3 {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes bounce-img-3 {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.jarallax-img {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  .res-xs-mt-10 {
    margin-top: 10px;
  }
  .res-xs-mt-30 {
    margin-top: 30px;
  }
  .res-xs-mt-40 {
    margin-top: 40px;
  }
}

/*------- img move 1 -------*/
.move-1 {
  position: absolute;
  top: 5%;
  left: 3%;
  animation: moveOne .3s infinite linear;
}

@-webkit-keyframes moveOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes moveOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

/*-------- 2. Header style ---------*/
.header-small-device {
  display: none;
  padding: 25px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-small-device {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-small-device {
    display: block;
  }
}

.header-large-device {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-large-device {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-large-device {
    display: none;
  }
}

.logo a img {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-res-lg a img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-logo-width a img {
    width: 110px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mobile-logo-width a img {
    width: 130px;
  }
}

.header-ptb {
  padding: 50px 0;
}

.header-ptb-2 {
  padding: 63px 0 63px;
}

.search-wrap form {
  position: relative;
}

.search-wrap form input {
  color: #393939;
  height: 30px;
  padding: 0 40px 0 0;
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.search-wrap form input::-moz-input-placeholder {
  color: #393939;
  opacity: 1;
}

.search-wrap form input::-webkit-input-placeholder {
  color: #393939;
  opacity: 1;
}

.search-wrap form button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  padding: 0;
  transition: all .3s ease 0s;
}

.search-wrap form button i {
  font-size: 30px;
  color: #393939;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-wrap form button i {
    font-size: 24px;
  }
}

.search-wrap form button i:hover {
  color: #666;
}

.search-wrap.search-width-1 {
  width: 220px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-wrap.search-width-1 {
    width: 200px;
  }
}

.search-wrap.search-width-2 {
  width: 185px;
}

.search-wrap.search-wrap-2-mrg {
  margin: 0 auto;
}

.search-wrap.search-wrap-2 form input {
  height: 53px;
  font-size: 14px;
  border-bottom: 1px solid #d8d8d8;
}

.search-wrap.search-wrap-2 form button i {
  font-size: 20px;
}

.header-right-wrap.header-right-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 1;
}

.header-right-wrap.header-center-flex {
  display: flex;
  justify-content: center;
  line-height: 1;
}

.header-right-wrap.header-right-wrap-mrg {
  margin: 64px 0 49px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-right-wrap.header-right-wrap-mrg {
    margin: 44px 0 29px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-right-wrap.header-right-wrap-mrg {
    margin: 44px 0 29px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap.header-right-wrap-mrg {
    margin: 34px 0 19px;
  }
}

.header-right-wrap .same-style {
  margin-left: 64px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap .same-style {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-right-wrap .same-style {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .header-right-wrap .same-style {
    margin-left: 24px;
  }
}

.header-right-wrap .same-style:first-child {
  margin-left: 0;
}

.header-right-wrap .same-style a {
  font-size: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap .same-style a {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .header-right-wrap .same-style a {
    font-size: 24px;
  }
}

.header-right-wrap .same-style a:hover {
  color: #666;
}

.header-right-wrap .same-style.same-style-mrg-dec {
  margin-left: 38px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-right-wrap .same-style.same-style-mrg-dec {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap .same-style.same-style-mrg-dec {
    margin-left: 20px;
  }
}

.header-right-wrap .same-style.same-style-mrg-dec:first-child {
  margin-left: 0;
}

.header-right-wrap .same-style.same-style-mrg-dec-2 {
  margin-left: 32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-right-wrap .same-style.same-style-mrg-dec-2 {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap .same-style.same-style-mrg-dec-2 {
    margin-left: 20px;
  }
}

.header-right-wrap .same-style.same-style-mrg-dec-2:first-child {
  margin-left: 0;
}

.header-right-wrap .same-style.same-style-font-dec a {
  font-size: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap.hr-mrg-handmade .same-style-mrg-dec {
    margin-left: 13px;
  }
  .header-right-wrap.hr-mrg-handmade .same-style-mrg-dec:first-child {
    margin-left: 0;
  }
}

.free-delivery h4 {
  font-weight: 500;
  font-size: 14px;
  color: #1d1d1d;
  margin: 0;
}

.free-delivery h4 span {
  color: #ff6e21;
}

.free-delivery h4 span.free-delivery-black {
  color: #1d1d1d;
}

.same-style-2 {
  margin-left: 27px;
}

.same-style-2 ul > li {
  position: relative;
}

.same-style-2 ul > li > a {
  font-weight: 500;
  color: #1d1d1d;
  font-size: 12px;
  display: inline-block;
  line-height: 30px;
}

.same-style-2 ul > li > a i {
  font-size: 12px;
  margin-left: 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .same-style-2 ul > li > a i {
    margin-left: 0px;
  }
}

.same-style-2 ul > li:hover > a {
  color: #ff6e21;
}

.same-style-2 ul > li ul {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100px;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #262626;
  padding: 27px 0px;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .same-style-2 ul > li ul {
    right: 0;
    left: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .same-style-2 ul > li ul {
    right: 0;
    left: auto;
    width: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .same-style-2 ul > li ul {
    right: 0;
    left: auto;
    width: 90px;
  }
}

.same-style-2 ul > li ul > li {
  display: block;
  line-height: 1;
}

.same-style-2 ul > li ul > li > a {
  display: block;
  color: #ddd;
  padding: 0 25px 20px;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .same-style-2 ul > li ul > li > a {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .same-style-2 ul > li ul > li > a {
    padding: 0 15px 20px;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .same-style-2 ul > li ul > li > a {
    padding: 0 15px 20px;
    text-align: center;
  }
}

.same-style-2 ul > li ul > li > a:hover {
  color: #ff6e21;
}

.same-style-2 ul > li ul > li:last-child a {
  padding: 0 25px 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .same-style-2 ul > li ul > li:last-child a {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .same-style-2 ul > li ul > li:last-child a {
    padding: 0 15px 0px;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .same-style-2 ul > li ul > li:last-child a {
    padding: 0 15px 0px;
    text-align: center;
  }
}

.same-style-2 ul > li:hover ul {
  transform: rotateX(0deg);
  visibility: visible;
}

.header-cll span {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-cll span {
    font-size: 20px;
  }
}

.header-cll span i {
  font-size: 42px;
  color: #ff6e21;
  margin-right: 18px;
}

.header-cll span i.black {
  color: #393939;
}

.hb-negative-mrg {
  margin-bottom: -80px;
  position: relative;
  z-index: 99;
}

.main-menu > nav > ul > li {
  display: inline-block;
  position: relative;
}

.main-menu > nav > ul > li > a {
  font-weight: 600;
  letter-spacing: 1.82px;
  display: inline-block;
  font-size: 11px;
  position: relative;
}

.main-menu > nav > ul > li > a::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 37%;
  bottom: 0;
  width: 20%;
  height: 2px;
  content: "";
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}

.main-menu > nav > ul > li > a.active::after {
  width: calc(100%);
  opacity: 1;
}

.main-menu > nav > ul > li > ul {
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #262626;
  position: absolute;
  top: 100%;
  left: 0;
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  z-index: 9999;
}

.main-menu > nav > ul > li > ul.sub-menu-width {
  width: 220px;
  padding: 39px 0 43px;
}

.main-menu > nav > ul > li > ul.sub-menu-width li {
  display: block;
  padding: 0 15px 14px 30px;
  position: relative;
  text-align: left;
}

.main-menu > nav > ul > li > ul.sub-menu-width li:last-child {
  padding-bottom: 0;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a {
  display: block;
  color: #D0D0D0;
  font-size: 12px;
  font-weight: 300;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a i {
  float: right;
  font-size: 12px;
  color: #999;
  font-weight: 600;
  position: relative;
  top: 6px;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a:hover {
  color: #ff6e21;
  text-decoration: underline;
}

.main-menu > nav > ul > li > ul.sub-menu-width li ul.lavel-menu {
  padding: 36px 0px 40px;
  width: 220px;
  transition: all 250ms ease-out;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #262626;
  position: absolute;
  top: -17px;
  left: 100%;
  margin: 0;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.sub-menu-width li ul.lavel-menu {
    width: 180px;
  }
}

.main-menu > nav > ul > li > ul.sub-menu-width li:hover > ul.lavel-menu {
  visibility: visible;
  opacity: 1;
}

.main-menu > nav > ul > li > ul.mega-menu-width1 {
  width: 450px;
}

.main-menu > nav > ul > li > ul.mega-menu-width3 {
  width: 625px;
}

.main-menu > nav > ul > li > ul.mega-menu-width3 > li.mega-menu-sub-width33 {
  width: 33.333%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 {
  padding: 40px 10px 33px 40px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li {
  text-align: left;
  padding-right: 30px;
  float: left;
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li.mega-menu-sub-width50 {
  width: 50%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li a.menu-title {
  color: #fff;
  font-size: 14px;
  padding: 0px 0px 12px 0px;
  position: relative;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li a.menu-title:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  bottom: -2px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul {
  margin: 28px 0 0;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li {
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li a {
  color: #D0D0D0;
  font-size: 12px;
  display: block;
  padding: 8px 0px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li a:hover {
  color: #ff6e21;
  text-decoration: underline;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 {
  width: 1170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2 {
    width: 950px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg1 {
  left: -186px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -188px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -188px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -148px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg2 {
  left: -250px;
}

.main-menu > nav > ul > li > ul.menu-negative-mrg3 {
  left: -504px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -481px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -482px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -424px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg4 {
  left: -480px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg4 {
    left: -450px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg4 {
    left: -472px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg4 {
    left: -366px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg5 {
  left: -395px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg5 {
    left: -395px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg5 {
    left: -440px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg5 {
    left: -349px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 {
  padding: 52px 40px 45px 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 {
    padding: 52px 30px 45px 0px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li {
  text-align: left;
  float: left;
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width20 {
  width: 20%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width20 {
    width: 19%;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width22 {
  width: 22.5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width22 {
    width: 27.5%;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width37 {
  width: 37.5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width37 {
    width: 34.5%;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li a.menu-title {
  color: #fff;
  font-size: 14px;
  padding: 0px 30px 12px 30px;
  position: relative;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li a.menu-title:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  bottom: -2px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li > ul {
  margin: 28px 0 0;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li > ul li {
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li > ul li a {
  color: #D0D0D0;
  font-size: 12px;
  display: block;
  padding: 8px 30px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li > ul li a:hover {
  color: #ff6e21;
  text-decoration: underline;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap {
  position: relative;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap.default-overlay::before {
  background-color: #000;
  opacity: .2;
  z-index: 1;
  pointer-events: none;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap a img {
  width: 100%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap .banner-menu-content {
  position: absolute;
  left: 41px;
  bottom: 35px;
  z-index: 9;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap .banner-menu-content h2 {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0px;
  line-height: 45px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap .banner-menu-content h2 {
    font-size: 35px;
    line-height: 50px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .banner-menu-content-wrap:hover .banner-menu-content h2 {
  letter-spacing: 1px;
}

.main-menu > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.main-menu > nav > ul > li:hover > a::after {
  width: calc(100%);
  opacity: 1;
}

.main-menu.menu-text-white > nav > ul > li > a {
  color: #fff;
}

.main-menu.menu-text-white > nav > ul > li > a::after {
  background-color: #fff;
}

.main-menu.menu-text-black > nav > ul > li > a {
  color: #393939;
}

.main-menu.menu-text-black > nav > ul > li > a::after {
  background-color: #393939;
}

.main-menu.menu-lh-1 > nav > ul > li > a {
  line-height: 80px;
}

.main-menu.menu-lh-2 > nav > ul > li > a {
  line-height: 60px;
}

.main-menu.menu-lh-3 > nav > ul > li > a {
  line-height: 167px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-lh-3 > nav > ul > li > a {
    line-height: 120px;
  }
}

.main-menu.menu-lh-3 > nav > ul > li > a::after {
  top: 20%;
}

.main-menu.menu-lh-4 > nav > ul > li > a {
  line-height: 148px;
}

.main-menu.kid-main-menu > nav > ul > li > a {
  font-size: 20px;
  font-family: "Fredoka One", cursive;
  color: #393939;
  font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.kid-main-menu > nav > ul > li > a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.kid-main-menu > nav > ul > li > a {
    font-size: 14px;
  }
}

.main-menu.kid-main-menu > nav > ul > li > a:hover {
  color: #ff6e21;
}

.main-menu.menu-border-none > nav > ul > li > a::after {
  display: none;
}

.main-menu.main-menu-padding-1 > nav > ul > li {
  padding: 0 24px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 16px;
  }
}

.main-menu.main-menu-padding-2 > nav > ul > li {
  padding: 0 48px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-2 > nav > ul > li {
    padding: 0 28px 0 0;
  }
}

.main-menu.main-menu-padding-3 > nav > ul > li {
  padding: 0 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.main-menu-padding-3 > nav > ul > li {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-padding-3 > nav > ul > li {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-3 > nav > ul > li {
    padding: 0 15px;
  }
}

.main-menu.main-menu-padding1 {
  padding-left: 25px;
}

.category-menu-wrap {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-menu-wrap {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .category-menu-wrap {
    margin-top: 25px;
  }
}

.category-menu-wrap h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  margin: 0;
  line-height: 80px;
}

.category-menu-wrap h3.font-width-inc {
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-menu-wrap h3 {
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .category-menu-wrap h3 {
    line-height: 50px;
    font-size: 17px;
  }
}

.category-menu-wrap h3 a {
  color: #fff;
}

.category-menu-wrap h3 a i {
  font-size: 30px;
  position: relative;
  top: 7px;
  margin-right: 13px;
}

.category-menu-wrap h3 a:hover {
  color: #ddd;
}

.clickalbe-sidebar-wrapper-style-1 {
  position: fixed;
  top: 0;
  width: 400px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(200px, 0);
  background-color: #1D1D1D;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 {
    width: 330px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 {
    width: 290px;
  }
}

.clickalbe-sidebar-wrapper-style-1.clickalbe-sidebar-active-left {
  transform: translateX(-410px);
  left: 0;
}

.clickalbe-sidebar-wrapper-style-1.sidebar-visible {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.clickalbe-sidebar-wrapper-style-1.mobilemenu-visible {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap {
  padding: 170px 70px 20px;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap {
    padding: 100px 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap {
    padding: 70px 20px 20px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap.clickalbe-sidebar-padding-dec {
  padding: 150px 50px 20px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap.clickalbe-sidebar-padding-dec .sidebar-close {
  left: 50px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-close {
  position: absolute;
  top: 80px;
  left: 70px;
  font-size: 26px;
  line-height: 30px;
  transition: all 250ms ease-out;
  color: #BDBDBD;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-close {
    left: 30px;
    top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-close {
    top: 20px;
    left: 20px;
    font-size: 23px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-close:hover {
  transform: rotate(90deg);
  color: #ff6e21;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-content-100-percent {
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li {
  display: block;
  padding-bottom: 40px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li {
    padding-bottom: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li {
    padding-bottom: 22px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li:last-child {
  padding-bottom: 0;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a {
  font-size: 18px;
  display: block;
  text-transform: capitalize;
  color: #BDBDBD;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a {
    font-size: 16px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a i {
  float: right;
  top: 2px;
  position: relative;
  color: #BDBDBD;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a i {
    font-size: 14px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li:hover > a {
  color: #ff6e21;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li:hover > a i {
  color: #ff6e21;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul {
  padding: 38px 0 0px 25px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li {
  padding-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li {
    padding-bottom: 18px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li:last-child {
  padding-bottom: 0;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li a {
  color: #BDBDBD;
  font-size: 14px;
  display: block;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li a i {
  float: right;
  top: -1px;
  position: relative;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li:hover > a {
  color: #ff6e21;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li:hover > a i {
  color: #ff6e21;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .header-aside-menu {
  margin: 0 0 25px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .header-aside-menu nav ul li {
  display: block;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .header-aside-menu nav ul li a {
  font-size: 15px;
  color: #BDBDBD;
  display: block;
  padding: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .header-aside-menu nav ul li a {
    font-size: 15px;
    padding: 0 0 16px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .header-aside-menu nav ul li a:hover {
  color: #ff6e21;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .header-aside-menu nav ul li:last-child a {
  padding: 0 0 0px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .header-aside-payment img {
  max-width: 100%;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content > p {
  line-height: 1.714286;
  color: #BDBDBD;
  margin: 29px 0 0;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .aside-contact-info {
  margin: 30px 0 30px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .aside-contact-info ul li {
  color: #BDBDBD;
  display: flex;
  margin: 0 0 23px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .aside-contact-info ul li:last-child {
  margin: 0 0 0px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .header-aside-content .aside-contact-info ul li i {
  font-size: 22px;
  margin: 1px 16px 0px 0;
}

.main-wrapper .body-overlay {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay {
  opacity: 1;
  visibility: visible;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 104px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 104px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 50px 15px 50px;
    width: 280px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 48px;
  left: 55px;
  font-size: 30px;
  line-height: 30px;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    left: 30px;
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 20px;
    left: 23px;
    font-size: 23px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  transform: rotate(90deg);
  color: #ff6e21;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 30px;
  color: #393939;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 25px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 75px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
  margin-top: 2px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 14px;
  margin: 0 0 8px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 14px;
  color: #262626;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  width: 20px;
  text-align: right;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 18px 0 43px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 14px;
  color: #535353;
  margin: 0;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  font-size: 18px;
  color: #2F2F2F;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  text-align: center;
  line-height: 20px;
  padding: 19px 20px 20px;
  background-color: #2F2F2F;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:hover {
  background-color: #ff6e21;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:first-child {
  margin-top: 0;
}

.social-icon-style-2 a {
  display: inline-block;
  font-size: 12px;
  width: 31px;
  height: 31px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  margin-right: 5px;
  border: 1px solid transparent;
}

.social-icon-style-2 a:last-child {
  margin-right: 0px;
}

.social-icon-style-2 a i {
  line-height: 30px;
  font-size: 12px;
}

.social-icon-style-2 a.facebook {
  background-color: #3b5998;
}

.social-icon-style-2 a.facebook:hover {
  opacity: .9;
  color: #fff;
}

.social-icon-style-2 a.twitter {
  background-color: #1da1f2;
}

.social-icon-style-2 a.twitter:hover {
  opacity: .9;
  color: #fff;
}

.social-icon-style-2 a.google-plus {
  background-color: #dd4b39;
}

.social-icon-style-2 a.google-plus:hover {
  opacity: .9;
  color: #fff;
}

.social-icon-style-2 a.behance {
  background-color: #1769ff;
}

.social-icon-style-2 a.behance:hover {
  opacity: .9;
  color: #fff;
}

.mobile-menu-content-area .mobile-search {
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  margin-bottom: 27px;
  padding-bottom: 40px;
}

.mobile-menu-content-area .mobile-search form {
  position: relative;
}

.mobile-menu-content-area .mobile-search form input {
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
  color: #BDBDBD;
}

.mobile-menu-content-area .mobile-search form button {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.09);
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #BDBDBD;
  font-size: 15px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .mobile-menu-content-area .mobile-search form button {
    font-size: 16px;
  }
}

.mobile-menu-content-area .mobile-search form button:hover {
  color: #ff6e21;
}

.mobile-menu-content-area .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.mobile-menu-content-area .mobile-curr-lang-wrap {
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  margin-bottom: 31px;
  padding-bottom: 37px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  padding-top: 25px;
  margin-top: 33px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang {
  position: relative;
  margin-bottom: 15px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
  margin-bottom: 0;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  font-size: 15px;
  display: block;
  color: #BDBDBD;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
  float: right;
  font-size: 14px;
  position: relative;
  top: 5px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
  font-size: 14px;
  font-weight: 400;
  color: #BDBDBD;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
  color: #ff6e21;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang {
    margin-bottom: 5px;
  }
  .mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
  }
}

.header-sidebar-wrap-all {
  padding: 120px 55px 50px;
  width: 375px;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-wrap-all {
    padding: 80px 30px 50px;
    width: 275px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-wrap-all {
    padding: 80px 30px 50px;
    width: 270px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-wrap-all {
    padding: 50px 20px 50px;
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-sidebar-wrap-all {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-sidebar-wrap-all {
    display: none;
  }
}

.header-sidebar-wrap-all .header-sidebar-wrap {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.clickable-mainmenu-style-2 {
  padding: 95px 0 95px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-style-2 {
    padding: 55px 0 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-style-2 {
    padding: 55px 0 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-style-2 {
    padding: 45px 0 45px;
  }
}

.clickable-mainmenu-style-2 > nav ul > li {
  padding-bottom: 48px;
  display: block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-style-2 > nav ul > li {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-style-2 > nav ul > li {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-style-2 > nav ul > li {
    padding-bottom: 20px;
  }
}

.clickable-mainmenu-style-2 > nav ul > li:last-child {
  padding-bottom: 0;
}

.clickable-mainmenu-style-2 > nav ul > li a {
  display: inline-block;
  font-size: 24px;
  color: #1b1b1b;
  font-weight: bold;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-style-2 > nav ul > li a {
    font-size: 20px;
  }
}

.clickable-mainmenu-style-2 > nav ul > li a i {
  margin-left: 5px;
  font-size: 18px;
}

.clickable-mainmenu-style-2 > nav ul > li:hover > a {
  color: #3ad9b1;
}

.clickable-mainmenu-style-2 > nav ul > li ul {
  padding: 18px 0 0px 0px;
}

.clickable-mainmenu-style-2 > nav ul > li ul li {
  padding-bottom: 6px;
}

.clickable-mainmenu-style-2 > nav ul > li ul li:last-child {
  padding-bottom: 0;
}

.clickable-mainmenu-style-2 > nav ul > li ul li a {
  font-size: 16px;
  color: #444;
}

.clickable-mainmenu-style-2 > nav ul > li ul li a i {
  position: relative;
  top: 1px;
  font-size: 14px;
}

.clickable-mainmenu-style-2 > nav ul > li ul li ul {
  padding: 18px 0 0px 18px;
}

.clickable-mainmenu-style-2 > nav ul > li ul li ul li a {
  font-size: 14px;
}

.header-sidebar-footer {
  border-top: 1px solid #a3a3a3;
  border-bottom: 1px solid #a3a3a3;
  padding: 38px 0 35px;
}

.header-sidebar-footer h3 {
  color: #1b1b1b;
  margin: 0;
  font-size: 14px;
}

.header-sidebar-footer .footer-sidebar-widget {
  margin: 15px 0 47px;
}

.header-sidebar-footer .footer-sidebar-widget p {
  font-size: 11px;
  color: #1b1b1b;
  margin: 0 0 3px;
}

.header-sidebar-footer .footer-sidebar-widget p:last-child {
  margin: 0 0 0px;
}

.search-3-area {
  position: relative;
}

.search-3-area .search-toggle {
  z-index: 99;
}

.search-3-area .search-toggle i {
  display: block;
}

.search-3-area .search-toggle i.s-close {
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.search-3-area .search-toggle .s-close {
  opacity: 0;
}

.search-3-area .search-toggle.open .s-close {
  opacity: 1;
}

.search-3-area .search-toggle.open .s-open {
  opacity: 0;
}

.search-wrap-3 {
  background-color: transparent;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 340px;
  z-index: -99;
  padding-bottom: 1px;
  margin-right: 10px;
}

.search-wrap-3 form {
  position: relative;
  overflow: hidden;
}

.search-wrap-3 form input {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #393939;
  line-height: 30px;
  padding: 2px 20px;
  width: 100%;
}

.search-wrap-3 form input::-moz-input-placeholder {
  color: #393939;
  opacity: 1;
}

.search-wrap-3 form input::-webkit-input-placeholder {
  color: #393939;
  opacity: 1;
}

.search-wrap-3.open {
  opacity: 1;
  right: 100%;
  visibility: visible;
  z-index: 99;
}

.category-menu {
  width: 290px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  background-color: #fff;
  padding: 33px 0 35px;
  box-shadow: 0 0 30px 0.4px rgba(0, 0, 0, 0.1);
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 767px) {
  .category-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

.category-menu nav > ul > li {
  margin-bottom: 17px;
  position: relative;
}

.category-menu nav > ul > li:last-child {
  margin-bottom: 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .category-menu nav > ul > li {
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-menu nav > ul > li {
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu nav > ul > li {
    margin-bottom: 6px;
  }
}

.category-menu nav > ul > li > a {
  padding: 0 40px;
  display: block;
  font-size: 11px;
  letter-spacing: 1.82px;
  text-transform: uppercase;
  font-weight: 600;
}

.category-menu nav > ul > li > a span {
  float: right;
  font-size: 14px;
  position: relative;
  top: 1px;
}

.category-menu nav > ul > li .category-menu-dropdown {
  position: absolute;
  left: 100%;
  top: -22px;
  width: 570px;
  background-color: #fff;
  padding: 30px 52px 40px 30px;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  z-index: 99;
  box-shadow: 0 0 30px 0.4px rgba(0, 0, 0, 0.1);
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu {
  display: inline-block;
  width: 50%;
  float: left;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.ct-menu-mrg-bottom {
  margin-bottom: 74px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.ct-menu-mrg-left {
  padding-left: 38px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.category-menu-border {
  position: relative;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.category-menu-border:before {
  position: absolute;
  content: "";
  bottom: -36px;
  left: 0;
  background-color: #d6d7da;
  height: 1px;
  width: 180px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu h4 {
  font-weight: 600;
  font-size: 11px;
  margin: 0 0 18px;
  letter-spacing: 1.82px;
  text-transform: uppercase;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li {
  padding: 0 0 9px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li:last-child {
  padding: 0 0 0px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li a {
  font-size: 12px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li a:hover {
  color: #ff6e21;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu > a img {
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .category-menu nav > ul > li .category-menu-dropdown.ct-menu-res-height-1 {
    height: 280px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-menu nav > ul > li .category-menu-dropdown.ct-menu-res-height-1 {
    height: 280px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu nav > ul > li .category-menu-dropdown.ct-menu-res-height-1 {
    height: 280px;
    overflow-y: auto;
  }
}

.category-menu nav > ul > li > .cr-menu-desktop-none {
  display: none;
  padding: 15px 0 0 60px;
}

.category-menu nav > ul > li > .cr-menu-desktop-none > li {
  padding: 0 0 6px;
}

.category-menu nav > ul > li > .cr-menu-desktop-none > li:last-child {
  padding: 0 0 0px;
}

.category-menu nav > ul > li > .cr-menu-desktop-none > li > a {
  display: block;
  padding: 0 40px 0 0;
  font-size: 11px;
  letter-spacing: 1.82px;
  text-transform: uppercase;
  font-weight: 600;
}

.category-menu nav > ul > li > .cr-menu-desktop-none > li > a span {
  top: 4px;
}

.category-menu nav > ul > li > .cr-menu-desktop-none > li > a i {
  float: right;
  font-size: 12px;
  top: 7px;
  position: relative;
}

.category-menu nav > ul > li > .cr-menu-desktop-none > li ul {
  padding: 10px 0 0;
}

.category-menu nav > ul > li > .cr-menu-desktop-none > li ul li {
  padding-bottom: 3px;
}

.category-menu nav > ul > li > .cr-menu-desktop-none > li ul li:last-child {
  padding-bottom: 0px;
}

.category-menu nav > ul > li > .cr-menu-desktop-none > li ul li a {
  padding: 0 0px 0 20px;
  font-size: 12px;
}

.category-menu nav > ul > li:hover > a, .category-menu nav > ul > li:hover > a span {
  color: #ff6e21;
}

.category-menu nav > ul > li:hover .category-menu-dropdown {
  transform: rotateX(0deg);
  visibility: visible;
}

.category-menu-2 {
  padding: 24px 0 27px;
}

.category-menu-2 nav ul {
  display: flex;
  justify-content: center;
}

.category-menu-2 nav ul li {
  margin: 0 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .category-menu-2 nav ul li {
    margin: 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-menu-2 nav ul li {
    margin: 0 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu-2 nav ul li {
    margin: 0 15px;
  }
}

.category-menu-2 nav ul li a {
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
  letter-spacing: .13px;
  display: inline-block;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu-2 nav ul li a {
    font-size: 14px;
  }
}

.category-menu-2 nav ul li a::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 37%;
  bottom: -32px;
  width: 20%;
  height: 4px;
  content: "";
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  background-color: #f2c456;
}

.category-menu-2 nav ul li a.active::after {
  width: calc(100%);
  opacity: 1;
}

.category-menu-2 nav ul li:hover > a::after {
  width: calc(100%);
  opacity: 1;
}

.kid-main-menu-wrap {
  margin-bottom: -112px;
  z-index: 999;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kid-main-menu-wrap {
    margin-bottom: -80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kid-main-menu-wrap {
    margin-bottom: -50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kid-main-menu-wrap {
    margin-bottom: -50px;
  }
}

.kid-main-menu-wrap .kid-menu-bg-img {
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kid-main-menu-wrap .kid-menu-bg-img {
    height: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kid-main-menu-wrap .kid-menu-bg-img {
    height: 85px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kid-main-menu-wrap .kid-menu-bg-img {
    height: 78px;
  }
}

.kid-main-menu-wrap .kid-menu-bg-img .kid-main-menu {
  padding-top: 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kid-main-menu-wrap .kid-menu-bg-img .kid-main-menu {
    padding-top: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kid-main-menu-wrap .kid-menu-bg-img .kid-main-menu {
    padding-top: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kid-main-menu-wrap .kid-menu-bg-img .kid-main-menu {
    padding-top: 12px;
  }
}

.header-ptb-3 {
  padding: 57px 0 30px;
}

/*-------- 3. Slider style ---------*/
.slider-height-1 {
  height: 810px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-1 {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-1 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-1 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: 450px;
  }
}

.slider-height-2 {
  height: 945px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-2 {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-2 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-2 {
    height: 450px;
  }
}

.slider-height-3 {
  height: 910px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-3 {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-3 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-3 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-3 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-3 {
    height: 400px;
  }
}

.slider-height-4 {
  height: 880px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-4 {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-4 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-4 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-4 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-4 {
    height: 400px;
  }
}

.slider-height-5 {
  height: 879px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-5 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-5 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-5 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-5 {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-5 {
    height: 550px;
  }
}

.slider-height-6 {
  height: 1050px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-6 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-6 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-6 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-6 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-6 {
    height: 400px;
  }
}

.slider-height-7 {
  height: 890px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-7 {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-7 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-7 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-7 {
    height: 420px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-7 {
    height: 400px;
  }
}

.slider-height-8 {
  height: 878px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-8 {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-8 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-8 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-8 {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-8 {
    height: 550px;
  }
}

.slider-height-9 {
  height: 1093px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-9 {
    height: 777px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-9 {
    height: 777px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-9 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-9 {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-9 {
    height: 600px;
    background-position: 70%;
  }
}

.slider-pl-1 {
  padding-left: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-pl-1 {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-pl-1 {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-pl-1 {
    padding-left: 20px;
  }
}

.main-slider-content-1 span {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3.6px;
  color: #1b1b1b;
}

.main-slider-content-1 h1 {
  font-size: 46px;
  font-weight: 600;
  color: #1b1b1b;
  line-height: 62px;
  margin: 3px 0 62px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-content-1 h1 {
    margin: 3px 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-1 h1 {
    margin: 3px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-1 h1 {
    margin: 3px 0 20px;
    line-height: 43px;
    font-size: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-1 h1 {
    margin: 3px 0 25px;
    line-height: 40px;
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-1 h1 {
    font-size: 30px;
    line-height: 35px;
    margin: 3px 0 30px;
  }
}

.slider-btn-1 a {
  display: inline-block;
  font-size: 16px;
  color: #1b1b1b;
  line-height: 1;
  z-index: 2;
}

.slider-btn-1 a.slider-btn-1-bg-white {
  background-color: #fff;
}

.slider-btn-1 a.slider-btn-1-ptb {
  padding: 20px 38px 23px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-btn-1 a.slider-btn-1-ptb {
    padding: 15px 26px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-btn-1 a.slider-btn-1-ptb {
    padding: 12px 20px 15px;
  }
}

.slider-btn-1 a.slider-btn-1-border-1 {
  border: 2px solid #c8c8c8;
  transition: all .5s ease-in-out 0s;
}

.slider-btn-1 a.slider-btn-1-border-1:hover {
  border: 2px solid #f2c456;
}

.slider-btn-2 a {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  line-height: 1;
  font-weight: bold;
  z-index: 2;
  font-family: "Rufina", serif;
}

.slider-btn-2 a.slider-btn-2-bg-yellow {
  background-color: #ff6e21;
}

.slider-btn-2 a.slider-btn-2-bg-white {
  background-color: #fff;
}

.slider-btn-2 a.slider-btn-2-text-black {
  color: #1b1b1b;
}

.slider-btn-2 a.slider-btn-2-ptb {
  padding: 22px 58px 24px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-btn-2 a.slider-btn-2-ptb {
    padding: 18px 40px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-btn-2 a.slider-btn-2-ptb {
    padding: 12px 20px 15px;
  }
}

.slider-btn-2 a.slider-btn-3-ptb {
  padding: 22px 36px 21px;
}

@media only screen and (max-width: 767px) {
  .slider-btn-2 a.slider-btn-3-ptb {
    padding: 16px 20px 15px;
  }
}

.slider-nav-position-1 .owl-nav {
  position: absolute;
  left: 120px;
  bottom: 85px;
  display: flex;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-nav-position-1 .owl-nav {
    bottom: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-nav-position-1 .owl-nav {
    bottom: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-nav-position-1 .owl-nav {
    bottom: 50px;
    left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-nav-position-1 .owl-nav {
    bottom: 70px;
    left: 41px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-nav-position-1 .owl-nav {
    bottom: 50px;
    left: 10px;
  }
}

.slider-nav-style-1 .owl-nav div {
  margin: 0 10px;
  font-size: 53px;
  color: #191919;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-nav-style-1 .owl-nav div {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-1 .owl-nav div {
    font-size: 35px;
  }
}

.slider-nav-style-1 .owl-nav div:hover {
  color: #444;
}

.owl-item.active .slider-animated-1 span {
  animation-delay: 1.0s;
  animation-name: fadeInDown;
}

.owl-item.active .slider-animated-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-1 a {
  animation-delay: 1.6s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-2 h1 {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-2 p {
  animation-delay: 1.3s;
  animation-name: fadeInUp;
}

.owl-item.active .slider-animated-2 a {
  animation-delay: 1.6s;
  animation-name: fadeInUp;
}

.slider-area {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area.pt-30 {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area.pt-30 {
    padding-top: 0px;
  }
}

.main-slider-content-2 {
  padding-top: 215px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-2 {
    padding-top: 115px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-2 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-2 {
    padding-top: 50px;
  }
}

.main-slider-content-2 h1 {
  font-size: 64px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  line-height: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-2 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-2 h1 {
    font-size: 30px;
    line-height: 50px;
  }
}

.main-slider-content-2 p {
  font-size: 18px;
  line-height: 36px;
  color: #fff;
  margin: 2px auto 31px;
  width: 27%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-content-2 p {
    width: 40%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-content-2 p {
    width: 40%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-content-2 p {
    width: 45%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-content-2 p {
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-content-2 p {
    font-size: 16px;
    line-height: 29px;
    margin: 10px auto 20px;
    width: 90%;
  }
}

.slider-nav-position-2 .owl-nav div {
  position: absolute;
  top: 50%;
  transform: 50%;
  left: 20px;
  z-index: 9;
  color: #fff;
  font-size: 35px;
  transition: all .3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.slider-nav-position-2 .owl-nav div:hover {
  color: #ff6e21;
}

.slider-nav-position-2 .owl-nav div.owl-next {
  left: auto;
  right: 20px;
}

.slider-nav-position-2:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.main-hero-content-1 h1 {
  color: #1b1b1b;
  margin: 0 0 55px;
  font-weight: bold;
  font-family: "Rufina", serif;
  font-size: 66px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-1 h1 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-1 h1 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-1 h1 {
    font-size: 45px;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-1 h1 {
    font-size: 45px;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-1 h1 {
    font-size: 30px;
    margin: 0 0 30px;
  }
}

.main-hero-content-1 .coupoun-version-wrap {
  margin-top: 51px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-1 .coupoun-version-wrap {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-1 .coupoun-version-wrap {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-1 .coupoun-version-wrap {
    margin-top: 30px;
  }
}

.main-hero-content-1 .coupoun-version-wrap a {
  color: #1b1b1b;
  display: inline-block;
  font-size: 18px;
  font-family: "Rufina", serif;
  margin-right: 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-1 .coupoun-version-wrap a {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-1 .coupoun-version-wrap a {
    margin-right: 8px;
    font-size: 15px;
  }
}

.main-hero-content-1 .coupoun-version-wrap a:last-child {
  margin-right: 0px;
}

.main-hero-content-1 .coupoun-version-wrap a:hover {
  color: #ff6e21;
}

.hero-book-img-position {
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  position: absolute;
  content: "";
  right: 37%;
  bottom: 32%;
  width: 151px;
  animation-duration: 2.5s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-book-img-position {
    right: 18%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-book-img-position {
    width: 65px;
    right: 15%;
    bottom: 40%;
  }
}

.hero-book-img-position img {
  width: 100%;
}

.main-hero-content-2 .welcome-flower h3 {
  color: #ffffff;
  font-size: 32px;
  margin: 0;
  display: inline-block;
  font-family: "Playfair Display", serif;
  position: relative;
  font-style: italic;
}

@media only screen and (max-width: 767px) {
  .main-hero-content-2 .welcome-flower h3 {
    font-size: 21px;
  }
}

.main-hero-content-2 .welcome-flower h3:before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 240px;
  background-color: #d8d8d8;
  left: auto;
  right: calc(100% + 55px);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-2 .welcome-flower h3:before {
    width: 180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-2 .welcome-flower h3:before {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-2 .welcome-flower h3:before {
    width: 100px;
    right: calc(100% + 30px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-2 .welcome-flower h3:before {
    width: 100px;
    right: calc(100% + 30px);
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-2 .welcome-flower h3:before {
    width: 15px;
    right: calc(100% + 5px);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-hero-content-2 .welcome-flower h3:before {
    width: 80px;
    right: calc(100% + 20px);
  }
}

.main-hero-content-2 .welcome-flower h3:after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 240px;
  background-color: #d8d8d8;
  right: auto;
  left: calc(100% + 55px);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-2 .welcome-flower h3:after {
    width: 180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-2 .welcome-flower h3:after {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-2 .welcome-flower h3:after {
    width: 100px;
    left: calc(100% + 30px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-2 .welcome-flower h3:after {
    width: 100px;
    left: calc(100% + 30px);
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-2 .welcome-flower h3:after {
    width: 15px;
    left: calc(100% + 5px);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-hero-content-2 .welcome-flower h3:after {
    width: 80px;
    left: calc(100% + 20px);
  }
}

.main-hero-content-2 h1 {
  color: #ffffff;
  font-size: 66px;
  margin: 13px 0 60px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-2 h1 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-2 h1 {
    font-size: 55px;
    margin: 13px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-2 h1 {
    font-size: 37px;
    margin: 10px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-2 h1 {
    font-size: 37px;
    margin: 10px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-2 h1 {
    font-size: 28px;
    margin: 5px 0 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-hero-content-2 h1 {
    font-size: 35px;
  }
}

.slider-btn-4 a {
  color: #1b1b1b;
  font-weight: bold;
  font-family: "Playfair Display", serif;
  font-size: 16px;
  display: inline-block;
  background-color: #fff;
  padding: 16px 60px 18px;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-btn-4 a {
    padding: 12px 35px 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-btn-4 a {
    padding: 12px 35px 16px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-btn-4 a {
    padding: 10px 30px 12px;
    font-size: 15px;
  }
}

.main-hero-content-3 {
  margin-right: -135px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-3 {
    margin-right: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-3 {
    margin-right: 0px;
  }
  .main-hero-content-3.pt-80 {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-3 {
    margin-right: 0px;
  }
  .main-hero-content-3.pt-80 {
    padding-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-3 {
    margin-right: 0px;
  }
  .main-hero-content-3.pt-80 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-3 {
    margin-right: 0px;
  }
}

.main-hero-content-3 .handmade-leaf-img {
  animation-duration: 2.5s;
  animation-name: bounce-img;
  animation-iteration-count: infinite;
}

.main-hero-content-3 .handmade-leaf-img img {
  width: 156px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-3 .handmade-leaf-img img {
    width: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-3 .handmade-leaf-img img {
    width: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-3 .handmade-leaf-img img {
    width: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-3 .handmade-leaf-img img {
    width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-3 .handmade-leaf-img img {
    width: 80px;
  }
}

.main-hero-content-3 h1 {
  color: #fff;
  font-size: 66px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
  margin: 6px 0 22px;
  font-style: italic;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-3 h1 {
    margin: 6px 0 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-3 h1 {
    margin: 6px 0 12px;
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-3 h1 {
    margin: 6px 0 12px;
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-3 h1 {
    margin: 6px 0 12px;
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-3 h1 {
    margin: 25px 0 12px;
    font-size: 27px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-hero-content-3 h1 {
    margin: 30px 0 12px;
    font-size: 34px;
  }
}

.main-hero-content-3 p {
  color: #fff;
  font-size: 18px;
  line-height: 36px;
  margin: 0px auto 43px;
  width: 65%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-3 p {
    margin: 0px auto 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-3 p {
    margin: 0px auto 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-3 p {
    margin: 0px auto 20px;
    width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-3 p {
    margin: 0px auto 20px;
    width: 72%;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-3 p {
    margin: 0px auto 30px;
    line-height: 30px;
    font-size: 16px;
    width: 92%;
  }
}

.main-hero-content-4 {
  position: relative;
  z-index: 9;
}

.main-hero-content-4 h1 {
  font-size: 130px;
  font-weight: bold;
  color: #fff;
  letter-spacing: .77px;
  margin: 0 0 8px;
  line-height: 1;
  text-shadow: 0px 0px 37px rgba(0, 0, 0, 0.5);
}

.main-hero-content-4 h1 span {
  margin-left: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-4 h1 span {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-4 h1 span {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-4 h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-4 h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-4 h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-4 h1 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-4 h1 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-hero-content-4 h1 {
    font-size: 45px;
    margin: 0 0 20px;
  }
}

.main-hero-content-5 {
  margin-left: 33px;
  margin-top: 130px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-5 {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-5 {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-5 {
    margin-top: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-5 {
    margin-top: 0px;
    margin-left: 0px;
  }
}

.main-hero-content-5 h1 {
  color: #1b1b1b;
  font-size: 66px;
  line-height: 78px;
  font-family: "Fredoka One", cursive;
  margin: 0 0 62px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-5 h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-5 h1 {
    font-size: 40px;
    line-height: 55px;
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-5 h1 {
    font-size: 38px;
    line-height: 50px;
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-5 h1 {
    font-size: 28px;
    line-height: 40px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-5 h1 {
    font-size: 24px;
    line-height: 35px;
    margin: 0 0 20px;
  }
}

.furniture-slider-wrap {
  position: relative;
  padding-left: 525px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .furniture-slider-wrap {
    padding-left: 425px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .furniture-slider-wrap {
    padding-left: 325px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-slider-wrap {
    padding-left: 225px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-slider-wrap {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-slider-wrap {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .furniture-slider-wrap {
    padding-left: 100px;
  }
}

.furniture-slider-wrap .main-slider-content-3-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 25px 35px;
}

@media only screen and (max-width: 767px) {
  .furniture-slider-wrap .main-slider-content-3-wrap {
    position: static;
    top: 0;
    transform: inherit;
    padding: 15px 15px;
    margin: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .furniture-slider-wrap .main-slider-content-3-wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0px;
  }
}

.furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 {
  padding: 94px 257px 150px 52px;
  border: 2px solid #f2c456;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 {
    padding: 80px 120px 90px 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 {
    padding: 50px 50px 80px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 {
    padding: 30px 15px 90px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 {
    padding: 30px 50px 70px 15px;
  }
}

.furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 span {
  color: #654600;
  font-weight: 600;
  letter-spacing: 3.57px;
  text-transform: uppercase;
  display: block;
}

.furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 h1 {
  color: #1b1b1b;
  font-weight: 600;
  font-size: 46px;
  font-family: "Zilla Slab", serif;
  margin: 4px 0 45px;
  line-height: 57px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 h1 {
    margin: 4px 0 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 h1 {
    margin: 4px 0 25px;
    line-height: 45px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 h1 {
    margin: 4px 0 12px;
    line-height: 40px;
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-slider-wrap .main-slider-content-3-wrap .main-slider-content-3 h1 {
    margin: 2px 0 12px;
    line-height: 35px;
    font-size: 30px;
  }
}

.slider-nav-position-3 .owl-nav {
  position: absolute;
  left: 99px;
  bottom: 27.5%;
  display: flex;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-nav-position-3 .owl-nav {
    bottom: 17.5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-nav-position-3 .owl-nav {
    bottom: 20.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-nav-position-3 .owl-nav {
    bottom: 20.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-nav-position-3 .owl-nav {
    bottom: 23.5%;
    left: 76px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-nav-position-3 .owl-nav {
    bottom: 9.5%;
    left: 27px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-nav-position-3 .owl-nav {
    bottom: 17.5%;
    left: 47px;
  }
}

.default-overlay.kids-slider-overlay::before {
  background-color: #000;
  opacity: .1;
}

.pet-single-slider-wrap {
  padding: 0 0 38px 50px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-single-slider-wrap {
    padding: 0 0 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pet-single-slider-wrap {
    padding: 0 0 15px 15px;
  }
}

.pet-single-slider-wrap::before {
  position: absolute;
  content: "";
  left: 50px;
  top: 99px;
  width: 0;
  height: 0;
  z-index: 99;
  border-top: 191px solid #ffc68b;
  border-right: 190px solid transparent;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pet-single-slider-wrap::before {
    top: 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pet-single-slider-wrap::before {
    top: 45px;
    border-top: 150px solid #ffc68b;
    border-right: 150px solid transparent;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pet-single-slider-wrap::before {
    top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-single-slider-wrap::before {
    top: 0px;
    left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pet-single-slider-wrap::before {
    top: 0px;
    left: 0px;
    border-top: 100px solid #ffc68b;
    border-right: 100px solid transparent;
  }
}

.single-main-slider-gradient {
  position: relative;
  height: 100%;
  border-radius: 300px 211px 190px 160px;
  background: linear-gradient(to right, #fee8aa, #ffdf24);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-main-slider-gradient {
    border-radius: 100px 130px 150px 130px;
  }
}

@media only screen and (max-width: 767px) {
  .single-main-slider-gradient {
    border-radius: 100px 130px 100px 80px;
  }
}

.single-main-slider-gradient .slider-pet-img {
  position: absolute;
  bottom: -94px;
  right: -50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-main-slider-gradient .slider-pet-img {
    bottom: -70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-main-slider-gradient .slider-pet-img {
    bottom: -65px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-main-slider-gradient .slider-pet-img {
    bottom: -60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-main-slider-gradient .slider-pet-img {
    bottom: 0px;
    right: -40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-main-slider-gradient .slider-pet-img {
    right: auto !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-main-slider-gradient .slider-pet-img {
    right: 0 !important;
    left: auto;
    transform: inherit;
    bottom: 115px;
  }
}

.slider-pet-img img {
  width: 983px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-pet-img img {
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-pet-img img {
    width: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-pet-img img {
    width: 480px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-pet-img img {
    width: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-pet-img img {
    width: 120px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-pet-img img {
    width: 200px;
  }
}

.pets-contact-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99;
  flex-wrap: wrap;
  padding: 65px 80px 0px 75px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pets-contact-wrap {
    padding: 65px 0px 0px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pets-contact-wrap {
    padding: 65px 0px 0px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pets-contact-wrap {
    padding: 45px 30px 0px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pets-contact-wrap {
    padding: 25px 0px 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pets-contact-wrap {
    padding: 20px 0px 25px 0px;
    text-align: center;
    display: block;
  }
}

.pets-contact-wrap .pets-social {
  display: flex;
  justify-content: center;
}

.pets-contact-wrap .pets-social a {
  font-size: 36px;
  font-family: "Fredoka One", cursive;
  margin-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pets-contact-wrap .pets-social a {
    margin-right: 30px;
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pets-contact-wrap .pets-social a {
    margin-right: 30px;
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pets-contact-wrap .pets-social a {
    margin-right: 20px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pets-contact-wrap .pets-social a {
    margin-right: 20px;
    font-size: 20px;
  }
}

.pets-contact-wrap .pets-social a:last-child {
  margin-right: 0;
}

.pets-contact-wrap .pets-social a.pets-facebook {
  color: #628ff3;
}

.pets-contact-wrap .pets-social a.pets-instagram {
  color: #ff218a;
}

.pets-contact-wrap .pets-social a.pets-twitter {
  color: #86e0e1;
}

.pets-contact-wrap .pets-social a img {
  width: 39px;
  margin-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pets-contact-wrap .pets-social a img {
    width: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pets-contact-wrap .pets-social a img {
    width: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .pets-contact-wrap .pets-social a img {
    width: 20px;
  }
}

.pets-contact-wrap .pets-phn-number p {
  color: #ff6e21;
  font-size: 66px;
  font-family: "Fredoka One", cursive;
  line-height: 55px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pets-contact-wrap .pets-phn-number p {
    font-size: 36px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pets-contact-wrap .pets-phn-number p {
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pets-contact-wrap .pets-phn-number p {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pets-contact-wrap .pets-phn-number p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pets-contact-wrap .pets-phn-number p {
    font-size: 20px;
  }
}

.pets-contact-wrap .pets-phn-number p img {
  width: 50px;
  margin-right: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pets-contact-wrap .pets-phn-number p img {
    width: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pets-contact-wrap .pets-phn-number p img {
    width: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .pets-contact-wrap .pets-phn-number p img {
    width: 30px;
  }
}

.pet-slider-area {
  overflow: hidden;
}

.pet-slider-area .pets-slider-shape {
  margin-top: -26px;
}

.pet-slider-area .pets-slider-shape img {
  max-width: 100%;
}

.main-hero-content-6 {
  margin: -165px 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-6 {
    margin: -110px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-6 {
    margin: -110px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-6 {
    margin: -110px 0 0;
  }
}

.main-hero-content-6 h1 {
  color: #fff;
  font-size: 150px;
  font-family: "SignPainter HouseScript";
  line-height: 160px;
  margin: 0 0 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-6 h1 {
    font-size: 120px;
    line-height: 140px;
    margin: 0 0 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-6 h1 {
    font-size: 110px;
    line-height: 130px;
    margin: 0 0 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-6 h1 {
    font-size: 100px;
    line-height: 120px;
    margin: 0 0 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-6 h1 {
    font-size: 80px;
    line-height: 100px;
    margin: 0 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-6 h1 {
    font-size: 70px;
    line-height: 85px;
    margin: 0 0 7px;
  }
}

.main-hero-content-7 {
  margin-top: -70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-7 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-7 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-7 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-7 {
    margin-top: 0px;
  }
}

.main-hero-content-7 .organic-leaf-img img {
  width: 535px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-hero-content-7 .organic-leaf-img img {
    width: 320px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-7 .organic-leaf-img img {
    width: 320px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-7 .organic-leaf-img img {
    width: 230px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-7 .organic-leaf-img img {
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-7 .organic-leaf-img img {
    width: 180px;
  }
}

.main-hero-content-7 h1 {
  color: #1b1b1b;
  font-size: 66px;
  margin: 10px 0 23px;
  font-family: "SignPainter HouseScript";
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-hero-content-7 h1 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-hero-content-7 h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-hero-content-7 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-hero-content-7 h1 {
    font-size: 40px;
    margin: 7px 0 20px;
  }
}

.cake-slider-position-img {
  position: absolute;
  left: 0;
  top: -2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-slider-position-img {
    top: -3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-slider-position-img {
    top: -6px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-slider-position-img {
    top: -11px;
  }
}

.cake-slider-position-img img {
  max-width: 100%;
}

/*-------- 4. Section title style ---------*/
.section-title-mrg-left {
  margin-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-mrg-left {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-mrg-left {
    margin-left: 0px;
  }
  .section-title-mrg-left.mb-65 {
    margin-bottom: 25px;
  }
}

.section-title-1-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .section-title-1-wrap {
    display: block;
    text-align: center;
  }
}

.section-title-1-wrap .section-title-1-left {
  flex: 0 0 50%;
}

.section-title-1-wrap .section-title-1-right {
  flex: 0 0 50%;
}

.section-title-1-wrap .section-title-1-right p {
  font-size: 18px;
  line-height: 36px;
  margin: 0;
  width: 90%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-1-wrap .section-title-1-right p {
    font-size: 17px;
    line-height: 34px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1-wrap .section-title-1-right p {
    font-size: 15px;
    line-height: 28px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1-wrap .section-title-1-right p {
    font-size: 14px;
    line-height: 28px;
    width: 100%;
  }
}

.section-title-1-content span {
  font-weight: 600;
  letter-spacing: 3.6px;
  display: block;
}

.section-title-1-content h2 {
  font-size: 46px;
  margin: 22px 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-1-content h2 {
    font-size: 38px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-1-content h2 {
    font-size: 32px;
    margin: 8px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1-content h2 {
    font-size: 28px;
    margin: 5px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1-content h2 {
    font-size: 24px;
    margin: 5px 0 15px;
  }
}

.section-title-1-content.section-mrg-dec h2 {
  margin: 12px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1-content.section-mrg-dec h2 {
    margin: 5px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1-content.section-mrg-dec h2 {
    margin: 5px 0 0;
  }
}

.section-title-1-content.section-title-ntv-mrg {
  margin-left: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-1-content.section-title-ntv-mrg {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-1-content.section-title-ntv-mrg {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1-content.section-title-ntv-mrg {
    margin-left: 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1-content.section-title-ntv-mrg {
    margin-left: 0px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1-content.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1-content.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-1-content.collection-sec-res.mb-60 {
    margin-bottom: 10px;
  }
}

.section-title-2 h2 {
  margin: 0;
  font-size: 32px;
  color: #1b1b1b;
  position: relative;
  font-weight: 500;
  display: inline-block;
  padding: 0 0 19px;
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2 {
    font-size: 26px;
  }
}

.section-title-2 h2:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  content: "";
  height: 2px;
  width: 100px;
  background-color: #ff6e21;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-2.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-3 h2 {
  margin: 0;
  font-size: 32px;
  color: #1b1b1b;
  position: relative;
  font-weight: 500;
  display: inline-block;
  padding: 0 0 19px;
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    font-size: 22px;
    padding: 0 0 10px;
  }
}

.section-title-3.st-bg-white h2 {
  background-color: #fff;
  padding: 0 77px;
}

@media only screen and (max-width: 767px) {
  .section-title-3.st-bg-white h2 {
    padding: 0 10px;
  }
}

.section-title-3.st-mrg-botton {
  margin-bottom: -19px;
}

@media only screen and (max-width: 767px) {
  .section-title-3.st-mrg-botton {
    margin-bottom: -15px;
  }
}

.section-title-3.st-fredoka h2 {
  font-family: "Fredoka One", cursive;
}

.section-title-4 h2 {
  font-weight: bold;
  font-size: 66px;
  color: #1b1b1b;
  margin: 0 0 0px;
  font-family: "Rufina", serif;
  padding: 0 0 30px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-4 h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-4 h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-4 h2 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4 h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4 h2 {
    font-size: 30px;
    padding: 0 0 15px;
  }
}

.section-title-4 h2:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  height: 1px;
  width: 120px;
  background-color: #ff6e21;
}

.section-title-4 p {
  font-size: 18px;
  color: #1b1b1b;
  margin: 23px auto 0;
  line-height: 34px;
  width: 50%;
  font-family: "Rufina", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-4 p {
    width: 66%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4 p {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4 p {
    font-size: 16px;
    line-height: 30px;
    width: 100%;
    margin: 15px auto 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4.mb-55 {
    margin-bottom: 20px;
  }
}

.section-title-5-wrap {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .section-title-5-wrap {
    display: block;
  }
  .section-title-5-wrap.mb-60 {
    margin-bottom: 30px;
  }
}

.section-title-5 {
  flex: 0 0 50%;
}

.section-title-5 h2 {
  font-weight: bold;
  font-size: 36px;
  color: #1b1b1b;
  margin: 2px 0 0px;
  font-family: "Rufina", serif;
  padding: 0 0 30px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5 h2 {
    font-size: 30px;
    padding: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5 h2 {
    font-size: 28px;
    padding: 0 0 20px;
    margin-bottom: 20px;
  }
}

.section-title-5 h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 120px;
  background-color: #ff6e21;
}

.section-title-5.section-border-center h2:before {
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .section-title-5.mb-60 {
    margin-bottom: 30px;
  }
}

.section-title-5-right {
  flex: 0 0 50%;
  padding-left: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-5-right {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5-right {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5-right {
    padding-left: 0px;
  }
}

.section-title-5-right p {
  color: #333;
  line-height: 36px;
  font-size: 18px;
  font-family: "Rufina", serif;
  width: 87%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5-right p {
    width: 100%;
    line-height: 30px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5-right p {
    width: 100%;
    line-height: 30px;
    font-size: 16px;
  }
}

.section-title-6 > span {
  display: block;
  font-weight: 600;
  letter-spacing: 3.57px;
  margin: 0 0 17px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6 > span {
    margin: 0 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6 > span {
    margin: 0 0 5px;
  }
}

.section-title-6 h2 {
  font-size: 46px;
  color: #1b1b1b;
  font-weight: bold;
  font-style: italic;
  margin: 0 0 0px;
  font-family: "Playfair Display", serif;
}

.section-title-6 h2 > span {
  color: #3ad9b1;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-6 h2 {
    font-size: 37px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-6 h2 {
    font-size: 35px;
    line-height: 1;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-6 h2 {
    font-size: 35px;
    line-height: 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6 h2 {
    font-size: 38px;
    line-height: 1;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6 h2 {
    font-size: 24px;
  }
}

.section-title-6 p {
  font-size: 18px;
  line-height: 36px;
  margin: 21px auto 0;
  width: 68%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-6 p {
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-6 p {
    width: 73%;
    margin: 10px auto 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-6 p {
    width: 85%;
    font-size: 14px;
    margin: 15px auto 0;
    line-height: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6 p {
    font-size: 16px;
    line-height: 32px;
    margin: 0px auto 0;
    width: 85%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6 p {
    font-size: 14px;
    line-height: 25px;
    margin: 10px auto 0;
    width: 100%;
  }
}

.section-title-6 h4 {
  color: #1b1b1b;
  font-size: 22px;
  font-style: italic;
  margin: 0 0 27px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6 h4 {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6 h4 {
    margin: 0 0 15px;
  }
}

.section-title-6.st-handmade p {
  width: 60%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6.st-handmade p {
    width: 95%;
    margin: 15px auto 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6.st-handmade p {
    width: 95%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6.mb-55 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6.mb-55 {
    margin-bottom: 27px;
  }
  .section-title-6.mb-60 {
    margin-bottom: 27px;
  }
}

.section-title-7 span {
  color: #654600;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
  letter-spacing: 3.57px;
  margin: 0px 0 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-7 span {
    margin: 0px 0 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-7 span {
    margin: 0px 0 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7 span {
    margin: 0px 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7 span {
    margin: 0px 0 5px;
    letter-spacing: 2.57px;
  }
}

.section-title-7 h2 {
  color: #1b1b1b;
  font-weight: 600;
  display: block;
  font-family: "Zilla Slab", serif;
  font-size: 46px;
  position: relative;
  padding-bottom: 24px;
  margin: 0px 0 0;
}

@media only screen and (max-width: 767px) {
  .section-title-7 h2 {
    font-size: 28px;
    padding-bottom: 18px;
  }
}

.section-title-7 h2:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100px;
  background-color: #f2c456;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.section-title-7 p {
  font-size: 18px;
  color: #393939;
  line-height: 36px;
  width: 44%;
  margin: 23px auto 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-7 p {
    width: 54%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-7 p {
    width: 57%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-7 p {
    width: 71%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7 p {
    width: 78%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7 p {
    width: 93%;
    line-height: 32px;
    font-size: 16px;
  }
}

.section-title-7.section-title-7-modify {
  display: inline-block;
  text-align: center;
  background-color: #fff;
  margin-top: -90px;
  padding: 0 58px;
  position: relative;
  z-index: 99;
}

@media only screen and (max-width: 767px) {
  .section-title-7.section-title-7-modify {
    margin-top: 50px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-7.section-title-7-modify {
    margin-top: -90px;
    padding: 0 30px;
  }
}

.section-title-7.section-title-7-ptb {
  padding: 55px 0 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-7.section-title-7-ptb {
    padding: 35px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7.section-title-7-ptb {
    padding: 35px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7.section-title-7-ptb {
    padding: 35px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7.mb-55 {
    margin-bottom: 27px;
  }
  .section-title-7.mb-60 {
    margin-bottom: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7.mb-55 {
    margin-bottom: 27px;
  }
  .section-title-7.mb-60 {
    margin-bottom: 27px;
  }
}

.section-title-8 h2 {
  font-size: 46px;
  color: #1b1b1b;
  font-weight: 600;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-8 h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-8.mb-50 {
    margin-bottom: 27px;
  }
  .section-title-8.mb-55 {
    margin-bottom: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8.mb-50 {
    margin-bottom: 27px;
  }
  .section-title-8.mb-55 {
    margin-bottom: 27px;
  }
}

.section-title-9 h2 {
  font-size: 66px;
  color: #1b1b1b;
  font-family: "Fredoka One", cursive;
  margin: 0 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-9 h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-9 h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-9 h2 {
    font-size: 30px;
  }
}

.section-title-9.st-dec-font h2 {
  font-size: 46px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-9.st-dec-font h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-9.st-dec-font h2 {
    font-size: 23px;
  }
}

.section-title-9.st-dec-2-font h2 {
  font-size: 36px;
  color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .section-title-9.st-dec-2-font h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-9.mb-45 {
    margin-bottom: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-9.mb-45 {
    margin-bottom: 27px;
  }
  .section-title-9.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-9.mb-50 {
    margin-bottom: 27px;
  }
}

.section-title-10 {
  position: relative;
  z-index: 9;
}

.section-title-10 img {
  margin: 0px 0 13px;
}

.section-title-10 h2 {
  font-size: 66px;
  font-family: "SignPainter HouseScript";
  color: #ef8da1;
  margin: 0px 0 0;
}

@media only screen and (max-width: 767px) {
  .section-title-10 h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-10.mb-50 {
    margin-bottom: 27px;
  }
}

.section-title-11 h3 {
  color: #3cd53f;
  font-size: 36px;
  font-family: "SignPainter HouseScript";
  margin: 0 0 0px;
}

.section-title-11 h2 {
  color: #1b1b1b;
  font-size: 66px;
  font-family: "SignPainter HouseScript";
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .section-title-11 h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11.mb-60 {
    margin-bottom: 27px;
  }
}

.subscribe-organic-ptb {
  margin: 7px 0 110px;
}

@media only screen and (max-width: 767px) {
  .subscribe-organic-ptb {
    margin: 7px 0 50px;
  }
}

.section-title-12 h2 {
  font-size: 24px;
  font-weight: bold;
  color: #1D1D1D;
  letter-spacing: .86px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 24px;
  border-bottom: 1px solid #D8D8D8;
}

@media only screen and (max-width: 767px) {
  .section-title-12 h2 {
    font-size: 18px;
    letter-spacing: 0px;
    padding-bottom: 15px;
  }
}

/*-------- 5. Product style ---------*/
.product-tab-list {
  display: flex;
  justify-content: center;
}

.product-tab-list a {
  display: inline-block;
  border: 2px solid #d2d2d2;
  border-radius: 2px;
  line-height: 1;
  min-width: 82px;
  text-align: center;
  padding: 8px 10px;
  margin: 0 11px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list a {
    margin: 0 5px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list a {
    margin: 0 4px 8px;
  }
}

.product-tab-list a.active {
  border: 2px solid #959595;
}

.product-tab-list.tab-list-green-2 a:hover {
  color: #52e3b9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list.mb-60 {
    margin-bottom: 30px;
  }
}

.product-wrap {
  position: relative;
}

.product-wrap .product-img {
  position: relative;
  overflow: hidden;
}

.product-wrap .product-img::after {
  border: 8px solid #fff;
  bottom: 10px;
  content: "";
  left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
  z-index: 9;
  pointer-events: none;
}

.product-wrap .product-img.pro-theme-color-border::after {
  border: 8px solid #ff6e21;
}

.product-wrap .product-img.pro-border-none::after {
  display: none;
}

.product-wrap .product-img.pro-border-2::before {
  border: 4px solid #f2c456;
  bottom: 10px;
  content: "";
  left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
  z-index: 9;
  border-radius: 20px;
  pointer-events: none;
}

.product-wrap .product-img.pro-border-3 {
  border: 2px solid #f1bfc9;
  border-radius: 20px;
}

.product-wrap .product-img a img {
  width: 100%;
}

.product-wrap .product-img > span {
  display: inline-block;
  color: #fff;
  position: absolute;
  letter-spacing: 1.1px;
  line-height: 1;
  z-index: 1;
  padding: 7px 13px 8px;
}

.product-wrap .product-img > span.badge-top-20 {
  top: 32px;
}

.product-wrap .product-img > span.badge-left-20 {
  left: 32px;
}

.product-wrap .product-img > span.badge-right-20 {
  right: 32px;
}

.product-wrap .product-img > span.badge-black {
  background-color: #1b1b1b;
}

.product-wrap .product-img > span.badge-green {
  background-color: #3cd53f;
}

.product-wrap .product-img > span.badge-theme-color {
  background-color: #ff6e21;
}

.product-wrap .product-img > span.badge-style-2 {
  top: 27px;
  right: 3px;
  padding: 0;
}

.product-wrap .product-img > span.badge-style-2:before {
  position: absolute;
  content: "";
  top: -27px;
  z-index: -1;
  right: -3px;
  width: 0;
  height: 0;
  border-top: 88px solid #ec5555;
  border-left: 88px solid transparent;
}

.product-wrap .product-img .product-action-position-1 {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  text-align: center;
}

.product-wrap .product-img .product-action-position-2 {
  position: absolute;
  bottom: 34px;
  left: 0;
  right: 0;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action-position-2 {
    bottom: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-img .product-action-position-2 {
    bottom: 25px;
  }
}

.product-wrap .product-img .product-action a {
  display: inline-block;
  font-size: 20px;
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  margin: 0 4px;
  color: #191919;
  background-color: #fff;
  transform: scale(1.2);
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
  z-index: 99;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-img .product-action a {
    width: 43px;
    height: 43px;
    line-height: 43px;
    font-size: 18px;
    margin: 0 3px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-img .product-action a {
    width: 43px;
    height: 43px;
    line-height: 43px;
    font-size: 18px;
    margin: 0 2px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action a {
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin: 0 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action a {
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin: 0 2px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    margin: 0 2px;
  }
}

.product-wrap .product-img .product-action a i {
  line-height: 52px;
  transition: all 0.4s ease-in-out 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-img .product-action a i {
    line-height: 43px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-img .product-action a i {
    line-height: 43px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action a i {
    line-height: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action a i {
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action a i {
    line-height: 40px;
  }
}

.product-wrap .product-img .product-action a:hover {
  background-color: #191919;
  color: #fff;
}

.product-wrap .product-img .product-action.pro-action-col4 a {
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 2px;
}

.product-wrap .product-img .product-action.pro-action-col4 a i {
  line-height: 40px;
}

.product-wrap .product-img .product-action.pro-action-theme-color a {
  background-color: #ff6e21;
  color: #fff;
}

.product-wrap .product-img .product-action.pro-action-theme-color a:hover {
  background-color: #191919;
}

.product-wrap .product-img .product-action.pro-action-yellow-2 a {
  background-color: #f2c456;
  color: #fff;
}

.product-wrap .product-img .product-action.pro-action-yellow-2 a:hover {
  background-color: #191919;
}

.product-wrap .product-img .product-action.pro-action-pink a {
  background-color: #ef8da1;
  color: #fff;
}

.product-wrap .product-img .product-action.pro-action-pink a:hover {
  background-color: #191919;
}

.product-wrap .product-img .product-action.pro-action-green3 a {
  background-color: #3cd53f;
  color: #fff;
}

.product-wrap .product-img .product-action.pro-action-green3 a:hover {
  background-color: #191919;
}

.product-wrap .product-img .product-action-2 a {
  color: #191919;
  display: inline-block;
  font-size: 20px;
  opacity: 0;
  transform: translateY(15px);
  margin: 0 14px;
  visibility: hidden;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-img .product-action-2 a {
    margin: 0px 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-img .product-action-2 a {
    margin: 0px 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action-2 a {
    margin: 0px 8px;
  }
}

.product-wrap .product-img .product-action-2 a:nth-child(1) {
  transition-delay: 150ms;
}

.product-wrap .product-img .product-action-2 a:nth-child(2) {
  transition-delay: 200ms;
}

.product-wrap .product-img .product-action-2 a:nth-child(3) {
  transition-delay: 250ms;
}

.product-wrap .product-img .product-action-2 a:nth-child(4) {
  transition-delay: 300ms;
}

.product-wrap .product-img .product-action-2 a:hover {
  color: #3ad9b1;
}

.product-wrap .product-img .product-action-2.pro-action-yellow-2 a:hover {
  color: #f2c456;
}

.product-wrap .product-img .deal-count {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  width: 100%;
}

.product-wrap .product-img .deal-count div {
  display: flex;
  justify-content: center;
}

.product-wrap .product-img .deal-count div > span {
  background-color: #262626;
  color: #fff;
  margin: 0 5px;
  padding: 4px 9px 13px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-img .deal-count div > span {
    padding: 4px 6px 13px;
    margin: 0 3px;
  }
}

.product-wrap .product-img .deal-count div > span p {
  color: #fff;
  margin: 0;
  display: block;
  line-height: 1;
}

.product-wrap .product-img .shop-list-quickview {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.product-wrap .product-img .shop-list-quickview a {
  display: inline-block;
  font-size: 20px;
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  margin: 0 4px;
  color: #fff;
  background-color: #ff6e21;
  transform: scale(1.2);
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
  z-index: 99;
}

.product-wrap .product-img .shop-list-quickview a i {
  line-height: 52px;
  transition: all 0.4s ease-in-out 0s;
}

.product-wrap .product-img .shop-list-quickview a:hover {
  background-color: #191919;
  color: #fff;
}

.product-wrap .product-img.pro-img-border-radius {
  border-radius: 15px;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img.mb-25 {
    margin-bottom: 12px;
  }
}

.product-wrap .product-content h4 {
  font-size: 14px;
  margin: 0 0 7px;
}

.product-wrap .product-content .product-price span {
  position: relative;
}

.product-wrap .product-content .product-price span.old-price {
  margin-right: 4px;
}

.product-wrap .product-content .product-price span.old-price:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(130deg);
  background-color: #393939;
}

.product-wrap .product-content.product-content-green-2 h4 a {
  color: #393939;
}

.product-wrap .product-content.product-content-green-2 h4 a:hover {
  color: #52e3b9;
}

.product-wrap .product-content.product-content-yellow-2 h4 a {
  color: #393939;
}

.product-wrap .product-content.product-content-yellow-2 h4 a:hover {
  color: #f2c456;
}

.product-wrap .product-content.product-content-pink h4 a {
  color: #393939;
}

.product-wrap .product-content.product-content-pink h4 a:hover {
  color: #ef8da1;
}

.product-wrap .product-content.product-content-green3 h4 a {
  color: #393939;
}

.product-wrap .product-content.product-content-green3 h4 a:hover {
  color: #3cd53f;
}

.product-wrap .product-content-2 h4 {
  font-size: 24px;
  font-weight: bold;
  color: #1b1b1b;
  font-family: "Rufina", serif;
  margin: 0 0 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-content-2 h4 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-2 h4 {
    font-size: 20px;
    margin: 0 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-content-2 h4 {
    font-size: 20px;
    margin: 0 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-2 h4 {
    font-size: 20px;
    margin: 0 0 7px;
  }
}

.product-wrap .product-content-2 h4 a {
  color: #1b1b1b;
}

.product-wrap .product-content-2 h4 a:hover {
  color: #ff6e21;
}

.product-wrap .product-content-2 > span {
  display: block;
  margin: 0 0 8px;
}

.product-wrap .product-content-2 > span a {
  font-weight: 600;
  color: #1b1b1b;
  font-style: italic;
  display: block;
}

.product-wrap .product-content-2 > span a:hover {
  color: #ff6e21;
}

.product-wrap .product-content-2 .product-rating-wrap {
  display: block;
  margin: 17px 0 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-2 .product-rating-wrap {
    margin: 10px 0 6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-content-2 .product-rating-wrap {
    margin: 10px 0 6px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-2 .product-rating-wrap {
    margin: 7px 0 6px;
  }
}

.product-wrap .product-content-2 .product-rating-wrap .product-rating {
  background-color: #fff;
  display: inline-block;
  padding: 2px;
}

.product-wrap .product-content-2 .product-rating-wrap .product-rating i {
  color: #f6c443;
  font-size: 15px;
}

.product-wrap .product-content-2 .product-price-2 span {
  font-weight: 600;
  color: #ff6e21;
}

.product-wrap .product-content-2 .product-price-2 span.old-price {
  color: #1b1b1b;
  margin-right: 4px;
  position: relative;
}

.product-wrap .product-content-2 .product-price-2 span.old-price::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(130deg);
  background-color: #393939;
}

.product-wrap .product-content-2.pc-2-font-dec h4 {
  font-size: 20px;
  margin: 0 0 13px;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-2.pc-2-font-dec h4 {
    margin: 0 0 8px;
  }
}

.product-wrap .product-content-3 h4 {
  font-size: 18px;
  font-family: "Fredoka One", cursive;
  margin: 0 0 3px;
}

.product-wrap .product-content-3 .product-price-3 span {
  font-size: 18px;
  font-family: "Fredoka One", cursive;
  color: #ff6e21;
}

.product-wrap .product-content-3 .product-price-3 span.old-price {
  color: #8b8b8b;
  margin-right: 4px;
  position: relative;
}

.product-wrap .product-content-3 .product-price-3 span.old-price::before {
  position: absolute;
  content: "";
  width: 25px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(130deg);
  background-color: #1b1b1b;
}

.product-wrap .product-img-zoom a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.product-wrap .product-banner-content {
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-banner-content {
    bottom: 30px;
  }
}

.product-wrap .product-banner-content span {
  display: block;
  margin: 0 0 6px;
}

.product-wrap .product-banner-content h4 {
  background-color: #c74040;
  color: #fff;
  margin: 0;
  line-height: 1;
  font-size: 36px;
  display: inline-block;
  padding: 12px 40px 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-banner-content h4 {
    font-size: 25px;
    padding: 10px 35px 8px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-banner-content h4 {
    font-size: 25px;
    padding: 8px 30px 5px;
  }
}

.product-wrap .product-banner-content-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}

.product-wrap .product-banner-content-2 .clutch span {
  display: inline-block;
  background-color: #f2c456;
  line-height: 1;
  padding: 9px 12px 14px;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-banner-content-2 .clutch span {
    font-size: 12px;
  }
}

.product-wrap .product-banner-content-2 h2 {
  color: #fff;
  margin: 15px 0 32px;
  line-height: 1;
  font-size: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-banner-content-2 h2 {
    font-size: 60px;
    margin: 15px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-banner-content-2 h2 {
    font-size: 68px;
    margin: 15px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-banner-content-2 h2 {
    font-size: 30px;
    margin: 12px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-banner-content-2 h2 {
    font-size: 38px;
    margin: 18px 0 18px;
  }
}

.product-wrap .product-border {
  border: 2px solid #e4e4e4;
}

.product-wrap.product-wrap-hover {
  transition: all .3s ease 0s;
}

.product-wrap.product-wrap-hover:hover {
  margin-top: -28px;
}

.product-wrap:hover .product-img::after {
  right: 24px;
  left: 24px;
  top: 24px;
  bottom: 24px;
  opacity: 1;
}

.product-wrap:hover .product-img.pro-border-2::before {
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  opacity: 1;
}

.product-wrap:hover .product-img-zoom a img {
  transform: scale(1.2);
}

.product-wrap:hover .product-action a {
  transform: scale(1);
  opacity: 1;
}

.product-wrap:hover .product-action-2 a {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap.mb-55 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap.mb-55 {
    margin-bottom: 30px;
  }
}

.load-btn-style-1 a span {
  display: block;
}

.load-btn-style-1 a:hover {
  color: #000;
}

.single-collection-wrap {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-collection-wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.single-collection-wrap .collection-img a img {
  opacity: .3;
  max-width: 100%;
}

.single-collection-wrap .collection-content-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 615px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-collection-wrap .collection-content-wrap {
    left: 490px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-collection-wrap .collection-content-wrap {
    left: 352px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-collection-wrap .collection-content-wrap {
    left: 379px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-collection-wrap .collection-content-wrap {
    position: static;
    left: 0px;
    top: inherit;
    transform: inherit;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-collection-wrap .collection-content-wrap {
    position: static;
    left: 15px;
    top: inherit;
    transform: inherit;
    margin-top: 20px;
  }
}

.single-collection-wrap.slick-current .collection-content-wrap {
  direction: ltr;
  text-align: left;
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .single-collection-wrap.slick-current .collection-content-wrap {
    direction: inherit;
  }
}

.single-collection-wrap.slick-current .collection-img {
  direction: ltr !important;
  float: left;
}

@media only screen and (max-width: 767px) {
  .single-collection-wrap.slick-current .collection-img {
    direction: inherit;
    float: inherit;
  }
}

.single-collection-wrap.slick-current .collection-img a img {
  opacity: 1;
}

.collection-content-wrap {
  width: 724px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 138px 105px;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .collection-content-wrap {
    padding: 50px 50px;
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .collection-content-wrap {
    padding: 70px 50px;
    width: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-content-wrap {
    padding: 70px 50px;
    width: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-content-wrap {
    padding: 70px 50px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .collection-content-wrap {
    padding: 30px 30px;
    width: 100%;
    visibility: visible;
    opacity: 1;
  }
}

.collection-content h2 {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  position: relative;
  padding-left: 122px;
}

.collection-content h2:before {
  position: absolute;
  width: 100px;
  height: 3px;
  left: 0;
  bottom: 18px;
  content: "";
  background-color: #1b1b1b;
}

@media only screen and (max-width: 767px) {
  .collection-content h2:before {
    width: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .collection-content h2:before {
    bottom: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-content h2 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-content h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .collection-content h2 {
    font-size: 17px;
    padding-left: 50px;
  }
}

.collection-content p {
  font-size: 18px;
  margin: 52px 0 33px;
  line-height: 36px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .collection-content p {
    margin: 15px 0 26px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .collection-content p {
    margin: 15px 0 26px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-content p {
    margin: 15px 0 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-content p {
    margin: 15px 0 26px;
  }
}

@media only screen and (max-width: 767px) {
  .collection-content p {
    margin: 8px 0 15px;
    font-size: 15px;
    line-height: 26px;
  }
}

.btn-style-2 a {
  font-size: 16px;
  color: #1b1b1b;
  display: inline-block;
  line-height: 1;
  border: 2px solid #858585;
  padding: 20px 37px 24px;
  transition: all .5s ease-in-out 0s;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .btn-style-2 a {
    padding: 12px 20px 14px;
    font-size: 15px;
  }
}

.btn-style-2 a:hover {
  border: 2px solid #000;
}

.collection-slider-active {
  overflow: hidden;
}

.collection-slider-active .slick-list {
  padding-left: 475px;
  margin-right: -15px;
  margin-left: -15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .collection-slider-active .slick-list {
    padding-left: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .collection-slider-active .slick-list {
    padding-left: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-slider-active .slick-list {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-slider-active .slick-list {
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .collection-slider-active .slick-list {
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

.collection-icon-next {
  position: absolute;
  font-size: 77px;
  bottom: 47px;
  right: 32%;
  cursor: pointer;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .collection-icon-next {
    right: 41%;
    bottom: 140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-icon-next {
    right: 41%;
    bottom: 152px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-icon-next {
    font-size: 55px;
    bottom: 65%;
    right: 50%;
    transform: translateY(50%) translateX(50%);
  }
}

@media only screen and (max-width: 767px) {
  .collection-icon-next {
    font-size: 55px;
    bottom: 52%;
    right: 50%;
    transform: translateY(50%) translateX(50%);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .collection-icon-next {
    bottom: 71%;
  }
}

.collection-icon-next:hover {
  color: #666;
}

.deal-all-pro a {
  color: #ff6e21;
}

.deal-all-pro a:hover {
  color: #393939;
}

.deal-ptb {
  padding: 82px 115px 83px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-ptb {
    padding: 82px 30px 83px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-ptb {
    padding: 42px 40px 43px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-ptb {
    padding: 42px 15px 43px;
  }
}

.deal-ntv-mrg {
  margin-bottom: -67px;
  position: relative;
  z-index: 2;
}

.product-tab-list-2 {
  display: flex;
  justify-content: center;
}

.product-tab-list-2 a {
  display: inline-block;
  line-height: 1;
  margin: 0 34px;
  color: #aeaeae;
  font-size: 32px;
  font-weight: 500;
  padding: 0 0 21px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2 a {
    margin: 0 15px;
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2 a {
    padding: 0 0 8px;
    font-size: 16px;
    margin: 0 10px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-2 a {
    font-size: 20px;
  }
}

.product-tab-list-2 a:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100px;
  height: 2px;
  bottom: 0;
  background-color: transparent;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2 a:before {
    width: 50px;
  }
}

.product-tab-list-2 a.active {
  color: #1b1b1b;
}

.product-tab-list-2 a.active:before {
  background-color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2.mb-60 {
    margin-bottom: 10px;
  }
}

.product-book-slider {
  overflow: hidden;
}

.product-book-slider .slick-list {
  padding: 0 370px;
  margin-right: -30px;
  margin-left: -30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-book-slider .slick-list {
    padding: 0 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-book-slider .slick-list {
    padding: 0 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-book-slider .slick-list {
    padding: 0 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-book-slider .slick-list {
    padding: 0 80px;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-book-slider .slick-list {
    padding: 0 20px;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-book-slider .slick-list {
    padding: 0 30px;
  }
}

.product-book-slider .product-plr-opacity {
  padding-left: 30px;
  padding-right: 30px;
  opacity: 0.3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-book-slider .product-plr-opacity {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-book-slider .product-plr-opacity {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product-book-slider .product-plr-opacity.slick-active {
  opacity: 1;
}

.new-book-slider {
  overflow: hidden;
}

.new-book-slider .slick-list {
  padding: 0 330px 0 0;
  margin-right: -30px;
  margin-left: -30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .new-book-slider .slick-list {
    padding: 0 150px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .new-book-slider .slick-list {
    padding: 0 150px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .new-book-slider .slick-list {
    padding: 0 0px 0 0;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .new-book-slider .slick-list {
    padding: 0 90px 0 0;
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .new-book-slider .slick-list {
    padding: 0 15px 0 0;
    margin-right: -15px;
    margin-left: -15px;
  }
}

.new-book-slider .product-plr-2 {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .new-book-slider .product-plr-2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .new-book-slider .product-plr-2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .new-book-slider .product-plr-2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.newbooks-pl {
  padding-left: 375px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .newbooks-pl {
    padding-left: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .newbooks-pl {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newbooks-pl {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newbooks-pl {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .newbooks-pl {
    padding-left: 15px;
  }
}

.dot-style-2 .slick-dots {
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .dot-style-2 .slick-dots {
    margin-top: 10px;
  }
}

.dot-style-2 .slick-dots li {
  display: inline-block;
}

.dot-style-2 .slick-dots li button {
  background-color: #d8d8d8;
  width: 100px;
  height: 8px;
  display: inline-block;
  margin: 0 10px 0 0;
  transition: all .3s ease 0s;
  padding: 0;
  border: none;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .dot-style-2 .slick-dots li button {
    width: 50px;
  }
}

.dot-style-2 .slick-dots li button:hover {
  background-color: #ff6e21;
}

.dot-style-2 .slick-dots li.slick-active button {
  background-color: #ff6e21;
}

.collection-img-2 {
  overflow: hidden;
}

.collection-img-2 a {
  display: block;
}

.collection-img-2 a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.collection-img-2:hover a img {
  transform: scale(1.2);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .collection-img-2.mb-37 {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .collection-img-2.mb-37 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection-img-2.mb-37 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection-img-2.mb-37 {
    margin-bottom: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .collection-img-2.mb-37 {
    margin-bottom: 15px;
  }
}

.flower-best-choice-pl {
  padding-left: 330px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .flower-best-choice-pl {
    padding-left: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .flower-best-choice-pl {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flower-best-choice-pl {
    padding-left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-best-choice-pl {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-best-choice-pl {
    padding-left: 15px;
  }
}

.flower-best-choice-area {
  background: linear-gradient(to right, #e1f4f9, #e1f4f9 84%, #e1f4f9 16%, #fff 0%);
}

.single-flower-best-choice {
  position: relative;
}

.single-flower-best-choice .flower-best-content {
  position: absolute;
  left: 45px;
  bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .single-flower-best-choice .flower-best-content {
    left: 15px;
    bottom: 10px;
  }
}

.single-flower-best-choice .flower-best-content h3 {
  font-size: 46px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
  font-style: italic;
  margin: 0;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .single-flower-best-choice .flower-best-content h3 {
    font-size: 30px;
  }
}

.single-flower-best-choice .flower-best-content h3 a {
  color: #fff;
}

.single-flower-best-choice .flower-best-content h3 a:hover {
  color: #3ad9b1;
}

.flower-best-choice-slider {
  overflow: hidden;
}

.flower-best-choice-slider .slick-list {
  padding-right: 520px;
  margin-right: -74px;
  margin-left: -74px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .flower-best-choice-slider .slick-list {
    padding-right: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .flower-best-choice-slider .slick-list {
    padding-right: 200px;
    margin-right: -25px;
    margin-left: -25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flower-best-choice-slider .slick-list {
    padding-right: 100px;
    margin-right: -25px;
    margin-left: -25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-best-choice-slider .slick-list {
    padding-right: 150px;
    margin-right: -25px;
    margin-left: -25px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-best-choice-slider .slick-list {
    padding-right: 80px;
    margin-right: -15px;
    margin-left: -15px;
  }
}

.flower-best-choice-slider .slick-list .single-flower-best-plr {
  padding-left: 74px;
  padding-right: 74px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .flower-best-choice-slider .slick-list .single-flower-best-plr {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flower-best-choice-slider .slick-list .single-flower-best-plr {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-best-choice-slider .slick-list .single-flower-best-plr {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-best-choice-slider .slick-list .single-flower-best-plr {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.flower-best-choice-slider .slick-list .single-flower-best-plr .single-flower-best-choice .flower-best-content h3 {
  display: none;
}

.flower-best-choice-slider .slick-list .single-flower-best-plr.slick-active .single-flower-best-choice .flower-best-content h3 {
  display: block;
}

.slick-slide .single-flower-best-choice .flower-best-img a img {
  width: 100%;
}

.flower-best-choice-icon-next {
  position: absolute;
  font-size: 77px;
  bottom: -12px;
  left: 54%;
  cursor: pointer;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .flower-best-choice-icon-next {
    left: 62%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .flower-best-choice-icon-next {
    left: 71%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flower-best-choice-icon-next {
    left: 81%;
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-best-choice-icon-next {
    left: 75%;
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-best-choice-icon-next {
    left: 65%;
    font-size: 53px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flower-best-choice-icon-next {
    left: 81%;
  }
}

.flower-best-choice-icon-next:hover {
  color: #3ad9b1;
}

.single-collection-wrap-2 {
  position: relative;
}

.single-collection-wrap-2 > a img {
  width: 100%;
}

.single-collection-wrap-2 > a:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #52e3b9;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

.single-collection-wrap-2 .collection-content-wrap-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.single-collection-wrap-2 .collection-content-wrap-2 h3 {
  font-size: 36px;
  color: #1b1b1b;
  font-weight: bold;
  margin: 0 0 0 -50px;
  position: relative;
  display: inline-block;
  padding: 21px 0 27px;
  font-family: "Playfair Display", serif;
  transition: all .4s ease 0s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-collection-wrap-2 .collection-content-wrap-2 h3 {
    font-size: 30px;
    padding: 18px 0 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-collection-wrap-2 .collection-content-wrap-2 h3 {
    font-size: 28px;
    padding: 14px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-collection-wrap-2 .collection-content-wrap-2 h3 {
    font-size: 25px;
    padding: 14px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-collection-wrap-2 .collection-content-wrap-2 h3 {
    font-size: 25px;
    padding: 11px 0 17px;
  }
}

@media only screen and (max-width: 767px) {
  .single-collection-wrap-2 .collection-content-wrap-2 h3 {
    font-size: 25px;
    padding: 11px 0 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-collection-wrap-2 .collection-content-wrap-2 h3 {
    font-size: 20px;
  }
}

.single-collection-wrap-2 .collection-content-wrap-2 h3 a {
  color: #1b1b1b;
}

.single-collection-wrap-2 .collection-content-wrap-2 h3 a:hover {
  color: #444;
}

.single-collection-wrap-2 .collection-content-wrap-2 h3:before {
  position: absolute;
  left: 0px;
  top: -30px;
  height: 2px;
  width: 100%;
  background-color: #1b1b1b;
  content: "";
  transition: all .6s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.single-collection-wrap-2 .collection-content-wrap-2 h3:after {
  position: absolute;
  left: 0px;
  bottom: -30px;
  height: 2px;
  width: 100%;
  background-color: #1b1b1b;
  content: "";
  transition: all .6s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.single-collection-wrap-2:hover > a:before {
  opacity: .9;
  visibility: visible;
}

.single-collection-wrap-2:hover .collection-content-wrap-2 h3 {
  opacity: 1;
  visibility: visible;
  margin: 0 0 0 0px;
}

.single-collection-wrap-2:hover .collection-content-wrap-2 h3:before {
  top: 0px;
  opacity: 1;
  visibility: visible;
}

.single-collection-wrap-2:hover .collection-content-wrap-2 h3:after {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area.pt-75 {
    padding-top: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-area.pt-90 {
    padding-top: 50px;
  }
  .product-area.pt-75 {
    padding-top: 28px;
  }
  .product-area.pb-60 {
    padding-bottom: 30px;
  }
  .pets-product-area.pb-55 {
    padding-bottom: 0px;
  }
}

.furniture-deal-area {
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.2);
}

.deal-slider-active {
  padding: 0 185px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .deal-slider-active {
    padding: 0 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-slider-active {
    padding: 0 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-slider-active {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-slider-active {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-slider-active {
    padding: 0 15px;
  }
}

.kids-pro-top-wrap {
  position: relative;
  z-index: 9;
  overflow: hidden;
  background-color: #fff;
  border-radius: 50px;
}

.kids-pro-top-wrap::after {
  border: 16px solid #000;
  bottom: 30px;
  content: "";
  left: 30px;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 9;
  pointer-events: none;
  border-radius: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-pro-top-wrap::after {
    bottom: 15px;
    left: 15px;
    right: 15px;
    top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-pro-top-wrap::after {
    bottom: 15px;
    left: 15px;
    right: 15px;
    top: 15px;
  }
}

.kids-pro-top-wrap a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.kids-pro-top-wrap .kids-pro-top-content {
  position: absolute;
  bottom: 22%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 75px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kids-pro-top-wrap .kids-pro-top-content {
    bottom: 13%;
    padding: 0 53px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-pro-top-wrap .kids-pro-top-content {
    bottom: 15%;
    padding: 0 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-pro-top-wrap .kids-pro-top-content {
    bottom: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-pro-top-wrap .kids-pro-top-content {
    bottom: 10%;
    padding: 0 52px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-pro-top-wrap .kids-pro-top-content {
    bottom: 10%;
    padding: 0 52px;
  }
}

.kids-pro-top-wrap .kids-pro-top-content h3 {
  font-size: 20px;
  color: #1b1b1b;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kids-pro-top-wrap .kids-pro-top-content h3 {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-pro-top-wrap .kids-pro-top-content h3 {
    font-size: 14px;
    line-height: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-pro-top-wrap .kids-pro-top-content h3 {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-pro-top-wrap .kids-pro-top-content h3 {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-pro-top-wrap .kids-pro-top-content h3 {
    font-size: 14px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .kids-pro-top-wrap .kids-pro-top-content h3 {
    font-size: 16px;
  }
}

.kids-pro-top-wrap .kids-pro-top-content h3 a {
  color: #1b1b1b;
}

.kids-pro-top-wrap .kids-pro-top-content h3 a:hover {
  color: #f2c456;
}

.kids-pro-top-wrap.pro-border-yellow::after {
  border: 16px solid #f2c456;
}

.kids-pro-top-wrap.pro-border-green::after {
  border: 16px solid #3ad9b1;
}

.kids-pro-top-wrap.pro-border-pink::after {
  border: 16px solid #fd76e5;
}

.kids-pro-top-wrap:hover > a img {
  transform: scale(1.1);
}

.product-area-negative-mrg {
  margin-top: -62px;
}

.product-slider-1 {
  z-index: 9;
}

.deal-area {
  position: relative;
  overflow: hidden;
}

.deal-area .kids-pattern-1 {
  position: absolute;
  top: 9px;
  left: 68px;
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area .kids-pattern-1 {
    top: 20px;
    left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area .kids-pattern-1 {
    top: 20px;
    left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-area .kids-pattern-1 {
    top: 20px;
    left: auto;
    right: 0;
  }
}

.deal-area .kids-pattern-1 img {
  width: 249px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area .kids-pattern-1 img {
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area .kids-pattern-1 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-area .kids-pattern-1 img {
    width: 100px;
  }
}

.deal-area .kids-pattern-2 {
  position: absolute;
  top: 70px;
  right: 44%;
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
}

.deal-area .kids-pattern-2 img {
  width: 148px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area .kids-pattern-2 img {
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area .kids-pattern-2 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-area .kids-pattern-2 img {
    width: 100px;
  }
}

.deal-area .kids-pattern-3 {
  position: absolute;
  top: 29%;
  right: 0%;
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
}

.deal-area .kids-pattern-3 img {
  width: 262px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area .kids-pattern-3 img {
    width: 162px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area .kids-pattern-3 img {
    width: 162px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-area .kids-pattern-3 img {
    width: 162px;
  }
}

.deal-area .kids-pattern-4 {
  position: absolute;
  bottom: 15%;
  right: 53px;
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
}

@media only screen and (max-width: 767px) {
  .deal-area .kids-pattern-4 {
    right: 10px;
  }
}

.deal-area .kids-pattern-4 img {
  width: 157px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area .kids-pattern-4 img {
    width: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area .kids-pattern-4 img {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-area .kids-pattern-4 img {
    width: 80px;
  }
}

.deal-area .kids-pattern-5 {
  position: absolute;
  bottom: 65px;
  left: 10%;
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
}

@media only screen and (max-width: 767px) {
  .deal-area .kids-pattern-5 {
    left: 2%;
  }
}

.deal-area .kids-pattern-5 img {
  width: 157px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area .kids-pattern-5 img {
    width: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area .kids-pattern-5 img {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-area .kids-pattern-5 img {
    width: 80px;
  }
}

.kids-deal-img {
  margin-left: -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-deal-img {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-deal-img {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-deal-img {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-deal-img {
    margin-left: 0px;
  }
}

.kids-deal-img a img {
  width: 100%;
}

.kids-deal-content {
  margin: 0 -52px 0 66px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-deal-content {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-deal-content {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-deal-content {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-deal-content {
    margin: 15px 0px 0 0px;
  }
}

.kids-deal-content h2 {
  color: #1b1b1b;
  font-size: 46px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .kids-deal-content h2 {
    font-size: 38px;
  }
}

.kids-deal-content > p {
  color: #1b1b1b;
  font-size: 24px;
  font-weight: 300;
  margin: 27px auto 55px;
  width: 81%;
  line-height: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-deal-content > p {
    width: 95%;
    font-size: 20px;
    margin: 17px auto 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-deal-content > p {
    width: 95%;
    font-size: 16px;
    margin: 10px auto 25px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-deal-content > p {
    width: 95%;
    font-size: 17px;
    margin: 3px auto 25px;
  }
}

.timer-style-2 {
  margin: 0 0 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .timer-style-2 {
    margin: 0 0 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-2 {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-2 {
    margin: 0 0 15px;
  }
}

.timer-style-2 > div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.timer-style-2 > div > span {
  font-size: 24px;
  color: #1b1b1b;
  font-style: italic;
  width: 120px;
  height: 120px;
  background-color: #ffedc3;
  text-align: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .timer-style-2 > div > span {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .timer-style-2 > div > span {
    margin: 0 12px;
    width: 90px;
    height: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-2 > div > span {
    margin: 0 10px 12px;
    width: 90px;
    height: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-2 > div > span {
    margin: 0 10px 12px;
    width: 90px;
    height: 90px;
  }
}

.timer-style-2 > div > span p {
  margin: 0;
  font-size: 16px;
  color: #1b1b1b;
  line-height: 1;
}

.product-tab-list-3 {
  display: flex;
  justify-content: center;
}

.product-tab-list-3 a {
  display: inline-block;
  line-height: 1;
  margin: 0 36px;
  color: #ff6e21;
  font-size: 32px;
  padding: 0 0 21px;
  position: relative;
  text-align: center;
  font-family: "Fredoka One", cursive;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-3 a {
    margin: 0 26px;
    font-size: 27px;
    padding: 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-3 a {
    margin: 0 17px;
    font-size: 22px;
    padding: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3 a {
    margin: 0 10px 10px;
    font-size: 22px;
    padding: 0 0 11px;
  }
}

.product-tab-list-3 a:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 5px;
  bottom: 0;
  border-radius: 5px;
  background-color: transparent;
}

.product-tab-list-3 a.active:before {
  background-color: #c7c7c7;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3.mb-50 {
    margin-bottom: 20px;
  }
}

.product-cake-wrap .product-cake-img {
  overflow: hidden;
}

.product-cake-wrap .product-cake-img a {
  display: block;
}

.product-cake-wrap .product-cake-img a img {
  width: 100%;
  transform: scale(1) rotate(0deg);
  transition: all .5s ease 0s;
}

.product-cake-wrap .product-cake-content h2 {
  font-size: 66px;
  color: #1b1b1b;
  margin: 0;
  font-family: "SignPainter HouseScript";
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-cake-wrap .product-cake-content h2 {
    font-size: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .product-cake-wrap .product-cake-content h2 {
    font-size: 45px;
    margin: 20px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-cake-wrap .product-cake-content h2 {
    font-size: 40px;
    margin: 0px 0 0;
  }
}

.product-cake-wrap .product-cake-content h2 a {
  color: #1b1b1b;
}

.product-cake-wrap .product-cake-content h2 a:hover {
  color: #ef8da1;
}

.product-cake-wrap .product-cake-content p {
  color: #8b8b8b;
  font-size: 18px;
  line-height: 36px;
  margin: 17px 0 29px;
  width: 88%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-cake-wrap .product-cake-content p {
    font-size: 15px;
    line-height: 30px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-cake-wrap .product-cake-content p {
    font-size: 15px;
    line-height: 28px;
    margin: 10px 0 20px;
    width: 100%;
  }
}

.product-cake-wrap:hover .product-cake-img a img {
  transform: scale(1.02) rotate(5deg);
}

.cake-product-mrg1 {
  margin-top: -25px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .cake-product-mrg1 {
    margin-top: 0px;
  }
  .cake-product-mrg1.pb-85 {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cake-product-mrg1 {
    margin-top: 0px;
  }
  .cake-product-mrg1.pb-85 {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-product-mrg1 {
    margin-top: 0px;
  }
  .cake-product-mrg1.pb-85 {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-product-mrg1 {
    margin-top: 0px;
  }
  .cake-product-mrg1.pb-85 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-product-mrg1.pb-85 {
    padding-bottom: 10px;
  }
}

.cake-product-mrg2 {
  margin-top: -55px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .cake-product-mrg2 {
    margin-top: 0px;
  }
  .cake-product-mrg2.pb-50 {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cake-product-mrg2 {
    margin-top: 0px;
  }
  .cake-product-mrg2.pb-50 {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-product-mrg2 {
    margin-top: 0px;
  }
  .cake-product-mrg2.pb-50 {
    padding-bottom: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-product-mrg2 {
    margin-top: 0px;
  }
  .cake-product-mrg2.pb-50 {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-product-mrg2 {
    margin-top: 30px;
  }
  .cake-product-mrg2.pb-50 {
    padding-bottom: 0px;
  }
}

.organic-product-ptb-1 {
  padding: 401px 0 300px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-product-ptb-1 {
    padding: 301px 0 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-product-ptb-1 {
    padding: 301px 0 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-product-ptb-1 {
    padding: 280px 0 250px;
    padding: 150px 0 250px;
    background-position: 69%;
  }
}

@media only screen and (max-width: 767px) {
  .organic-product-ptb-1 {
    padding: 100px 0 100px;
    background-position: 86%;
  }
}

.organic-product-ptb-2 {
  padding: 189px 0 359px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-product-ptb-2 {
    padding: 189px 0 259px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-product-ptb-2 {
    padding: 189px 0 259px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-product-ptb-2 {
    padding: 150px 0 280px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-product-ptb-2 {
    padding: 80px 0 150px;
  }
}

/*-------- 6. Banner style ---------*/
.banner-wrap {
  position: relative;
}

.banner-wrap a img {
  width: 100%;
}

.banner-wrap .banner-btn-position {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 230px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-btn-position {
    right: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-btn-position {
    right: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-btn-position {
    right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-btn-position {
    right: 15px;
  }
}

.btn-style-1 a {
  font-size: 16px;
  color: #1b1b1b;
  display: inline-block;
  background-color: #fff;
  line-height: 1;
  padding: 22px 37px 26px;
  z-index: 2;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .btn-style-1 a {
    padding: 12px 15px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .btn-style-1 a {
    font-size: 12px;
    padding: 5px 11px 7px;
  }
}

.banner-wrap .banner-img {
  position: relative;
  overflow: hidden;
}

.banner-wrap .banner-img a {
  display: block;
}

.banner-wrap .banner-img a img {
  width: 100%;
  transform: scale(1);
  transition: all 0.3s ease 0s;
}

.banner-wrap .banner-img .banner-badge {
  position: absolute;
  top: 27px;
  right: 70px;
}

.banner-wrap .banner-img .banner-badge span {
  display: inline-block;
  color: #fff;
  background-color: #ff6e21;
  height: 62px;
  width: 62px;
  line-height: 62px;
  text-align: center;
  letter-spacing: 1.11px;
  border-radius: 100%;
}

.banner-wrap .banner-position-1 {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-1 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    left: 20px;
  }
}

.banner-wrap .banner-position-2 {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 44px;
}

.banner-wrap .banner-content-1 h5 {
  color: #fff;
  margin: 0 0 5px;
}

.banner-wrap .banner-content-1 h2 {
  font-size: 32px;
  margin: 0;
  font-weight: 500;
}

.banner-wrap .banner-content-1 h2.yellow {
  color: #ffe30b;
}

.banner-wrap .banner-content-1 h2.lightblue {
  color: #d4fff8;
}

.banner-wrap .banner-content-1 span {
  color: #ff6e21;
  margin: 0px 0 10px;
  display: block;
  font-weight: 500;
}

.banner-wrap .banner-content-2 h2 {
  font-size: 18px;
  color: #1b1b1b;
  margin: 0;
  font-weight: 500;
}

.banner-wrap .banner-content-2 h2 a {
  color: #1b1b1b;
}

.banner-wrap .banner-content-2 h2 a:hover {
  color: #444;
}

.banner-wrap:hover .banner-img a img {
  transform: scale(1.2);
}

.banner-gradient-area {
  background: radial-gradient(circle, #58259A, #000000);
}

.banner-zoom-wrap {
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease 0s;
  margin-right: 50px;
}

@media only screen and (max-width: 767px) {
  .banner-zoom-wrap {
    margin-right: 0px;
  }
}

.banner-zoom-wrap a img {
  display: block;
  transition: all 0.5s ease-out 0s;
  width: 100%;
}

.banner-zoom-wrap a img:last-child {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: scale(1.5);
  visibility: hidden;
}

.banner-zoom-wrap:hover .zoom-img img {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

.banner-zoom-content {
  margin-right: -33px;
  margin-left: 95px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-zoom-content {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-zoom-content {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-zoom-content {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-zoom-content {
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 20px;
    text-align: center;
  }
}

.banner-zoom-content h2 {
  font-size: 44px;
  font-weight: 600;
  color: #fff;
  line-height: 53px;
  margin: 0 0 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-zoom-content h2 {
    margin: 0 0 50px;
    font-size: 36px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-zoom-content h2 {
    margin: 0 0 30px;
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-zoom-content h2 {
    margin: 0 0 30px;
    font-size: 27px;
    line-height: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-zoom-content h2 {
    margin: 0 0 20px;
    font-size: 23px;
    line-height: 40px;
  }
}

.banner-bg-1-content {
  margin-right: -104px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-bg-1-content {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-bg-1-content {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-bg-1-content {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-1-content {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-1-content {
    margin-right: 0px;
  }
}

.banner-bg-1-content h3 {
  color: #1b1b1b;
  font-size: 32px;
  margin: 0;
  font-weight: bold;
  font-family: "Rufina", serif;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-bg-1-content h3 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-bg-1-content h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-bg-1-content h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-1-content h3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-1-content h3 {
    font-size: 16px;
  }
}

.banner-bg-1-content h2 {
  color: #1b1b1b;
  font-size: 105px;
  margin: 33px 0 13px;
  font-weight: bold;
  font-family: "Rufina", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-bg-1-content h2 {
    margin: 23px 0 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-bg-1-content h2 {
    margin: 23px 0 13px;
    font-size: 88px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-bg-1-content h2 {
    margin: 23px 0 13px;
    font-size: 88px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-1-content h2 {
    margin: 12px 0 20px;
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-1-content h2 {
    margin: 10px 0 13px;
    font-size: 50px;
  }
}

.valentine-wrap {
  position: relative;
  margin-right: 420px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .valentine-wrap {
    margin-right: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .valentine-wrap {
    margin-right: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .valentine-wrap {
    margin-right: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .valentine-wrap {
    margin-right: 0px;
  }
}

.valentine-wrap img {
  max-width: 100%;
}

.valentine-wrap .valentine-content {
  padding: 120px 80px 100px;
  background-color: #fff;
  display: inline-block;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  margin-right: -375px;
  float: right;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .valentine-wrap .valentine-content {
    margin-right: -205px;
    padding: 60px 60px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .valentine-wrap .valentine-content {
    margin-right: -155px;
    padding: 50px 60px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .valentine-wrap .valentine-content {
    margin-right: -55px;
    padding: 50px 60px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .valentine-wrap .valentine-content {
    padding: 30px 15px 30px;
    margin: 0 15px;
    float: left;
  }
}

.valentine-wrap .valentine-content span {
  color: #654600;
  font-weight: 600;
  letter-spacing: 3.57px;
}

.valentine-wrap .valentine-content h3 {
  color: #1b1b1b;
  font-weight: bold;
  font-size: 46px;
  margin: 14px 0 0;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .valentine-wrap .valentine-content h3 {
    margin: 8px 0 8px;
    font-size: 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .valentine-wrap .valentine-content h3 {
    margin: 8px 0 8px;
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .valentine-wrap .valentine-content h3 {
    margin: 8px 0 8px;
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .valentine-wrap .valentine-content h3 {
    margin: 5px 0 8px;
    font-size: 25px;
  }
}

.valentine-wrap .valentine-content h2 {
  color: #ff6e21;
  font-weight: bold;
  font-size: 86px;
  line-height: 1;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .valentine-wrap .valentine-content h2 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .valentine-wrap .valentine-content h2 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .valentine-wrap .valentine-content h2 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .valentine-wrap .valentine-content h2 {
    font-size: 45px;
  }
}

.valentine-wrap .valentine-content p {
  font-size: 18px;
  margin: 41px 0 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .valentine-wrap .valentine-content p {
    margin: 21px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .valentine-wrap .valentine-content p {
    margin: 21px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .valentine-wrap .valentine-content p {
    margin: 21px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .valentine-wrap .valentine-content p {
    font-size: 14px;
    margin: 18px 0 17px;
  }
}

.btn-style-5 a {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  z-index: 9;
}

.btn-style-5 a.bs5-white-text {
  color: #fff;
}

.btn-style-5 a.bs5-green-bg {
  background-color: #3ad9b1;
}

.btn-style-5 a.bs5-ptb-1 {
  padding: 22px 47px 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-5 a.bs5-ptb-1 {
    padding: 18px 37px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-5 a.bs5-ptb-1 {
    padding: 18px 37px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-5 a.bs5-ptb-1 {
    padding: 16px 35px 18px;
  }
}

.banner-bg-content-2 {
  position: relative;
  z-index: 9;
}

.banner-bg-content-2 h2 {
  font-size: 66px;
  font-weight: bold;
  font-style: italic;
  margin: 0;
  line-height: 1;
  color: #fff;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-bg-content-2 h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-bg-content-2 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-bg-content-2 h2 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-content-2 h2 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-2 h2 {
    font-size: 28px;
    line-height: 40px;
  }
}

.banner-bg-content-2 h3 {
  font-size: 46px;
  font-weight: bold;
  font-style: italic;
  margin: 56px 0 26px;
  line-height: 1;
  color: #fff;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-bg-content-2 h3 {
    margin: 40px 0 26px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-bg-content-2 h3 {
    margin: 40px 0 26px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-bg-content-2 h3 {
    margin: 40px 0 26px;
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-content-2 h3 {
    margin: 25px 0 26px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-2 h3 {
    margin: 10px 0 26px;
    font-size: 23px;
  }
}

.default-overlay.hamdmade-bg-overly:before {
  background-color: #1b1b1b;
  opacity: .3;
}

.hamdmade-bg-area {
  padding: 265px 0 275px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hamdmade-bg-area {
    padding: 165px 0 175px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hamdmade-bg-area {
    padding: 165px 0 175px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hamdmade-bg-area {
    padding: 150px 0 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hamdmade-bg-area {
    padding: 130px 0 140px;
  }
}

@media only screen and (max-width: 767px) {
  .hamdmade-bg-area {
    padding: 80px 0 90px;
  }
}

.banner-offer-wrap {
  padding-left: 525px;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-offer-wrap {
    padding-left: 325px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-offer-wrap {
    padding-left: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-offer-wrap {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-offer-wrap {
    padding-left: 0px;
  }
}

.banner-offer-wrap img {
  width: 100%;
}

.banner-offer-wrap .banner-offer-content-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 120px;
  background-color: #fff;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.2);
  padding: 117px 83px 50px 83px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-offer-wrap .banner-offer-content-1 {
    left: 40px;
    padding: 50px 50px 50px 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-offer-wrap .banner-offer-content-1 {
    left: 15px;
    padding: 50px 50px 50px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-offer-wrap .banner-offer-content-1 {
    left: 30px;
    padding: 50px 50px 50px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-offer-wrap .banner-offer-content-1 {
    position: static;
    top: 50%;
    transform: translateY(0%);
    left: 0px;
    width: 100%;
    padding: 50px 50px 50px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-offer-wrap .banner-offer-content-1 {
    position: static;
    top: 0%;
    transform: inherit;
    left: 0px;
    width: 100%;
    padding: 43px 15px 50px 15px;
  }
}

.banner-offer-wrap .banner-offer-content-1 span {
  color: #654600;
  letter-spacing: 3.57px;
  font-weight: 600;
}

.banner-offer-wrap .banner-offer-content-1 h1 {
  color: #1b1b1b;
  font-size: 46px;
  font-weight: 600;
  font-family: "Zilla Slab", serif;
  margin: 11px 0 40px;
  line-height: 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-offer-wrap .banner-offer-content-1 h1 {
    margin: 11px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-offer-wrap .banner-offer-content-1 h1 {
    margin: 11px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-offer-wrap .banner-offer-content-1 h1 {
    margin: 11px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-offer-wrap .banner-offer-content-1 h1 {
    margin: 11px 0 15px;
    font-size: 36px;
  }
}

.banner-offer-wrap .banner-offer-content-1 h3 {
  color: #f2c456;
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-offer-wrap .banner-offer-content-1 h3 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-offer-wrap .banner-offer-content-1 h3 {
    font-size: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-offer-wrap .banner-offer-content-1 h3 {
    font-size: 19px;
  }
}

.banner-offer-wrap .banner-offer-content-1 p {
  font-size: 18px;
  line-height: 36px;
  margin: 24px 0 45px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-offer-wrap .banner-offer-content-1 p {
    margin: 24px 0 27px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-offer-wrap .banner-offer-content-1 p {
    margin: 11px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-offer-wrap .banner-offer-content-1 p {
    margin: 11px 0 15px;
    font-size: 16px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-offer-wrap .banner-offer-content-1 p {
    margin: 11px 0 15px;
    font-size: 16px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-offer-wrap .banner-offer-content-1 p {
    margin: 11px 0 15px;
    font-size: 16px;
    line-height: 30px;
  }
}

.banner-offer-wrap .offer-young {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 242px;
  background-color: #fff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-offer-wrap .offer-young {
    right: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-offer-wrap .offer-young {
    right: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-offer-wrap .offer-young {
    right: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-offer-wrap .offer-young {
    top: 24%;
    left: 50%;
    transform: translateX(-50%) translateY(0%);
    right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .banner-offer-wrap .offer-young {
    top: 8%;
    left: 50%;
    transform: translateX(-50%) translateY(0%);
    right: auto;
  }
}

.banner-offer-wrap .offer-young h2 {
  font-size: 147px;
  color: #000;
  font-weight: bold;
  line-height: 1;
  padding: 56px 68px 54px;
  margin: 0;
  position: relative;
  text-transform: capitalize;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: url(../../assets/images/banner/furniture-offer-banner-1.jpg);
  background-position: 57%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-offer-wrap .offer-young h2 {
    font-size: 100px;
    padding: 30px 50px 28px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-offer-wrap .offer-young h2 {
    font-size: 100px;
    padding: 30px 50px 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-offer-wrap .offer-young h2 {
    font-size: 50px;
    padding: 20px 40px 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-offer-wrap .offer-young h2 {
    font-size: 50px;
    padding: 20px 40px 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-offer-wrap .offer-young h2 {
    font-size: 40px;
    padding: 15px 30px 13px;
  }
}

.furniture-banner-wrap {
  position: relative;
}

.furniture-banner-wrap > a {
  display: block;
}

.furniture-banner-wrap > a img {
  width: 100%;
}

.furniture-banner-wrap .furniture-banner-content-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content {
  display: inline-block;
  background-color: rgba(27, 27, 27, 0.9);
  padding: 101px 75px 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content {
    padding: 50px 75px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content {
    padding: 50px 75px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content {
    padding: 30px 50px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content {
    padding: 75px 75px 0;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content {
    padding: 20px 15px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content {
    padding: 20px 30px 0;
  }
}

.furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content h2 {
  font-size: 36px;
  color: #fff;
  font-weight: 600;
  margin: 0 0 20px;
  padding: 0 0 28px;
  font-family: "Zilla Slab", serif;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content h2 {
    font-size: 26px;
    padding: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content h2 {
    font-size: 18px;
    margin: 0 0 10px;
    padding: 0 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content h2 {
    font-size: 20px;
    margin: 0 0 20px;
    padding: 0 0 20px;
  }
}

.furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content h2::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 66px;
  background-color: #f2c456;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content p {
  font-size: 18px;
  color: #aeaeae;
  line-height: 32px;
  margin: 0 auto 42px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content p {
    font-size: 16px;
    margin: 0 auto 25px;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content p {
    font-size: 12px;
    line-height: 26px;
    margin: 0 auto 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content p {
    font-size: 14px;
    line-height: 28px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content .slider-btn-1 a {
    padding: 15px 30px 18px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content .slider-btn-1 a {
    padding: 12px 25px 15px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content .slider-btn-1 a {
    padding: 12px 25px 15px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-banner-wrap .furniture-banner-content-wrap .furniture-banner-content .slider-btn-1 a {
    padding: 8px 15px 11px;
    font-size: 14px;
  }
}

.furniture-banner-wrap:hover .furniture-banner-content-wrap .furniture-banner-content {
  opacity: 1;
  visibility: visible;
}

.furniture-banner-wrap:hover .furniture-banner-content-wrap .furniture-banner-content h2 {
  animation-delay: .3s;
  animation-name: zoomIn;
}

.furniture-banner-wrap:hover .furniture-banner-content-wrap .furniture-banner-content p {
  animation-delay: .6s;
  animation-name: zoomIn;
}

.furniture-banner-wrap:hover .furniture-banner-content-wrap .furniture-banner-content .slider-btn-1 a {
  animation-delay: .9s;
  animation-name: zoomIn;
}

.furniture-banner-content-wrap .furniture-banner-content * {
  animation-duration: 1.0s;
  animation-fill-mode: both;
}

.grid-sizer {
  width: 25%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.pb-85 {
    padding-bottom: 35px;
  }
  .book-banner-bg {
    background-position: 22%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.pb-85 {
    padding-bottom: 12px;
  }
  .book-banner-bg {
    background-position: 30%;
  }
}

/*-------- 7. Video style ---------*/
.youtube-video-height {
  height: 610px !important;
  padding: 0 !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .youtube-video-height {
    height: 500px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .youtube-video-height {
    height: 500px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .youtube-video-height {
    height: 450px !important;
  }
}

@media only screen and (max-width: 767px) {
  .youtube-video-height {
    height: 300px !important;
  }
}

.video-wrap {
  position: relative;
}

.video-wrap .video-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  z-index: 9;
}

.video-wrap .video-content h2 {
  font-size: 120px;
  color: #fff;
  font-weight: bold;
  border: 15px solid #fff;
  line-height: 1;
  padding: 53px 50px 53px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-wrap .video-content h2 {
    padding: 40px 50px 40px;
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-wrap .video-content h2 {
    padding: 30px 50px 30px;
    font-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .video-wrap .video-content h2 {
    font-size: 40px;
    padding: 25px 30px 24px;
  }
}

.cake-video-ptb {
  padding: 352px 0 352px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cake-video-ptb {
    padding: 200px 0 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-video-ptb {
    padding: 220px 0 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-video-ptb {
    padding: 200px 0 160px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-video-ptb {
    padding: 130px 0 100px;
  }
}

.cake-video-content {
  position: relative;
  z-index: 9;
}

.cake-video-content h2 {
  color: #fff;
  font-size: 80px;
  margin: 0;
  font-family: "SignPainter HouseScript";
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-video-content h2 {
    font-size: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-video-content h2 {
    font-size: 45px;
  }
}

.cake-video-content h1 {
  color: #fff;
  font-size: 100px;
  margin: 3px 0 16px;
  font-family: "SignPainter HouseScript";
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-video-content h1 {
    font-size: 90px;
    margin: 3px 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-video-content h1 {
    font-size: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-video-content h1 {
    font-size: 35px;
  }
}

.cake-video-content .video-btn-2 a {
  display: inline-block;
  width: 105px;
  height: 105px;
  text-align: center;
  line-height: 105px;
  background-color: #ef8da1;
  color: #fff;
  border-radius: 100%;
  font-size: 20px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .cake-video-content .video-btn-2 a {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

.cake-video-content .video-btn-2 a i {
  line-height: 105px;
}

@media only screen and (max-width: 767px) {
  .cake-video-content .video-btn-2 a i {
    line-height: 60px;
  }
}

.cake-video-content .video-btn-2 a::before {
  animation: 1500ms ease-out 0s normal none infinite running border-jump;
  background: #ef8da1 none repeat scroll 0 0;
  border-radius: 50%;
  content: "";
  display: block;
  height: 90px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90px;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .cake-video-content .video-btn-2 a::before {
    width: 60px;
    height: 60px;
  }
}

.cake-video-content .video-btn-2 a::after {
  background: #ef8da1 none repeat scroll 0 0;
  border-radius: 50%;
  content: "";
  display: block;
  height: 90px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 200ms ease 0s;
  width: 90px;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .cake-video-content .video-btn-2 a::after {
    width: 60px;
    height: 60px;
  }
}

.cake-video-content .video-btn-2 a:hover {
  background-color: #191919;
  color: #fff;
}

@-webkit-keyframes border-jump {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
    border-radius: 100%;
  }
}

@keyframes border-jump {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
    border-radius: 100%;
  }
}

/*-------- 8. Blog style ---------*/
.blog-wrap {
  background-color: #fff;
}

.blog-wrap .blog-img {
  overflow: hidden;
  position: relative;
}

.blog-wrap .blog-img a img {
  width: 100%;
  transform: scale(1) rotate(0deg);
  transition: all .5s ease 0s;
}

.blog-wrap .blog-img .video-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

.blog-wrap .blog-img .video-btn a {
  display: inline-block;
  width: 58px;
  height: 58px;
  text-align: center;
  line-height: 58px;
  background-color: #fff;
  color: #191919;
  border-radius: 100%;
}

.blog-wrap .blog-img .video-btn a i {
  line-height: 58px;
}

.blog-wrap .blog-img .video-btn a:hover {
  background-color: #191919;
  color: #fff;
}

.blog-wrap .blog-content {
  padding: 26px 35px 48px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap .blog-content {
    padding: 26px 30px 48px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content {
    padding: 25px 15px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content {
    padding: 26px 20px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content {
    padding: 22px 20px 30px;
  }
}

.blog-wrap .blog-content .blog-category > a {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 3.57px;
  position: relative;
  padding-left: 32px;
  display: block;
}

.blog-wrap .blog-content .blog-category > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1b1b1b;
  width: 22px;
  height: 1px;
}

.blog-wrap .blog-content .blog-category.organic-blog-category > a {
  color: #3cd53f;
}

.blog-wrap .blog-content h3 {
  font-size: 22px;
  font-weight: 500;
  margin: 6px 0 11px;
}

.blog-wrap .blog-content h3.organic-blog-title {
  color: #393939;
}

.blog-wrap .blog-content h3.organic-blog-title a {
  color: #393939;
}

.blog-wrap .blog-content h3.organic-blog-title a:hover {
  color: #3cd53f;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap .blog-content h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content h3 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content h3 {
    font-size: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content h3 {
    font-size: 18px;
  }
}

.blog-wrap .blog-content .blog-meta ul li {
  font-size: 12px;
  color: #1b1b1b;
  display: inline-block;
  margin-right: 2px;
}

.blog-wrap .blog-content .blog-meta ul li a {
  font-size: 12px;
  color: #1b1b1b;
}

.blog-wrap .blog-content .blog-meta ul li a:hover {
  color: #000;
}

.blog-wrap .blog-content .blog-meta.organic-meta ul li a {
  color: #1b1b1b;
}

.blog-wrap .blog-content .blog-meta.organic-meta ul li a:hover {
  color: #3cd53f;
}

.blog-wrap .blog-content p {
  color: #8b8b8b;
  line-height: 28px;
  margin: 3px 0 22px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content p {
    margin: 3px 0 8px;
  }
}

.blog-wrap .blog-content .blog-btn a {
  display: inline-block;
  color: #1b1b1b;
  line-height: 1;
  border-bottom: 1px solid #1b1b1b;
}

.blog-wrap .blog-content .blog-btn a:hover {
  color: #666;
  border-bottom: 1px solid #666;
}

.blog-wrap:hover .blog-img a img {
  transform: scale(1.2) rotate(3deg);
}

.dot-style-1 .owl-dots {
  text-align: center;
  margin-top: 30px;
}

.dot-style-1 .owl-dots .owl-dot {
  background-color: #d8d8d8;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 5px;
  transition: all .3s ease 0s;
}

.dot-style-1 .owl-dots .owl-dot.active, .dot-style-1 .owl-dots .owl-dot:hover {
  background-color: #1b1b1b;
}

.dot-style-1.dot-style-1-white .owl-dots .owl-dot {
  background-color: #fff;
}

.dot-style-1.dot-style-1-white .owl-dots .owl-dot.active, .dot-style-1.dot-style-1-white .owl-dots .owl-dot:hover {
  background-color: #1b1b1b;
}

.blog-wrap-2 {
  display: flex;
  flex-wrap: wrap;
}

.blog-wrap-2 .blog-img-2 {
  flex: 0 0 45%;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-img-2 {
    flex: 0 0 100%;
  }
}

.blog-wrap-2 .blog-img-2 a {
  display: block;
}

.blog-wrap-2 .blog-img-2 a img {
  width: 100%;
  transform: scale(1) rotate(0deg);
  transition: all .5s ease 0s;
}

.blog-wrap-2 .blog-content-2 {
  flex: 0 0 55%;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 {
    flex: 0 0 100%;
  }
}

.blog-wrap-2 .blog-content-2 .blog-category-2 a {
  text-transform: uppercase;
  font-size: 12px;
  color: #1d1d1d;
}

.blog-wrap-2 .blog-content-2 .blog-category-2 a:hover {
  color: #ff6e21;
}

.blog-wrap-2 .blog-content-2 .blog-category-2.blog-category-pink a {
  color: #1d1d1d;
}

.blog-wrap-2 .blog-content-2 .blog-category-2.blog-category-pink a:hover {
  color: #ef8da1;
}

.blog-wrap-2 .blog-content-2 h3 {
  font-size: 24px;
  color: #1d1d1d;
  margin: 6px 0 19px;
}

.blog-wrap-2 .blog-content-2 h3.rufina-font {
  font-family: "Rufina", serif;
}

.blog-wrap-2 .blog-content-2 h3.signpainter-font {
  font-family: "SignPainter HouseScript";
  font-size: 36px;
  line-height: 35px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 h3.signpainter-font {
    font-size: 30px;
    line-height: 30px;
  }
}

.blog-wrap-2 .blog-content-2 h3.signpainter-font a {
  color: #1d1d1d;
}

.blog-wrap-2 .blog-content-2 h3.signpainter-font a:hover {
  color: #ef8da1;
}

.blog-wrap-2 .blog-content-2 h3.pets-blog-title {
  font-family: "Fredoka One", cursive;
  font-size: 28px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2 h3.pets-blog-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 h3.pets-blog-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2 h3.pets-blog-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 h3.pets-blog-title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 22px;
    margin: 6px 0 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 20px;
    margin: 6px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 20px;
    margin: 6px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 20px;
    margin: 6px 0 4px;
  }
}

.blog-wrap-2 .blog-content-2 h3 a {
  color: #1d1d1d;
}

.blog-wrap-2 .blog-content-2 h3 a:hover {
  color: #ff6e21;
}

.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li {
  font-size: 12px;
  color: #1b1b1b;
  display: inline-block;
  margin-right: 2px;
}

.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li a {
  font-size: 12px;
  color: #1b1b1b;
}

.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li a:hover {
  color: #ff6e21;
}

.blog-wrap-2 .blog-content-2 .blog-meta-2.blog-meta-pink ul li a {
  color: #1b1b1b;
}

.blog-wrap-2 .blog-content-2 .blog-meta-2.blog-meta-pink ul li a:hover {
  color: #ef8da1;
}

.blog-wrap-2 .blog-content-2 p {
  font-size: 14px;
  color: #1b1b1b;
  margin: 11px 0 30px;
  line-height: 27px;
  width: 90%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2 p {
    width: 100%;
    margin: 10px 0 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 p {
    width: 100%;
    margin: 11px 0 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2 p {
    width: 100%;
    margin: 7px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 p {
    width: 100%;
    margin: 7px 0 9px;
    line-height: 25px;
  }
}

.blog-wrap-2 .blog-content-2.bc-padding-left {
  padding-left: 45px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2.bc-padding-left {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2.bc-padding-left {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2.bc-padding-left {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2.bc-padding-left {
    padding-left: 0px;
    padding-top: 15px;
  }
}

.blog-wrap-2:hover .blog-img-2 a img {
  transform: scale(1.2) rotate(3deg);
}

.blog-wrap-3 {
  position: relative;
}

.blog-wrap-3 .blog-img-3 {
  position: relative;
  overflow: hidden;
}

.blog-wrap-3 .blog-img-3::before {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #1b1b1b);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9;
}

.blog-wrap-3 .blog-img-3 a {
  display: block;
}

.blog-wrap-3 .blog-img-3 a img {
  width: 100%;
  transform: scale(1) rotate(0deg);
  transition: all .5s ease 0s;
}

.blog-wrap-3 .blog-content-3 {
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-3 .blog-content-3 {
    left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-3 .blog-content-3 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3 .blog-content-3 {
    left: 15px;
    bottom: 20px;
  }
}

.blog-wrap-3 .blog-content-3 span {
  display: inline-block;
  color: #1b1b1b;
  background-color: #f2c456;
  font-size: 12px;
  line-height: 1;
  padding: 7px 10px 8px;
}

.blog-wrap-3 .blog-content-3 h3 {
  color: #fff;
  font-size: 32px;
  font-family: "Zilla Slab", serif;
  margin: 22px 0 14px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-3 .blog-content-3 h3 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-3 .blog-content-3 h3 {
    font-size: 22px;
    margin: 17px 0 9px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-3 .blog-content-3 h3 {
    font-size: 25px;
    margin: 17px 0 9px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3 .blog-content-3 h3 {
    font-size: 22px;
    margin: 15px 0 7px;
  }
}

.blog-wrap-3 .blog-content-3 h3 a {
  color: #fff;
}

.blog-wrap-3 .blog-content-3 h3 a:hover {
  color: #f2c456;
}

.blog-wrap-3 .blog-content-3 .blog-meta-3 ul li {
  font-size: 12px;
  color: #fff;
  display: inline-block;
  margin-right: 2px;
}

.blog-wrap-3 .blog-content-3 .blog-meta-3 ul li a {
  font-size: 12px;
  color: #fff;
}

.blog-wrap-3 .blog-content-3 .blog-meta-3 ul li a:hover {
  color: #f2c456;
}

.blog-wrap-3:hover .blog-img-3 a img {
  transform: scale(1.1) rotate(3deg);
}

.blog-more a {
  color: #393939;
}

.blog-more a:hover {
  color: #f2c456;
}

@media only screen and (max-width: 767px) {
  .blog-more.mt-30 {
    margin-top: 10px;
  }
}

.kids-blog-ptb {
  padding: 162px 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-blog-ptb {
    padding: 120px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-blog-ptb {
    padding: 100px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-blog-ptb {
    padding: 100px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-blog-ptb {
    padding: 50px 0px;
  }
}

.organic-blog-ptb-1 {
  padding: 233px 0 243px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-blog-ptb-1 {
    padding: 200px 0 243px !important;
  }
}

@media only screen and (max-width: 767px) {
  .organic-blog-ptb-1 {
    padding: 100px 0 150px !important;
  }
}

.blog-area {
  position: relative;
  overflow: hidden;
}

.blog-area .kids-blog-pattern-1 {
  position: absolute;
  left: 0;
  top: 30%;
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-area .kids-blog-pattern-1 {
    top: 10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-area .kids-blog-pattern-1 {
    top: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area .kids-blog-pattern-1 {
    top: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area .kids-blog-pattern-1 {
    top: 4%;
  }
}

.blog-area .kids-blog-pattern-1 img {
  width: 224px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-area .kids-blog-pattern-1 img {
    width: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-area .kids-blog-pattern-1 img {
    width: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-area .kids-blog-pattern-1 img {
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area .kids-blog-pattern-1 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area .kids-blog-pattern-1 img {
    width: 80px;
  }
}

.blog-area .kids-blog-pattern-2 {
  position: absolute;
  left: 0;
  bottom: 70px;
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
}

@media only screen and (max-width: 767px) {
  .blog-area .kids-blog-pattern-2 {
    bbottom: 50px;
  }
}

.blog-area .kids-blog-pattern-2 img {
  width: 195px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-area .kids-blog-pattern-2 img {
    width: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-area .kids-blog-pattern-2 img {
    width: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-area .kids-blog-pattern-2 img {
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area .kids-blog-pattern-2 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area .kids-blog-pattern-2 img {
    width: 50px;
  }
}

.blog-area .kids-blog-pattern-3 {
  position: absolute;
  right: 0;
  bottom: 45px;
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
}

@media only screen and (max-width: 767px) {
  .blog-area .kids-blog-pattern-3 {
    bottom: 20px;
  }
}

.blog-area .kids-blog-pattern-3 img {
  width: 335px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-area .kids-blog-pattern-3 img {
    width: 140px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-area .kids-blog-pattern-3 img {
    width: 140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-area .kids-blog-pattern-3 img {
    width: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area .kids-blog-pattern-3 img {
    width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area .kids-blog-pattern-3 img {
    width: 100px;
  }
}

.blog-wrap-4 {
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-4 {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

.blog-wrap-4 .blog-img-4 {
  position: relative;
  overflow: hidden;
  margin: 0 0 13px;
}

.blog-wrap-4 .blog-img-4 > a {
  display: block;
}

.blog-wrap-4 .blog-img-4 > a > img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.blog-wrap-4 .blog-img-4 > span {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .96px;
  background-color: #1d1d1d;
  display: inline-block;
  line-height: 1;
  padding: 11px 50px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-4 .blog-img-4 > span {
    padding: 11px 20px;
  }
}

.blog-wrap-4 .blog-img-4 .video-btn-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

.blog-wrap-4 .blog-img-4 .video-btn-4 a {
  display: inline-block;
  width: 58px;
  height: 58px;
  text-align: center;
  line-height: 58px;
  background-color: #fff;
  color: #191919;
  border-radius: 100%;
}

.blog-wrap-4 .blog-img-4 .video-btn-4 a i {
  line-height: 58px;
}

.blog-wrap-4 .blog-img-4 .video-btn-4 a:hover {
  background-color: #191919;
  color: #fff;
}

.blog-wrap-4 .blog-content-4 h3 {
  font-size: 32px;
  color: #2f2f2f;
  margin: 0;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-4 .blog-content-4 h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-4 .blog-content-4 h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-4 .blog-content-4 h3 {
    font-size: 20px;
    line-height: 30px;
  }
}

.blog-wrap-4 .blog-content-4 h3 a {
  color: #2f2f2f;
}

.blog-wrap-4 .blog-content-4 h3 a:hover {
  color: #ff6e21;
}

.blog-wrap-4 .blog-content-4 .blog-meta-4 {
  padding: 10px 0 4px;
}

.blog-wrap-4 .blog-content-4 .blog-meta-4 ul li {
  font-size: 12px;
  color: #2f2f2f;
  display: inline-block;
  margin-right: 2px;
}

.blog-wrap-4 .blog-content-4 .blog-meta-4 ul li a {
  font-size: 12px;
  color: #2f2f2f;
}

.blog-wrap-4 .blog-content-4 .blog-meta-4 ul li a:hover {
  color: #ff6e21;
}

.blog-wrap-4 .blog-content-4 p {
  color: #707070;
  line-height: 28px;
  margin: 0 0 25px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-4 .blog-content-4 p {
    margin: 0 0 15px;
  }
}

.blog-wrap-4 .blog-content-4 .blog-btn-2 a {
  font-size: 12px;
  font-weight: bold;
  color: #1d1d1d;
  letter-spacing: 2px;
  display: inline-block;
  line-height: 1;
  border: 1px solid #979797;
  padding: 21px 28px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-4 .blog-content-4 .blog-btn-2 a {
    padding: 15px 20px;
  }
}

.blog-wrap-4 .blog-content-4 .blog-btn-2 a:hover {
  border: 1px solid #000;
  color: #fff;
}

.blog-wrap-4:hover > .blog-img-4 > a {
  transform: scale(1.1);
}

.quote-content {
  background-color: #2f2f2f;
  padding: 50px 187px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .quote-content {
    padding: 50px 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .quote-content {
    padding: 50px 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quote-content {
    padding: 50px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quote-content {
    padding: 40px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .quote-content {
    padding: 40px 15px;
  }
}

.quote-content h5 {
  font-size: 18px;
  color: #fff;
  font-family: "Playfair Display", serif;
  margin: 0 0 20px;
  line-height: 28px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .quote-content h5 {
    font-size: 16px;
    margin: 0 0 10px;
  }
}

.quote-content h5 a {
  color: #fff;
}

.quote-content h5 a:hover {
  color: #ff6e21;
}

.quote-content .blog-name span {
  font-weight: bold;
  color: #fff;
  display: inline-block;
  position: relative;
}

.quote-content .blog-name span:before {
  position: absolute;
  left: -45px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 32px;
  height: 2px;
  content: "";
}

.quote-content .blog-name span:after {
  position: absolute;
  right: -45px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 32px;
  height: 2px;
  content: "";
}

.quote-content.quote-content-mrg {
  margin: 40px 0 38px;
}

.main-blog-wrap {
  margin-left: 20px;
}

.main-blog-wrap.main-blog-wrap-mrg-none {
  margin-left: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-blog-wrap {
    margin-left: 0px;
    margin-bottom: 50px;
  }
  .main-blog-wrap.main-blog-wrap-mrg-none {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-blog-wrap {
    margin-left: 0px;
    margin-bottom: 50px;
  }
  .main-blog-wrap.main-blog-wrap-mrg-none {
    margin-bottom: 0;
  }
}

.blog-details-content .blog-details-img {
  position: relative;
}

.blog-details-content .blog-details-img img {
  width: 100%;
}

.blog-details-content .blog-details-img > span {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .96px;
  background-color: #1d1d1d;
  display: inline-block;
  line-height: 1;
  padding: 11px 50px;
}

.blog-details-content h3 {
  font-size: 32px;
  color: #2f2f2f;
  margin: 35px 0 0px;
  font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 767px) {
  .blog-details-content h3 {
    font-size: 20px;
    line-height: 30px;
    margin: 10px 0 0px;
  }
}

.blog-details-content .blog-meta-5 {
  padding: 10px 0 17px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-meta-5 {
    padding: 10px 0 10px;
  }
}

.blog-details-content .blog-meta-5 ul li {
  font-size: 12px;
  color: #2f2f2f;
  display: inline-block;
  margin-right: 2px;
}

.blog-details-content .blog-meta-5 ul li a {
  font-size: 12px;
  color: #2f2f2f;
}

.blog-details-content .blog-meta-5 ul li a:hover {
  color: #ff6e21;
}

.blog-details-content p {
  color: #707070;
  line-height: 28px;
  margin: 0 0 18px;
}

.blog-details-content .blog-dec-midle-content {
  margin: 38px 0 0;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-dec-midle-content {
    margin: 20px 0 0;
  }
}

.blog-details-content .blog-dec-midle-content img {
  width: 100%;
}

.blog-details-content .blog-dec-midle-content h3 {
  font-size: 32px;
  color: #2f2f2f;
  margin: 35px 0 18px;
  font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-dec-midle-content h3 {
    font-size: 20px;
    line-height: 30px;
    margin: 10px 0 0px;
  }
}

.blog-details-content .blog-dec-midle-content p {
  color: #707070;
  line-height: 28px;
  margin: 0 0 18px;
}

.blog-details-tag-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 46px 0 20px;
  flex-wrap: wrap;
  padding-bottom: 8px;
  border-bottom: 1px solid #d8d8d8;
}

@media only screen and (max-width: 767px) {
  .blog-details-tag-social {
    margin: 25px 0 25px;
  }
}

.blog-details-tag-social .blog-details-tag ul {
  display: flex;
}

.blog-details-tag-social .blog-details-tag ul li {
  color: #2f2f2f;
  margin-right: 3px;
}

.blog-details-tag-social .blog-details-tag ul li a {
  color: #2f2f2f;
}

.blog-details-tag-social .blog-details-tag ul li a:hover {
  color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .blog-details-tag-social .blog-details-social {
    margin-top: 10px;
  }
}

.blog-details-tag-social .blog-details-social ul {
  display: flex;
  align-items: center;
}

.blog-details-tag-social .blog-details-social ul li {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .blog-details-tag-social .blog-details-social ul li {
    margin-left: 4px;
  }
}

.blog-details-tag-social .blog-details-social ul li:first-child {
  margin-left: 0;
}

.blog-details-tag-social .blog-details-social ul li a {
  display: inline-block;
  color: #191919;
  font-size: 18px;
}

.blog-details-tag-social .blog-details-social ul li a.facebook:hover {
  color: #3b5999;
}

.blog-details-tag-social .blog-details-social ul li a.twitter:hover {
  color: #55acee;
}

.blog-details-tag-social .blog-details-social ul li a.linkedin:hover {
  color: #0173a9;
}

.blog-details-tag-social .blog-details-social ul li a.pinterest:hover {
  color: #b90f16;
}

.next-prev-wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 17px;
  border-bottom: 1px solid #d8d8d8;
}

.next-prev-wrap .next-prev-conent > h4 {
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 7px;
  color: #1d1d1d;
  font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 767px) {
  .next-prev-wrap .next-prev-conent > h4 {
    font-size: 14px;
    margin: 0 0 3px;
  }
}

.next-prev-wrap .next-prev-conent > h4 a {
  color: #1d1d1d;
}

.next-prev-wrap .next-prev-conent > h4 a:hover {
  color: #ff6e21;
}

.next-prev-wrap .next-prev-conent > a {
  color: #989898;
  font-size: 12px;
}

.next-prev-wrap .next-prev-conent > a:hover {
  color: #ff6e21;
}

.next-prev-wrap .next-prev-conent.prev-wrap {
  text-align: right;
}

.blog-comments-area {
  margin: 35px 0 50px;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area {
    margin: 30px 0 30px;
  }
}

.blog-comments-area h4 {
  font-size: 24px;
  color: #2f2f2f;
  margin: 0 0 35px;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area h4 {
    margin: 0 0 20px;
  }
}

.blog-comments-area .blog-comments-wrap {
  border: 1px solid #d1d1d1;
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel {
  padding: 50px 50px 50px;
  border-bottom: 1px solid #d1d1d1;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel {
    padding: 30px 20px 30px;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel:last-child {
  border-bottom: none;
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment {
    flex-wrap: wrap;
  }
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment.ml-80 {
    margin-left: 0;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-img {
  flex: 0 0 94px;
  margin-right: 57px;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-img {
    margin-bottom: 10px;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-img img {
  width: 100%;
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content p {
  font-size: 16px;
  color: #535353;
  font-family: "Playfair Display", serif;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content p {
    margin-bottom: 10px;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply {
    display: flex;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply h5 {
  color: #989898;
  font-size: 14px;
  margin: 0 20px 0 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply h5 {
    line-height: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply h5 {
    line-height: 1;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply a {
  display: inline-block;
  line-height: 1;
  color: #2f2f2f;
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply a:hover {
  color: #ff6e21;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comment-form {
    margin-bottom: 50px;
  }
  .comment-form.mrg-none {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .comment-form {
    margin-bottom: 30px;
  }
  .comment-form.mrg-none {
    margin-bottom: 0;
  }
}

.comment-form > h3 {
  font-size: 24px;
  color: #2f2f2f;
  margin: 0 0 7px;
}

.comment-form p {
  font-size: 13px;
  color: #707070;
  margin: 0 0 45px;
}

@media only screen and (max-width: 767px) {
  .comment-form p {
    margin: 0 0 20px;
  }
}

.comment-form form .leave-form {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .comment-form form .leave-form {
    margin: 0 0 15px;
  }
}

.comment-form form .leave-form input {
  background-color: transparent;
  height: 60px;
  color: #2f2f2f;
  border: 1px solid #d1d1d1;
  padding: 2px 20px;
}

@media only screen and (max-width: 767px) {
  .comment-form form .leave-form input {
    height: 50px;
  }
}

.comment-form form .leave-form input:focus {
  border: 1px solid #262626;
}

.comment-form form .leave-form textarea {
  background-color: transparent;
  height: 150px;
  color: #2f2f2f;
  padding: 20px 20px 2px 20px;
  border: 1px solid #d1d1d1;
}

.comment-form form .leave-form textarea:focus {
  border: 1px solid #262626;
}

.comment-form form .text-submit input {
  height: auto;
  border: none;
  display: inline-block;
  padding: 15px 45px;
  font-weight: bold;
  background-color: #1d1d1d;
  color: #fff;
  width: auto;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
}

.comment-form form .text-submit input:hover {
  background-color: #ff6e21;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area.pt-105 {
    padding-top: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area.pb-80 {
    padding-bottom: 13px;
  }
  .blog-area.pb-60 {
    padding-bottom: 20px;
  }
  .blog-details-wrap.ml-20 {
    margin-left: 0;
  }
}

/*-------- 9. Subscribe style ---------*/
.subscribe-title {
  margin-left: 36px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-title {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-title {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-title {
    margin-left: 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-title {
    margin-left: 0px;
    text-align: center;
  }
}

.subscribe-title h3 {
  color: #fff;
  margin: 0;
  font-size: 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-title h3 {
    font-size: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-title h3 {
    font-size: 30px;
  }
}

.subscribe-form .mc-form {
  position: relative;
}

.subscribe-form .mc-form > input {
  border: none;
  color: #fff;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #858585;
  background-color: transparent;
  height: 42px;
  padding: 2px 100px 2px 0;
}

@media only screen and (max-width: 767px) {
  .subscribe-form .mc-form > input {
    font-size: 14px;
    padding: 2px 80px 2px 0;
  }
}

.subscribe-form .mc-form .mc-news {
  display: none;
}

.subscribe-form .mc-form .clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form .mc-form .clear input {
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 0;
  background-color: transparent;
}

@media only screen and (max-width: 767px) {
  .subscribe-form .mc-form .clear input {
    font-size: 14px;
  }
}

.subscribe-form .mc-form .clear input:hover {
  color: #ccc;
}

.subscribe-form.subscribe-mrg-1 {
  margin: 0 34px 0 66px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form.subscribe-mrg-1 {
    margin: 15px 0px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form.subscribe-mrg-1 {
    margin: 15px 0px 0 0px;
  }
}

.subscribe-pt {
  padding-top: 115px;
}

@media only screen and (max-width: 767px) {
  .subscribe-pt {
    padding-top: 112px;
  }
}

.subscribe-pb {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .subscribe-pb {
    padding-bottom: 50px;
  }
}

.subscribe-title-2 h3 {
  color: #fff;
  font-size: 36px;
  margin: 0 0 24px;
}

@media only screen and (max-width: 767px) {
  .subscribe-title-2 h3 {
    font-size: 23px;
  }
}

.subscribe-form-2 .mc-form-2 {
  position: relative;
}

.subscribe-form-2 .mc-form-2 input {
  height: 90px;
  background-color: #fff;
  color: #393939;
  font-size: 16px;
  border: none;
  padding: 2px 160px 2px 50px;
  border-radius: 50px;
  box-shadow: 0px 0px 12px rgba(199, 199, 199, 0.5);
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2 .mc-form-2 input {
    height: 60px;
    font-size: 14px;
    padding: 2px 125px 2px 14px;
  }
}

.subscribe-form-2 .mc-form-2 .mc-news-2 {
  display: none;
}

.subscribe-form-2 .mc-form-2 .clear-2 {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form-2 .mc-form-2 .clear-2 input {
  width: auto;
  height: auto;
  padding: 5px 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 30px;
  background-color: #ff6e21;
  box-shadow: none;
}

.subscribe-form-2 .mc-form-2 .clear-2 input:hover {
  background-color: #262626;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2 .mc-form-2 .clear-2 input {
    padding: 8px 15px;
    font-size: 14px;
  }
}

.subscribe-form-2 .mc-form-2.mc-form-2-modify input {
  border: 2px solid #d8d8d8;
}

.subscribe-form-2 .mc-form-2.mc-form-2-modify .clear-2 input {
  border: none;
  background-color: #f2c456;
}

.subscribe-form-2 .mc-form-2.mc-form-2-modify .clear-2 input:hover {
  background-color: #262626;
}

.subscribe-form-2 .mc-form-2.mc-form-2-modify .clear-2.organic-clear-2 input {
  background-color: #3cd53f;
}

.subscribe-form-2 .mc-form-2.mc-form-2-modify .clear-2.organic-clear-2 input:hover {
  background-color: #262626;
}

.subscribe-title-3 h3 {
  font-size: 36px;
  margin: 0;
  color: #1b1b1b;
  font-family: "Rufina", serif;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .subscribe-title-3 h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-title-3.mb-30 {
    margin-bottom: 10px;
  }
}

.subscribe-form-3 .mc-form-3 {
  position: relative;
}

.subscribe-form-3 .mc-form-3 input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #1b1b1b;
  font-size: 18px;
  color: #1b1b1b;
  padding: 2px 150px 2px 0;
  height: 60px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-3 .mc-form-3 input {
    font-size: 14px;
    padding: 2px 104px 2px 0;
    height: 50px;
  }
}

.subscribe-form-3 .mc-form-3 .mc-news-3 {
  display: none;
}

.subscribe-form-3 .mc-form-3 .clear-3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.subscribe-form-3 .mc-form-3 .clear-3 input {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  height: auto;
  color: #1b1b1b;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-3 .mc-form-3 .clear-3 input {
    font-size: 14px;
  }
}

.subscribe-form-3 .mc-form-3 .clear-3 input:hover {
  color: #ff6e21;
}

.subscribe-form-4 .mc-form-4 {
  position: relative;
}

.subscribe-form-4 .mc-form-4 input {
  background-color: transparent;
  border: 2px solid #38866f;
  font-size: 18px;
  color: #515151;
  padding: 2px 150px 2px 28px;
  height: 80px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-4 .mc-form-4 input {
    font-size: 14px;
    padding: 2px 100px 2px 15px;
    height: 50px;
  }
}

.subscribe-form-4 .mc-form-4 .mc-news-4 {
  display: none;
}

.subscribe-form-4 .mc-form-4 .clear-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.subscribe-form-4 .mc-form-4 .clear-4 input {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  height: auto;
  color: #515151;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-4 .mc-form-4 .clear-4 input {
    font-size: 14px;
  }
}

.subscribe-form-4 .mc-form-4 .clear-4 input:hover {
  color: #777;
}

.instagram-border-radious {
  border-radius: 220px;
}

.subscribe-pets-ptb {
  padding: 145px 0 150px;
}

@media only screen and (max-width: 767px) {
  .subscribe-pets-ptb {
    padding: 70px 0 70px;
  }
}

.subscribe-form-5 .subscribe-form-style-5 .mc-form-5 {
  position: relative;
}

.subscribe-form-5 .subscribe-form-style-5 .mc-form-5 input {
  background-color: transparent;
  color: #262626;
  height: 70px;
  border: 1px solid #262626;
  padding: 2px 60px 2px 20px;
}

.subscribe-form-5 .subscribe-form-style-5 .mc-form-5 .mc-news-5 {
  display: none;
}

.subscribe-form-5 .subscribe-form-style-5 .mc-form-5 .clear-5 {
  display: inline-block;
  position: absolute;
  right: 2px;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 50px;
  border: none;
  padding: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/mail.png") no-repeat scroll right 18px center;
}

.subscribe-form-5 .subscribe-form-style-5 .mc-form-5 .clear-5 input {
  width: 50px;
  border: none;
  cursor: pointer;
  padding: 2px 5px 2px;
}

@media only screen and (max-width: 767px) {
  .subscribe-area.pt-70 {
    padding-top: 40px;
  }
  .subscribe-area.pb-80 {
    padding-bottom: 50px;
  }
}

/*-------- 10. Footer style ---------*/
.footer-menu nav ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-menu nav ul li {
  display: inline-block;
  margin: 0 25px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-menu nav ul li {
    margin: 0 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-menu nav ul li {
    margin: 0 9px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-menu nav ul li {
    margin: 0 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu nav ul li {
    margin: 15px 12px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu nav ul li {
    margin: 0px 7px 7px;
  }
}

.footer-menu nav ul li a {
  display: inline-block;
  font-size: 11px;
  letter-spacing: 1.83px;
  padding-bottom: 3px;
  font-weight: 600;
}

.footer-menu nav ul li a.active {
  border-bottom: 2px solid #d8d8d8;
}

.footer-menu.color-yellow-2 nav ul li a:hover {
  color: #f2c456;
}

@media only screen and (max-width: 767px) {
  .footer-menu {
    margin: 21px 0 10px;
  }
}

.social-icon-style a {
  font-size: 17px;
  color: #191919;
  display: inline-block;
  margin-left: 30px;
}

.social-icon-style a:first-child {
  margin-left: 0;
}

.social-icon-style.social-icon-right {
  display: flex;
  justify-content: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social-icon-style.social-icon-right {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .social-icon-style.social-icon-right {
    justify-content: center;
  }
}

.social-icon-style.social-icon-right-2 {
  display: flex;
  justify-content: right;
}

@media only screen and (max-width: 767px) {
  .social-icon-style.social-icon-right-2 {
    justify-content: center;
  }
}

.social-icon-style.social-mrg-dec a {
  margin-left: 9px;
}

.social-icon-style.social-mrg-dec a:first-child {
  margin-left: 0;
}

.social-icon-style.social-mrg-dec-2 a {
  margin-left: 13px;
}

.social-icon-style.social-mrg-dec-2 a:first-child {
  margin-left: 0;
}

.social-icon-style.social-font-dec a {
  font-size: 14px;
}

.social-icon-style.social-icon-white a {
  color: #fff;
}

.copyright p {
  font-size: 11px;
  color: #494949;
  letter-spacing: 2.73px;
}

.copyright p a {
  font-size: 11px;
  color: #494949;
  letter-spacing: 2.73px;
}

@media only screen and (max-width: 767px) {
  .copyright p {
    text-align: center;
  }
}

.copyright.copyright-uppercase p {
  text-transform: uppercase;
}

.copyright.copyright-ptb {
  padding: 8px 0 11px;
}

.copyright.copyright-gray p {
  color: #a2a2a2;
}

.copyright.copyright-gray p a {
  color: #a2a2a2;
}

.copyright.copyright-gray-2 p {
  color: #BDBDBD;
}

.copyright.copyright-gray-2 p a {
  color: #BDBDBD;
}

.copyright.copyright-white p {
  color: #fff;
}

.copyright.copyright-white p a {
  color: #fff;
}

.footer-widget h3.footer-title {
  font-size: 14px;
  color: #1b1b1b;
  margin: 0 0 11px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 1.43px;
}

@media only screen and (max-width: 767px) {
  .footer-widget h3.footer-title {
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget h3.footer-title {
    letter-spacing: 1.43px;
  }
}

.footer-widget h3.footer-title-2 {
  font-size: 14px;
  color: #1b1b1b;
  margin: 0 0 19px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 1.43px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget h3.footer-title-2 {
    line-height: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget h3.footer-title-2 {
    line-height: 23px;
  }
}

.footer-widget h3.footer-title-3 {
  font-size: 18px;
  color: #fff;
  margin: 0 0 32px;
  line-height: 1;
  font-weight: bold;
  font-family: "Rufina", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget h3.footer-title-3 {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget h3.footer-title-3 {
    margin: 0 0 15px;
  }
}

.footer-widget h3.footer-title-3.pets-footer-fredoka {
  font-family: "Fredoka One", cursive;
  letter-spacing: 1px;
}

.footer-widget h3.footer-title-4 {
  font-size: 36px;
  color: #1b1b1b;
  margin: 0 0 20px;
  line-height: 1;
  font-family: "SignPainter HouseScript";
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget h3.footer-title-4 {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget h3.footer-title-4 {
    margin: 0 0 15px;
  }
}

.footer-widget h3.footer-title-4.pets-footer-fredoka {
  font-family: "Fredoka One", cursive;
  letter-spacing: 1px;
}

.footer-widget .footer-info-list ul li {
  display: block;
  margin-bottom: 3px;
  font-size: 14px;
  color: #1b1b1b;
}

.footer-widget .footer-info-list ul li:last-child {
  margin-bottom: 0px;
}

.footer-widget .footer-info-list ul li a {
  font-size: 14px;
  color: #1b1b1b;
}

.footer-widget .footer-info-list ul li a:hover {
  text-decoration: underline;
}

.footer-widget .footer-info-list-2 ul li {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  color: #b5b5b5;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-info-list-2 ul li {
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-info-list-2 ul li {
    margin-bottom: 8px;
  }
}

.footer-widget .footer-info-list-2 ul li:last-child {
  margin-bottom: 0px;
}

.footer-widget .footer-info-list-2 ul li a {
  font-size: 14px;
  color: #b5b5b5;
}

.footer-widget .footer-info-list-2 ul li a:hover {
  text-decoration: underline;
}

.footer-widget .footera-about p {
  margin: 0;
  color: #b5b5b5;
  width: 75%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget .footera-about p {
    width: 84%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footera-about p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footera-about p {
    width: 100%;
  }
}

.footer-widget .footera-about-2 p {
  margin: 0;
  color: #1b1b1b;
  width: 83%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget .footera-about-2 p {
    width: 84%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footera-about-2 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footera-about-2 p {
    width: 100%;
  }
}

.footer-widget .footer-contact-wrap .single-footer-contact {
  display: flex;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-contact-wrap .single-footer-contact {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-contact-wrap .single-footer-contact {
    margin-bottom: 12px;
  }
}

.footer-widget .footer-contact-wrap .single-footer-contact:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-contact-wrap .single-footer-contact .footer-contact-icon {
  flex: 0 0 30px;
  margin-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget .footer-contact-wrap .single-footer-contact .footer-contact-icon {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .footer-contact-wrap .single-footer-contact .footer-contact-icon {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-contact-wrap .single-footer-contact .footer-contact-icon {
    margin-right: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-contact-wrap .single-footer-contact .footer-contact-icon {
    margin-right: 12px;
  }
}

.footer-widget .footer-contact-wrap .single-footer-contact .footer-contact-icon i {
  color: #b5b5b5;
  font-size: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-contact-wrap .single-footer-contact .footer-contact-icon i {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-contact-wrap .single-footer-contact .footer-contact-icon i {
    font-size: 20px;
  }
}

.footer-widget .footer-contact-wrap .single-footer-contact .footer-contact-text p {
  color: #b5b5b5;
  margin: 0;
}

.footer-widget .footer-contact-wrap .single-footer-contact.fc-green-3 .footer-contact-icon {
  margin-right: 22px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-contact-wrap .single-footer-contact.fc-green-3 .footer-contact-icon {
    margin-right: 10px;
  }
}

.footer-widget .footer-contact-wrap .single-footer-contact.fc-green-3 .footer-contact-icon:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-contact-wrap .single-footer-contact.fc-green-3 .footer-contact-icon i {
  color: #a4eba5;
}

.footer-widget .footer-contact-wrap .single-footer-contact.fc-green-3 .footer-contact-text p {
  color: #1b1b1b;
}

.footer-widget .app-img {
  margin-bottom: 10px;
}

.footer-widget.footer-mrg-1 {
  margin-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.footer-mrg-1 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.footer-mrg-1 {
    margin-left: 0px;
  }
}

.footer-widget.footer-mrg-2 {
  margin-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.footer-mrg-2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.footer-mrg-2 {
    margin-left: 0px;
  }
}

.footer-widget.footer-mrg-3 {
  margin-left: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget.footer-mrg-3 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.footer-mrg-3 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.footer-mrg-3 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.footer-mrg-3 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.mb-55 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.mb-55 {
    margin-bottom: 30px;
  }
}

.footer-logo-ngtv-mrg {
  margin-top: -52px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-logo-ngtv-mrg {
    margin-top: -60px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-logo-ngtv-mrg {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.footer-payment-right {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .footer-payment-right {
    text-align: center;
  }
}

.footer-bottom-pb {
  padding-bottom: 20px;
}

.footer-bottom-ptb {
  padding: 13px 0;
}

.footer-ml-95 {
  margin-left: 95px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-ml-95 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-ml-95 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-ml-95 {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.footer-ml-30 {
  margin-left: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-ml-30 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-ml-30 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-ml-30 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-ml-30 {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.cake-footer-area {
  position: relative;
}

.cake-footer-area .cake-footer-img1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.cake-footer-area .cake-footer-img1 img {
  width: 322px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cake-footer-area .cake-footer-img1 img {
    width: 222px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-footer-area .cake-footer-img1 img {
    width: 222px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-footer-area .cake-footer-img1 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-footer-area .cake-footer-img1 img {
    width: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cake-footer-area .cake-footer-img1 img {
    width: 100px;
  }
}

.cake-footer-area .cake-footer-img2 {
  position: absolute;
  bottom: 0;
  left: 36%;
  z-index: -1;
}

.cake-footer-area .cake-footer-img2 img {
  width: 267px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cake-footer-area .cake-footer-img2 img {
    width: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-footer-area .cake-footer-img2 img {
    width: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-footer-area .cake-footer-img2 img {
    width: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-footer-area .cake-footer-img2 img {
    width: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cake-footer-area .cake-footer-img2 img {
    width: 60px;
  }
}

.cake-footer-area .cake-footer-img3 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.cake-footer-area .cake-footer-img3 img {
  width: 339px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .cake-footer-area .cake-footer-img3 img {
    width: 239px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cake-footer-area .cake-footer-img3 img {
    width: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-footer-area .cake-footer-img3 img {
    width: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-footer-area .cake-footer-img3 img {
    width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-footer-area .cake-footer-img3 img {
    width: 100px;
  }
}

.footer-area {
  position: relative;
}

.organic-footer-logo {
  margin: 62px 0 0;
}

@media only screen and (max-width: 767px) {
  .organic-footer-logo {
    margin: 62px 0 45px;
  }
}

.footer-organic-img {
  position: absolute;
  right: 0;
  top: -266px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-organic-img {
    top: -100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-organic-img {
    top: -80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-organic-img {
    top: -200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-organic-img {
    top: -250px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-organic-img {
    top: -110px;
  }
}

.footer-organic-img img {
  width: 605px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-organic-img img {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-organic-img img {
    width: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-organic-img img {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-organic-img img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-organic-img img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-logo {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-logo {
    text-align: center;
  }
  .footer-logo.f-logo-small-left {
    text-align: left;
  }
  .footer-top.pt-75 {
    padding-top: 50px;
  }
  .footer-top.pt-100 {
    padding-top: 50px;
  }
  .footer-top.pb-40 {
    padding-bottom: 5px;
  }
  .footer-top.pb-35 {
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-logo.f-logo-small-left {
    text-align: center;
  }
}

/*-------- 11. Instagram style ---------*/
.instagram-feed-thumb {
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-right: -40px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-feed-thumb.instagram-flower {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-feed-thumb.instagram-flower {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-feed-thumb.instagram-flower {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-feed-thumb {
    margin-right: 0px;
    margin-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-feed-thumb {
    margin-right: 0px;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-feed-thumb {
    margin-right: 0px;
    margin-left: 0;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-feed-thumb {
    margin-right: 0px;
    margin-left: 0;
    margin-top: 30px;
  }
}

.instagram-wrap-1 {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 90%;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-1 {
    flex: 0 0 100%;
  }
}

.instagram-wrap-1 .single-instafeed {
  flex: 0 0 33.333%;
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-1 .single-instafeed {
    padding-right: 10px;
  }
}

.instagram-wrap-1 .single-instafeed a {
  display: block;
}

.instagram-wrap-1 .single-instafeed a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.instagram-wrap-1 .single-instafeed a:hover img {
  transform: scale(1.2);
}

.instra-more {
  flex: 0 0 10%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instra-more {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instra-more {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .instra-more {
    flex: 0 0 100%;
    text-align: center;
    margin: 10px 0 0;
  }
}

.instra-more a {
  letter-spacing: 3.57px;
  font-weight: 600;
  font-size: 12px;
}

.instra-more a i {
  font-size: 16px;
  top: 2px;
  position: relative;
}

.instagram-wrap-2 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.instagram-wrap-2 .single-instafeed {
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-2 .single-instafeed {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.instagram-wrap-2 .single-instafeed a {
  display: block;
}

.instagram-wrap-2 .single-instafeed a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.instagram-wrap-2 .single-instafeed a:hover img {
  transform: scale(1.1);
}

.instagram-wrap-3 {
  display: flex;
  flex-wrap: wrap;
}

.instagram-wrap-3 .single-instafeed {
  flex: 0 0 20%;
  max-width: 20%;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-3 .single-instafeed {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.instagram-wrap-3 .single-instafeed a {
  display: block;
}

.instagram-wrap-3 .single-instafeed a img {
  width: 100%;
}

.instagram-wrap-3 .single-instafeed a i {
  color: #191919;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background-color: #f2c456;
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  left: 50%;
  border-radius: 100%;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap-3 .single-instafeed a i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-3 .single-instafeed a i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
  }
}

.instagram-wrap-3 .single-instafeed a i:hover {
  background-color: #fff;
}

.instagram-wrap-3 .single-instafeed a:hover i {
  transform: translateY(-50%) translateX(-50%) scale(1);
}

.instagram-wrap-3.instagram-pink-color .single-instafeed-3 a i {
  color: #fff;
  background-color: #ef8da1;
}

.instagram-wrap-3.instagram-pink-color .single-instafeed-3 a i:hover {
  background-color: #fff;
  color: #191919;
}

.instagram-wrap-4 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.instagram-wrap-4 .single-instafeed {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding-left: 15px;
  padding-right: 15px;
  list-style: none;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-4 .single-instafeed {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap-4 .single-instafeed {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin: 0 0 0px;
  }
}

.instagram-wrap-4 .single-instafeed a {
  display: block;
}

.instagram-wrap-4 .single-instafeed a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.instagram-wrap-4 .single-instafeed a:hover img {
  transform: scale(1.1);
}

.instagram-wrap-5 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.instagram-wrap-5 .single-instafeed {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-5 .single-instafeed {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-wrap-5 .single-instafeed {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin: 0 0 0px;
  }
}

.instagram-wrap-5 .single-instafeed a {
  display: block;
}

.instagram-wrap-5 .single-instafeed a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.instagram-wrap-5 .single-instafeed a:hover img {
  transform: scale(1.1);
}

.instagram-area {
  position: relative;
  overflow: hidden;
}

.kids-monster-1 {
  position: absolute;
  left: 0;
  bottom: 16%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kids-monster-1 {
    bottom: 19%;
  }
}

@media only screen and (max-width: 767px) {
  .kids-monster-1 {
    bottom: 9%;
  }
}

.kids-monster-1 img {
  width: 394px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kids-monster-1 img {
    width: 180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-monster-1 img {
    width: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-monster-1 img {
    width: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-monster-1 img {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-monster-1 img {
    width: 80px;
  }
}

.kids-monster-2 {
  position: absolute;
  right: 0;
  bottom: 18%;
}

@media only screen and (max-width: 767px) {
  .kids-monster-2 {
    bottom: 9%;
  }
}

.kids-monster-2 img {
  width: 440px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kids-monster-2 img {
    width: 190px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-monster-2 img {
    width: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-monster-2 img {
    width: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-monster-2 img {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-monster-2 img {
    width: 80px;
  }
}

.kids-instagram-pb {
  padding-bottom: 190px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-instagram-pb {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-instagram-pb {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-instagram-pb {
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-instagram-pb {
    padding-bottom: 50px;
  }
}

.kids-instagram-pt {
  padding-top: 190px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-instagram-pt {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-instagram-pt {
    padding-top: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-instagram-pt {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-4-area.pt-150 {
    padding-top: 110px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-4-area.pt-150 {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-4-area.pt-150 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-4-area.pt-150 {
    padding-top: 50px;
  }
}

/*-------- 12. Service style ---------*/
.single-service .service-icon i {
  color: #ff6e21;
  font-size: 66px;
  transform: rotateY(180deg);
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service .service-icon i {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service .service-icon i {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .single-service .service-icon i {
    font-size: 50px;
  }
}

.single-service .service-content h4 {
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  margin: 29px 0 6px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service .service-content h4 {
    margin: 20px 0 6px;
  }
}

@media only screen and (max-width: 767px) {
  .single-service .service-content h4 {
    margin: 20px 0 6px;
  }
}

.single-service .service-content p {
  font-size: 12px;
  color: #777777;
  margin: 0 auto;
  width: 82%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service .service-content p {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-service .service-content p {
    width: 68%;
  }
}

.single-service:hover .service-icon i {
  animation: 500ms ease-in-out 0s normal none 1 running zoomIn;
  transform: rotateY(180deg);
}

.service-mrg-left {
  margin-left: -66px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .service-mrg-left {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-mrg-left {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-mrg-left {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-mrg-left {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .service-mrg-left {
    margin-left: 0px;
  }
}

.service-mrg-right {
  margin-right: -66px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .service-mrg-right {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-mrg-right {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-mrg-right {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-mrg-right {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .service-mrg-right {
    margin-right: 0px;
  }
}

.single-service-2 .service-icon-2 i {
  color: #3ad9b1;
  font-size: 54px;
  transform: rotateY(180deg);
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service-2 .service-icon-2 i {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service-2 .service-icon-2 i {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .single-service-2 .service-icon-2 i {
    font-size: 50px;
  }
}

.single-service-2 .service-content-2 h4 {
  font-size: 18px;
  font-weight: bold;
  color: #1b1b1b;
  font-family: "Playfair Display", serif;
  margin: 29px 0 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service-2 .service-content-2 h4 {
    margin: 15px 0 6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service-2 .service-content-2 h4 {
    margin: 15px 0 6px;
  }
}

@media only screen and (max-width: 767px) {
  .single-service-2 .service-content-2 h4 {
    margin: 15px 0 6px;
  }
}

.single-service-2 .service-content-2 p {
  margin: 0 auto;
  width: 81%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-service-2 .service-content-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-service-2 .service-content-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service-2 .service-content-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service-2 .service-content-2 p {
    width: 95%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-service-2 .service-content-2 p {
    width: 68%;
  }
}

.single-service-2:hover .service-icon-2 i {
  animation: 500ms ease-in-out 0s normal none 1 running zoomIn;
  transform: rotateY(180deg);
}

.service-wrap-3 {
  margin: 0 -134px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .service-wrap-3 {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap-3 {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-3 {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-3 {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-3 {
    margin: 0 0px;
  }
}

.single-service-3 .service-icon-3 {
  margin: 0 0 45px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-service-3 .service-icon-3 {
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-service-3 .service-icon-3 {
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service-3 .service-icon-3 {
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service-3 .service-icon-3 {
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .single-service-3 .service-icon-3 {
    margin: 0 0 15px;
  }
}

.single-service-3 .service-icon-3 img {
  width: 234px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-service-3 .service-icon-3 img {
    width: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-service-3 .service-icon-3 img {
    width: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service-3 .service-icon-3 img {
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service-3 .service-icon-3 img {
    width: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .single-service-3 .service-icon-3 img {
    width: 50px;
  }
}

.single-service-3 .service-content-3 h3 {
  color: #1b1b1b;
  font-size: 36px;
  margin: 0 0 12px;
  font-family: "Fredoka One", cursive;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service-3 .service-content-3 h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service-3 .service-content-3 h3 {
    font-size: 25px;
    margin: 0 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .single-service-3 .service-content-3 h3 {
    font-size: 23px;
    margin: 0 0 8px;
  }
}

.single-service-3 .service-content-3 p {
  font-size: 24px;
  line-height: 37px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service-3 .service-content-3 p {
    font-size: 19px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service-3 .service-content-3 p {
    font-size: 18px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-service-3 .service-content-3 p {
    font-size: 16px;
    line-height: 27px;
  }
}

.single-service-3:hover .service-icon-3 img {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-area .pb-85 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .service-area .pb-85 {
    padding-bottom: 20px;
  }
  .service-area.pb-70 {
    padding-bottom: 12px;
  }
}

/*-------- 13. Categories style ---------*/
.single-featured-categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 2px solid #e0e0e0;
  padding: 30px 30px 30px 60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-featured-categories {
    padding: 30px 20px 30px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-featured-categories {
    padding: 30px 15px 30px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-featured-categories {
    padding: 30px 20px 30px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-featured-categories {
    padding: 30px 15px 30px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .single-featured-categories {
    padding: 30px 15px 30px 15px;
  }
}

.single-featured-categories .featured-categories-content {
  flex: 0 0 60%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-featured-categories .featured-categories-content {
    flex: 0 0 62%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-featured-categories .featured-categories-content {
    flex: 0 0 70%;
  }
}

@media only screen and (max-width: 767px) {
  .single-featured-categories .featured-categories-content {
    flex: 0 0 60%;
  }
}

.single-featured-categories .featured-categories-content h3 {
  font-weight: 500;
  font-size: 20px;
  color: #1b1b1b;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-featured-categories .featured-categories-content h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-featured-categories .featured-categories-content h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-featured-categories .featured-categories-content h3 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-featured-categories .featured-categories-content h3 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .single-featured-categories .featured-categories-content h3 {
    font-size: 18px;
  }
}

.single-featured-categories .featured-categories-content h3 a {
  color: #1b1b1b;
}

.single-featured-categories .featured-categories-content h3 a:hover {
  color: #ff6e21;
}

.single-featured-categories .featured-categories-content p {
  color: #ff6e21;
  font-weight: 500;
  margin: 8px 0 24px;
}

.single-featured-categories .featured-categories-img {
  flex: 0 0 40%;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-featured-categories .featured-categories-img {
    flex: 0 0 38%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-featured-categories .featured-categories-img {
    flex: 0 0 30%;
  }
}

@media only screen and (max-width: 767px) {
  .single-featured-categories .featured-categories-img {
    flex: 0 0 40%;
  }
}

.single-featured-categories .featured-categories-img a img {
  width: 100%;
  transition: all .5s ease 0s;
  transform: scale(1);
}

.single-featured-categories:hover .featured-categories-img a img {
  transform: scale(1.1);
}

.slick-dot-style-1 .slick-dots {
  text-align: center;
}

.slick-dot-style-1 .slick-dots li {
  display: inline-block;
}

.slick-dot-style-1 .slick-dots li button {
  font-size: 0;
  border: none;
  padding: 0;
  background-color: #d8d8d8;
  width: 19px;
  height: 19px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 5px;
  transition: all .3s ease 0s;
}

.slick-dot-style-1 .slick-dots li button:hover {
  background-color: #ff6e21;
}

.slick-dot-style-1 .slick-dots li.slick-active button {
  background-color: #ff6e21;
}

.featured-categories-active .slick-list {
  margin-right: -12.5px;
  margin-left: -12.5px;
}

.featured-categories-active .slick-list .featured-categories-wrap {
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.organic-categories-area {
  padding: 25px 0 120px;
}

@media only screen and (max-width: 767px) {
  .organic-categories-area {
    padding: 25px 0 50px;
  }
}

.organic-categories-active.owl-carousel .owl-item .organic-categories-wrap {
  overflow: hidden;
}

.organic-categories-active.owl-carousel .owl-item .organic-categories-wrap img {
  display: inline-block;
  width: auto;
  max-width: 100%;
  transition: all .5s ease 0s;
}

.organic-categories-active.owl-carousel .owl-item .organic-categories-wrap h3 {
  font-size: 36px;
  margin: 50px 0 0;
  color: #1b1b1b;
  font-family: "SignPainter HouseScript";
}

.organic-categories-active.owl-carousel .owl-item .organic-categories-wrap h3 a {
  color: #1b1b1b;
}

.organic-categories-active.owl-carousel .owl-item .organic-categories-wrap h3 a:hover {
  color: #3cd53f;
}

@media only screen and (max-width: 767px) {
  .organic-categories-active.owl-carousel .owl-item .organic-categories-wrap h3 {
    margin: 25px 0 0;
  }
}

.organic-categories-active.owl-carousel .owl-item .organic-categories-wrap:hover img {
  animation: 900ms ease-in-out 0s normal none 1 running jello;
}

/*-------- 14. Brand logo style ---------*/
.brand-logo-active .single-brand-logo, .brand-logo-active-2 .single-brand-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo-active .single-brand-logo.bl-grayscale img, .brand-logo-active-2 .single-brand-logo.bl-grayscale img {
  filter: grayscale(100%);
}

.brand-logo-active .single-brand-logo.bl-grayscale img:hover, .brand-logo-active-2 .single-brand-logo.bl-grayscale img:hover {
  filter: grayscale(0%);
}

.brand-logo-active.owl-carousel .owl-item img, .brand-logo-active-2.owl-carousel .owl-item img, .testimonial-active.owl-carousel .owl-item img, .testimonial-active-2.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.brand-logo-active.owl-carousel .owl-item img, .brand-logo-active-2.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
  transform: scale(1);
  transition: all .3s ease 0s;
}

.brand-logo-active.owl-carousel .owl-item img:hover, .brand-logo-active-2.owl-carousel .owl-item img:hover {
  transform: scale(1.1);
}

.brand-logo-ptb {
  padding: 80px 0 60px;
}

@media only screen and (max-width: 767px) {
  .brand-logo-ptb {
    padding: 40px 0 30px;
  }
}

.brand-logo-ptb-about {
  padding: 60px 0 60px;
}

@media only screen and (max-width: 767px) {
  .brand-logo-ptb-about {
    padding: 40px 0 40px;
  }
}

.kids-brand-logo-ptb {
  padding: 108px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-brand-logo-ptb {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .kids-brand-logo-ptb {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-area.pt-70 {
    padding-top: 50px;
  }
  .brand-logo-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 15. Testimonial style ---------*/
.single-testimonial .testimonial-quote i {
  color: #ff6e21;
  background-color: #fff;
  display: inline-block;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
}

.single-testimonial p {
  font-size: 22px;
  line-height: 41px;
  font-weight: 600;
  margin: 12px auto 38px;
  color: #fff;
  width: 67%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial p {
    width: 85%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial p {
    width: 90%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial p {
    width: 90%;
    font-size: 16px;
    line-height: 32px;
    margin: 12px auto 25px;
  }
}

.client-info span {
  color: #fff;
  font-size: 18px;
  display: block;
  margin: 6px 0 0;
}

.single-testimonial-2 {
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 12px;
}

.single-testimonial-2 .testimonial-2-inner {
  position: relative;
  float: left;
  width: 100%;
}

.single-testimonial-2 .testimonial-2-inner:before {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  content: "";
  width: 2px;
  height: 100%;
}

.single-testimonial-2 .testimonial-2-inner:after {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  content: "";
  width: 2px;
  height: 100%;
}

.single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 {
  position: relative;
  margin-top: -40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 {
    margin-top: -24px;
  }
}

.single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 .quote-2-icon {
  position: relative;
}

.single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 .quote-2-icon:before {
  position: absolute;
  left: auto;
  right: calc(100% + 100px);
  top: 50%;
  background-color: #fff;
  content: "";
  width: 700%;
  height: 2px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 .quote-2-icon:before {
    right: calc(100% + 50px);
  }
}

.single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 .quote-2-icon:after {
  position: absolute;
  right: auto;
  left: calc(100% + 100px);
  top: 50%;
  background-color: #fff;
  content: "";
  width: 700%;
  height: 2px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 .quote-2-icon:after {
    left: calc(100% + 50px);
  }
}

.single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 .quote-2-icon i {
  color: #fff;
  font-size: 82px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 .quote-2-icon i {
    font-size: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .testimonial-2-inner .testimonial-quote-2 .quote-2-icon i {
    font-size: 50px;
  }
}

.single-testimonial-2 .testimonial-2-inner p {
  color: #fff;
  font-size: 66px;
  font-weight: bold;
  font-family: "Rufina", serif;
  margin: 0 0 63px;
  line-height: 107px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-2 .testimonial-2-inner p {
    font-size: 50px;
    margin: 0 0 63px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-2 .testimonial-2-inner p {
    font-size: 28px;
    margin: 28px 0 40px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .testimonial-2-inner p {
    font-size: 22px;
    line-height: 37px;
    margin: 15px 0 25px;
    padding: 0 20px;
  }
}

.single-testimonial-2 .testimonial-2-inner .client-info-2 {
  margin-bottom: -12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.single-testimonial-2 .testimonial-2-inner .client-info-2 span {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  font-family: "Rufina", serif;
  display: block;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .testimonial-2-inner .client-info-2 span {
    font-size: 16px;
  }
}

.single-testimonial-2 .testimonial-2-inner .client-info-2 span:before {
  position: absolute;
  left: auto;
  right: calc(100% + 100px);
  top: 50%;
  background-color: #fff;
  content: "";
  width: 500%;
  height: 2px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .testimonial-2-inner .client-info-2 span:before {
    right: calc(100% + 50px);
  }
}

.single-testimonial-2 .testimonial-2-inner .client-info-2 span:after {
  position: absolute;
  right: auto;
  left: calc(100% + 100px);
  top: 50%;
  background-color: #fff;
  content: "";
  width: 500%;
  height: 2px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .testimonial-2-inner .client-info-2 span:after {
    left: calc(100% + 50px);
  }
}

.single-testimonial-3 p {
  font-size: 28px;
  line-height: 39px;
  margin: 15px auto 45px;
  width: 63%;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-testimonial-3 p {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-testimonial-3 p {
    font-size: 23px;
    width: 83%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-3 p {
    font-size: 20px;
    width: 85%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-3 p {
    font-size: 20px;
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 p {
    font-size: 16px;
    line-height: 30px;
    margin: 15px auto 25px;
    width: 100%;
  }
}

.testimonial3-negative-mt {
  margin-top: -240px;
}

@media only screen and (max-width: 767px) {
  .testimonial3-negative-mt {
    margin-top: -80px;
  }
}

.testimonial3-pt {
  padding-top: 350px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial3-pt {
    padding-top: 305px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial3-pt {
    padding-top: 125px;
  }
}

.testimonial-area {
  position: relative;
  overflow: hidden;
}

.handmade-leaf-1 {
  position: absolute;
  right: 0;
  bottom: 0;
  animation-duration: 2.5s;
  animation-name: bounce-img-2;
  animation-iteration-count: infinite;
}

.handmade-leaf-1 img {
  width: 597px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .handmade-leaf-1 img {
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .handmade-leaf-1 img {
    width: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .handmade-leaf-1 img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .handmade-leaf-1 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .handmade-leaf-1 img {
    width: 100px;
  }
}

.handmade-leaf-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  animation-duration: 2.5s;
  animation-name: bounce-img-2;
  animation-iteration-count: infinite;
}

.handmade-leaf-2 img {
  width: 486px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .handmade-leaf-2 img {
    width: 380px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .handmade-leaf-2 img {
    width: 280px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .handmade-leaf-2 img {
    width: 230px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .handmade-leaf-2 img {
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .handmade-leaf-2 img {
    width: 100px;
  }
}

.testimonial-border {
  border: 1px solid #979797;
  padding: 0 0 55px;
}

.single-testimonial-4 p {
  font-size: 18px;
  line-height: 36px;
  margin: 9px auto 19px;
  width: 64%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-4 p {
    width: 78%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-4 p {
    width: 73%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-4 p {
    font-size: 14px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-testimonial-4 p {
    font-size: 16px;
    line-height: 34px;
  }
}

.nav-style-4 .owl-nav div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  font-size: 30px;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .nav-style-4 .owl-nav div {
    left: 15px;
    font-size: 20px;
  }
}

.nav-style-4 .owl-nav div:hover {
  color: #f2c456;
}

.nav-style-4 .owl-nav div.owl-next {
  left: auto;
  right: 40px;
}

@media only screen and (max-width: 767px) {
  .nav-style-4 .owl-nav div.owl-next {
    right: 15px;
  }
}

.single-testimonial-5 p {
  font-size: 36px;
  font-family: "Fredoka One", cursive;
  line-height: 49px;
  margin: 27px auto 36px;
  width: 68%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-testimonial-5 p {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-5 p {
    font-size: 30px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-5 p {
    font-size: 25px;
    line-height: 40px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-5 p {
    font-size: 20px;
    line-height: 36px;
    margin: 16px auto 26px;
    width: 100%;
  }
}

.testimonial-5-ptb {
  padding: 148px 0 142px;
}

@media only screen and (max-width: 767px) {
  .testimonial-5-ptb {
    padding: 70px 0 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-area.pt-150 {
    padding-top: 100px;
  }
  .testimonial-area.pb-150 {
    padding-bottom: 100px;
  }
  .testimonial-area-2.pt-200 {
    padding-top: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-area.pt-150 {
    padding-top: 70px;
  }
  .testimonial-area.pb-150 {
    padding-bottom: 70px;
  }
  .testimonial-area-2.pt-200 {
    padding-top: 115px;
  }
}

/*-------- 16. Bookstore style ---------*/
.bookstore-active {
  border-radius: 120px;
  padding: 70px 170px 75px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bookstore-active {
    padding: 70px 50px 75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bookstore-active {
    padding: 70px 50px 75px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bookstore-active {
    padding: 70px 50px 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bookstore-active {
    padding: 70px 50px 75px;
  }
}

@media only screen and (max-width: 767px) {
  .bookstore-active {
    padding: 50px 10px 55px;
  }
}

.single-bookstore i {
  font-size: 57px;
  color: #191919;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .single-bookstore i {
    font-size: 45px;
  }
}

.single-bookstore h3 {
  color: #1b1b1b;
  font-size: 24px;
  font-weight: bold;
  font-family: "Rufina", serif;
  margin: 19px 0 0;
}

@media only screen and (max-width: 767px) {
  .single-bookstore h3 {
    font-size: 20px;
    margin: 12px 0 0;
  }
}

.single-bookstore h3 a {
  color: #1b1b1b;
}

.single-bookstore:hover i {
  color: #ff6e21;
}

.nav-style-3 .owl-nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -34px;
  background-color: #fffdf9;
  padding: 9px 25px;
  border-radius: 50px;
}

.nav-style-3 .owl-nav div {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #9f9f9f;
  font-size: 15px;
  text-align: center;
  border-radius: 100%;
  margin: 0 15px;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .nav-style-3 .owl-nav div {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 5px;
    bottom: -20px;
  }
}

.nav-style-3 .owl-nav div:hover {
  color: #ff6e21;
  border: 1px solid #ff6e21;
}

.author-book-img {
  margin-right: 35px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .author-book-img {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .author-book-img {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .author-book-img {
    margin-right: 0px;
  }
}

.author-book-img img {
  width: 100%;
}

.author-book-text {
  margin-left: 70px;
  position: relative;
  z-index: 99;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .author-book-text {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .author-book-text {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .author-book-text {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .author-book-text {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.author-book-text span {
  color: #ff6e21;
  font-size: 16px;
  letter-spacing: 1.25px;
  display: block;
}

.author-book-text h2 {
  font-size: 36px;
  font-weight: bold;
  color: #1b1b1b;
  font-family: "Rufina", serif;
  position: relative;
  margin: 8px 0 20px;
  padding-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .author-book-text h2 {
    font-size: 27px;
    padding-bottom: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .author-book-text h2 {
    font-size: 24px;
    margin: 8px 0 5px;
    padding-bottom: 15px;
  }
}

.author-book-text h2::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 120px;
  background-color: #ff6e21;
}

.author-book-text p {
  font-size: 18px;
  font-family: "Rufina", serif;
  line-height: 36px;
  color: #333333;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .author-book-text p {
    font-size: 16px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .author-book-text p {
    font-size: 16px;
    line-height: 30px;
  }
}

.author-book-text .yearly-best-seller {
  display: flex;
  align-items: center;
  margin: 34px 0 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .author-book-text .yearly-best-seller {
    margin: 15px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .author-book-text .yearly-best-seller {
    margin: 20px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .author-book-text .yearly-best-seller {
    margin: 15px 0 25px;
  }
}

.author-book-text .yearly-best-seller .yearly-best-seller-img {
  flex: 0 0 107px;
  margin-right: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .author-book-text .yearly-best-seller .yearly-best-seller-img {
    flex: 0 0 90px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .author-book-text .yearly-best-seller .yearly-best-seller-img {
    flex: 0 0 70px;
    margin-right: 10px;
  }
}

.author-book-text .yearly-best-seller .yearly-best-seller-img img {
  width: 100%;
}

.author-book-text .yearly-best-seller .yearly-best-seller-content h4 {
  color: #1b1b1b;
  font-weight: bold;
  font-size: 22px;
  font-family: "Rufina", serif;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .author-book-text .yearly-best-seller .yearly-best-seller-content h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .author-book-text .yearly-best-seller .yearly-best-seller-content h4 {
    font-size: 18px;
  }
}

.author-book-text .yearly-best-seller .yearly-best-seller-content h4 a {
  color: #1b1b1b;
}

.author-book-area {
  position: relative;
  overflow: hidden;
}

.book-leaf-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  animation-iteration-count: infinite;
  animation-name: bounce-img-3;
  animation-duration: 2.5s;
}

.book-leaf-1 img {
  width: 461px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .book-leaf-1 img {
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .book-leaf-1 img {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .book-leaf-1 img {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .book-leaf-1 img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .book-leaf-1 img {
    width: 150px;
  }
}

.book-leaf-2 {
  position: absolute;
  right: 0;
  top: 0;
  animation-iteration-count: infinite;
  animation-name: bounce-img-3;
  animation-duration: 2.5s;
}

.book-leaf-2 img {
  width: 383px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .book-leaf-2 img {
    width: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .book-leaf-2 img {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .book-leaf-2 img {
    width: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .book-leaf-2 img {
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .book-leaf-2 img {
    width: 120px;
  }
}

.book-leaf-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
}

.book-leaf-3 img {
  width: 305px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .book-leaf-3 img {
    width: 205px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .book-leaf-3 img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .book-leaf-3 img {
    width: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .book-leaf-3 img {
    width: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .book-leaf-3 img {
    width: 80px;
  }
}

.book-leaf-4 {
  position: absolute;
  right: 15%;
  bottom: 0;
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
}

.book-leaf-4 img {
  width: 283px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .book-leaf-4 img {
    width: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .book-leaf-4 img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .book-leaf-4 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .book-leaf-4 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .book-leaf-4 img {
    width: 100px;
  }
}

.book-leaf-5 {
  position: absolute;
  left: 32%;
  bottom: 0;
  z-index: 99;
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .book-leaf-5 {
    left: 30%;
  }
}

.book-leaf-5 img {
  width: 138px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .book-leaf-5 img {
    width: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .book-leaf-5 img {
    width: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .book-leaf-5 img {
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .book-leaf-5 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .book-leaf-5 img {
    width: 50px;
  }
}

.book-leaf-6 {
  position: absolute;
  left: 13%;
  bottom: 0;
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
}

.book-leaf-6 img {
  width: 174px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .book-leaf-6 img {
    width: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .book-leaf-6 img {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .book-leaf-6 img {
    width: 60px;
  }
}

.book-leaf-7 {
  position: absolute;
  left: 0;
  bottom: 25px;
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
}

.book-leaf-7 img {
  width: 327px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .book-leaf-7 img {
    width: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .book-leaf-7 img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .book-leaf-7 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .book-leaf-7 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .book-leaf-7 img {
    width: 80px;
  }
}

.book-leaf-8 {
  position: absolute;
  left: 49%;
  bottom: 3%;
  z-index: 99;
  transform: rotate(10deg);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .book-leaf-8 {
    left: 45%;
    bottom: 0%;
    transform: rotate(19deg);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .book-leaf-8 {
    left: 42%;
    bottom: -1%;
    transform: rotate(25deg);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .book-leaf-8 {
    left: 35%;
    bottom: -3%;
    transform: rotate(32deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .book-leaf-8 {
    left: 33%;
    bottom: -3%;
    transform: rotate(33deg);
  }
}

@media only screen and (max-width: 767px) {
  .book-leaf-8 {
    left: 32%;
    bottom: -2%;
    transform: rotate(25deg);
  }
}

.book-leaf-8 img {
  width: 214px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .book-leaf-8 img {
    width: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .book-leaf-8 img {
    width: 160px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bookstore-area.pt-195 {
    padding-top: 90px;
  }
  .bookstore-area.pb-205 {
    padding-bottom: 135px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bookstore-area.pt-195 {
    padding-top: 90px;
  }
  .bookstore-area.pb-205 {
    padding-bottom: 135px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bookstore-area.pt-195 {
    padding-top: 90px;
  }
  .bookstore-area.pb-205 {
    padding-bottom: 135px;
  }
}

@media only screen and (max-width: 767px) {
  .bookstore-area.pt-195 {
    padding-top: 50px;
  }
  .bookstore-area.pb-205 {
    padding-bottom: 95px;
  }
  .author-book-area.pt-150 {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .author-book-area.pt-150 {
    padding-top: 50px;
  }
}

/*-------- 17. Others style ---------*/
.main-sidebar-right {
  margin-left: 375px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-sidebar-right {
    margin-left: 275px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-sidebar-right {
    margin-left: 270px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-sidebar-right {
    margin-left: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-sidebar-right {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-sidebar-right {
    margin-left: 0px;
  }
}

.pets-choose-ptb {
  padding: 282px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pets-choose-ptb {
    padding: 150px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pets-choose-ptb {
    padding: 150px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pets-choose-ptb {
    padding: 150px 0;
  }
}

@media only screen and (max-width: 767px) {
  .pets-choose-ptb {
    padding: 120px 0;
  }
}

.pets-choose-content {
  margin-top: 10px;
}

.pets-choose-content h2 {
  font-size: 66px;
  color: #1b1b1b;
  font-family: "Fredoka One", cursive;
  margin: 0 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pets-choose-content h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pets-choose-content h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .pets-choose-content h2 {
    font-size: 30px;
    margin: 0 0 15px;
  }
}

.pets-choose-content p {
  font-size: 24px;
  color: #1b1b1b;
  line-height: 50px;
  margin: 65px 0 70px;
  width: 66%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pets-choose-content p {
    margin: 25px 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pets-choose-content p {
    margin: 25px 0 30px;
    font-size: 20px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pets-choose-content p {
    font-size: 20px;
    line-height: 40px;
    margin: 15px 0 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .pets-choose-content p {
    font-size: 16px;
    line-height: 32px;
    width: 100%;
    margin: 10px 0 15px;
  }
}

.empty-cart-content img {
  max-width: 100%;
}

.empty-cart-content h3 {
  font-size: 24px;
  text-transform: uppercase;
  margin: 50px 0 41px;
  color: #262626;
}

@media only screen and (max-width: 767px) {
  .empty-cart-content h3 {
    font-size: 17px;
    margin: 30px 0 31px;
  }
}

.empty-cart-content .empty-cart-btn a {
  display: inline-block;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #262626;
  padding: 18px 32px;
}

.empty-cart-content .empty-cart-btn a:hover {
  background-color: #ff6e21;
}

.error-content {
  margin-top: -120px;
}

.error-content h1 {
  font-size: 250px;
  color: #fff;
  margin: 0;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .error-content h1 {
    font-size: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-content h1 {
    font-size: 220px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h1 {
    font-size: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content h1 {
    font-size: 150px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error-content h1 {
    font-size: 200px;
  }
}

.error-content h3 {
  font-size: 64px;
  color: #fff;
  margin: 0px 0 70px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .error-content h3 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-content h3 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h3 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content h3 {
    font-size: 25px;
    margin: 10px 0 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error-content h3 {
    font-size: 30px;
    margin: 10px 0 30px;
  }
}

.single-store h3 {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 28px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .single-store h3 {
    font-size: 20px;
    margin-bottom: 12px;
  }
}

.single-store ul li {
  margin-bottom: 5px;
}

.single-store ul li:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-store.mb-50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-store.mb-50 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .store-area.pb-70 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .store-area.pb-70 {
    padding-bottom: 20px;
  }
}

/*-------- 18. About style ---------*/
.cake-about-ptb {
  padding: 463px 0 463px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cake-about-ptb {
    padding: 300px 0 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-about-ptb {
    padding: 220px 0 220px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-about-ptb {
    padding: 180px 0 180px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-about-ptb {
    padding: 100px 0 100px;
    background-position: 24%;
  }
}

.cake-about-content {
  margin-right: -60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .cake-about-content {
    margin-right: -30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cake-about-content {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-about-content {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-about-content {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-about-content {
    margin-right: 0px;
  }
}

.cake-about-content h2 {
  color: #fff;
  font-size: 66px;
  margin: 0;
  font-family: "SignPainter HouseScript";
}

@media only screen and (max-width: 767px) {
  .cake-about-content h2 {
    font-size: 36px;
  }
}

.cake-about-content h3 {
  color: #ef8da1;
  font-size: 36px;
  font-family: "SignPainter HouseScript";
  margin: 10px 0 20px;
}

@media only screen and (max-width: 767px) {
  .cake-about-content h3 {
    margin: 10px 0 7px;
  }
}

.cake-about-content p {
  color: #8b8b8b;
  font-size: 18px;
  line-height: 36px;
  margin: 0 auto;
  width: 61%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-about-content p {
    width: 76%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-about-content p {
    width: 76%;
  }
}

@media only screen and (max-width: 767px) {
  .cake-about-content p {
    font-size: 15px;
    line-height: 27px;
    width: 100%;
  }
}

.organic-about-img {
  overflow: hidden;
}

.organic-about-img img {
  width: 100%;
  transition: all .5s ease 0s;
}

.organic-about-content {
  margin: 0px 0 0 54px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-about-content {
    margin: 0px 0 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-about-content {
    margin: 0px 0 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-about-content {
    margin: 0px 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-about-content {
    margin: 30px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-about-content {
    margin: 30px 0 0 0px;
  }
}

.organic-about-content h2 {
  color: #1b1b1b;
  font-size: 66px;
  margin: 0 0 10px;
  font-family: "SignPainter HouseScript";
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-about-content h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-about-content h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-about-content h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-about-content h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-about-content h2 {
    font-size: 32px;
  }
}

.organic-about-content p {
  font-size: 18px;
  color: #8b8b8b;
  line-height: 36px;
  margin: 12px 0 41px;
  width: 70%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-about-content p {
    width: 90%;
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-about-content p {
    width: 90%;
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-about-content p {
    width: 100%;
    line-height: 34px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-about-content p {
    width: 100%;
    line-height: 34px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-about-content p {
    width: 100%;
    line-height: 28px;
    font-size: 14px;
    margin: 12px 0 20px;
  }
}

.video-btn-3 a {
  display: inline-block;
  width: 105px;
  height: 105px;
  text-align: center;
  line-height: 105px;
  background-color: #3cd53f;
  color: #fff;
  border-radius: 100%;
  font-size: 20px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-btn-3 a {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .video-btn-3 a {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

.video-btn-3 a i {
  line-height: 105px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-btn-3 a i {
    line-height: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .video-btn-3 a i {
    line-height: 60px;
  }
}

.video-btn-3 a::before {
  animation: 1500ms ease-out 0s normal none infinite running border-jump;
  background: #3cd53f none repeat scroll 0 0;
  border-radius: 50%;
  content: "";
  display: block;
  height: 90px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90px;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-btn-3 a::before {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .video-btn-3 a::before {
    width: 60px;
    height: 60px;
  }
}

.video-btn-3 a:hover {
  background-color: #191919;
  color: #fff;
}

.video-btn-3.video-btn-3-yellow a {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .video-btn-3.video-btn-3-yellow a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
  }
}

.video-btn-3.video-btn-3-yellow a i {
  line-height: 80px;
}

@media only screen and (max-width: 767px) {
  .video-btn-3.video-btn-3-yellow a i {
    line-height: 50px;
  }
}

.video-btn-3.video-btn-3-yellow a::before {
  background: #ff6e21 none repeat scroll 0 0;
  height: 70px;
  width: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-btn-3.video-btn-3-yellow a::before {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .video-btn-3.video-btn-3-yellow a::before {
    width: 50px;
    height: 50px;
  }
}

.video-btn-3.video-btn-3-yellow a:hover {
  background-color: #191919;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-area.pt-155 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .about-area.pt-155 {
    padding-top: 20px;
  }
}

.about-us-img img {
  width: 100%;
}

.about-us-content {
  padding-left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content {
    padding-left: 0px;
    margin-top: 30px;
  }
}

.about-us-content h2 {
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 20px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content h2 {
    font-size: 30px;
    margin: 0 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content h2 {
    font-size: 30px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content h2 {
    font-size: 22px;
    margin: 0 0 10px;
    letter-spacing: 1px;
  }
}

.about-us-content p {
  line-height: 28px;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .about-us-content p {
    margin: 0 0 20px;
  }
}

.skill-content h2 {
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 30px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-content h2 {
    font-size: 30px;
    margin: 0 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-content h2 {
    font-size: 30px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-content h2 {
    font-size: 22px;
    margin: 0 0 10px;
    letter-spacing: 1px;
  }
}

.skill-bar {
  overflow: hidden;
  padding-top: 11px;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .skill-bar {
    margin-top: 10px;
  }
}

.skill-bar .skill-bar-item {
  padding-bottom: 30px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-bar .skill-bar-item {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-bar .skill-bar-item {
    padding-bottom: 20px;
  }
}

.skill-bar .skill-bar-item > span {
  font-size: 15px;
  text-align: center;
  text-transform: capitalize;
  z-index: 2;
}

.skill-bar .skill-bar-item .progress {
  background-color: #EFEFEF;
  border-radius: 0;
  box-shadow: none;
  height: 5px;
  margin-top: 9px;
  overflow: visible;
}

.skill-bar .skill-bar-item .progress .progress-bar {
  background-color: #ff6e21;
  box-shadow: none;
  position: relative;
  overflow: visible;
}

.skill-bar .skill-bar-item .progress .progress-bar span.text-top {
  color: #262626;
  font-size: 15px;
  text-transform: uppercase;
  position: absolute;
  right: 0;
  top: -32px;
}

.skill-bar .skill-bar-item:nth-child(1) .progress-bar {
  width: 95%;
}

.skill-bar .skill-bar-item:nth-child(2) .progress-bar {
  width: 85%;
}

.skill-bar .skill-bar-item:nth-child(3) .progress-bar {
  width: 80%;
}

.skill-bar .skill-bar-item:nth-child(4) .progress-bar {
  width: 99%;
}

.skill-img {
  margin-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-img {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-img {
    margin-left: 0px;
  }
}

.skill-img img {
  width: 100%;
}

.team-wrapper .team-img {
  overflow: hidden;
  position: relative;
}

.team-wrapper .team-img a img {
  width: 100%;
}

.team-wrapper .team-img .team-action {
  background: #fff none repeat scroll 0 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 70%;
  transform: translateY(-50%);
  transition: all 0.6s ease 0s;
  width: 100%;
  z-index: 5;
}

.team-wrapper .team-img .team-action a {
  color: #fff;
  font-size: 15px;
  transition: all 0.3s ease 0s;
  margin: 0 7px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
}

.team-wrapper .team-img .team-action a.facebook {
  background-color: #3b5998;
  border: 1px solid #3b5998;
}

.team-wrapper .team-img .team-action a.facebook:hover {
  background-color: transparent;
  color: #3b5998;
}

.team-wrapper .team-img .team-action a.twitter {
  background-color: #55acee;
  border: 1px solid #55acee;
}

.team-wrapper .team-img .team-action a.twitter:hover {
  background-color: transparent;
  color: #55acee;
}

.team-wrapper .team-img .team-action a.instagram {
  background-color: #c32aa3;
  border: 1px solid #c32aa3;
}

.team-wrapper .team-img .team-action a.instagram:hover {
  background-color: transparent;
  color: #c32aa3;
}

.team-wrapper .team-content {
  padding: 20px 10px 21px;
  box-shadow: 0 3px 5px rgba(85, 85, 85, 0.2);
}

.team-wrapper .team-content h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 3px;
  text-transform: capitalize;
  color: #333;
}

.team-wrapper .team-content span {
  font-size: 15px;
  font-style: italic;
  color: #555;
}

.team-wrapper:hover .team-action {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

/*-------- 19. Product details style ---------*/
.modal-backdrop.show {
  opacity: .7;
}

.modal-dialog {
  margin: 6% auto 8%;
  max-width: 1250px;
  width: 1250px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .modal-dialog {
    width: 1150px;
    max-width: 1150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog {
    width: 960px;
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 720px;
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

.modal-dialog .modal-content {
  border-radius: 0;
}

.modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  right: -13px;
  top: -54px;
  position: absolute;
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header {
    right: 0px;
    top: -34px;
  }
}

.modal-dialog .modal-content .modal-header .close {
  color: #fff;
  cursor: pointer;
  opacity: 1;
  padding: 0;
  margin: 0;
  width: 54px;
  height: 54px;
  line-height: 57px;
  background-color: #292929;
  border-radius: 100%;
  font-size: 18px;
  text-shadow: none;
  font-weight: 300;
  border: none;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header .close {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 15px;
  }
}

.modal-dialog .modal-content .modal-header .close:hover {
  color: #ff6e21;
  transform: rotate(90deg);
}

.modal-dialog .modal-content .modal-body {
  padding: 0px;
}

.quickview-content-padding {
  padding: 50px 50px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content-padding {
    padding: 15px 35px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content-padding {
    padding: 20px 35px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content-padding {
    padding: 15px 15px 15px;
  }
}

.product-details-content .pro-dec-next-prev {
  text-align: right;
}

.product-details-content .pro-dec-next-prev a {
  font-size: 20px;
  margin-left: 12px;
}

.product-details-content .pro-dec-next-prev a:first-child {
  margin-left: 0;
}

.product-details-content > span {
  color: #C3832D;
  margin: 0 0 7px;
  text-transform: uppercase;
}

.product-details-content h2 {
  font-size: 24px;
  margin: 0 0 20px;
  color: #262626;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 20px;
  }
}

.product-details-content .pro-dec-info-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 32px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-content .pro-dec-info-wrap {
    display: block;
  }
  .product-details-content .pro-dec-info-wrap.pro-dec-info-wrap2 {
    display: flex;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content .pro-dec-info-wrap {
    display: block;
  }
  .product-details-content .pro-dec-info-wrap.pro-dec-info-wrap2 {
    display: flex;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-dec-info-wrap.pro-dec-info-wrap2 {
    display: block;
  }
  .product-details-content .pro-dec-info-wrap.pro-dec-info-wrap3 {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-dec-info-wrap {
    display: block;
  }
  .product-details-content .pro-dec-info-wrap.pro-dec-info-wrap2 {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-dec-info-wrap {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-dec-info-wrap {
    display: flex;
  }
}

.product-details-content .pro-dec-info-wrap .pro-dec-ratting-wrap {
  display: flex;
}

.product-details-content .pro-dec-info-wrap .pro-dec-ratting-wrap .pro-dec-ratting i {
  color: #d5d6d8;
  font-size: 14px;
  margin-left: 2px;
}

.product-details-content .pro-dec-info-wrap .pro-dec-ratting-wrap .pro-dec-ratting i.yellow {
  color: #f5b223;
}

.product-details-content .pro-dec-info-wrap .pro-dec-ratting-wrap a {
  color: #1D1D1D;
  font-size: 12px;
  margin-left: 10px;
}

.product-details-content .pro-dec-info-wrap .pro-dec-stock ul {
  display: flex;
  flex-wrap: wrap;
}

.product-details-content .pro-dec-info-wrap .pro-dec-stock ul li {
  margin-left: 10px;
  color: #1D1D1D;
  font-size: 12px;
}

.product-details-content .pro-dec-info-wrap .pro-dec-stock ul li.green {
  color: #86BA45;
  font-weight: 600;
}

.product-details-content .pro-dec-info-wrap .pro-dec-stock ul li span {
  font-weight: 600;
}

.product-details-content .pro-dec-info-wrap .pro-dec-stock ul li i {
  margin-right: 3px;
}

.product-details-content .pro-dec-info-wrap .pro-dec-stock ul li:first-child {
  margin-left: 0px;
}

.product-details-content > h3 {
  font-size: 32px;
  margin: 0px 0 10px;
  font-weight: bold;
  color: #262626;
}

.product-details-content .product-details-peragraph p {
  font-size: 14px;
  line-height: 27px;
  color: #737373;
}

.product-details-content .product-details-action-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 45px 0 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 0 23px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap {
    margin: 20px 0 0px;
  }
}

.product-details-content .product-details-action-wrap .product-details-cart {
  margin: 0 15px 0 15px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-cart {
    margin: 0 7px 7px 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-cart {
    margin: 0 15px 0 15px;
  }
}

.product-details-content .product-details-action-wrap .product-details-cart a {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  padding: 22px 80px;
  background-color: #262626;
  font-weight: bold;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-content .product-details-action-wrap .product-details-cart a {
    padding: 22px 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content .product-details-action-wrap .product-details-cart a {
    padding: 22px 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-action-wrap .product-details-cart a {
    padding: 22px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-cart a {
    padding: 22px 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-cart a {
    padding: 22px 30px;
  }
}

.product-details-content .product-details-action-wrap .product-details-cart a:hover {
  background-color: #ff6e21;
}

.product-details-content .product-details-action-wrap .product-details-cart.product-dec-cart-mrg-none {
  margin: 0 15px 0 0px;
}

.product-details-content .product-details-action-wrap .product-details-wishlist a, .product-details-content .product-details-action-wrap .product-details-compare a {
  display: inline-block;
  font-size: 25px;
  color: #2f2f2f;
}

.product-details-content .product-details-action-wrap .product-details-wishlist a:hover, .product-details-content .product-details-action-wrap .product-details-compare a:hover {
  color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-wishlist.affiliate-res-xs {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-wishlist.affiliate-res-xs {
    margin-bottom: 0px;
  }
}

.product-details-content .product-details-action-wrap .product-details-compare {
  margin-left: 15px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-compare.affiliate-res-xs {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-action-wrap .product-details-compare.affiliate-res-xs {
    margin-left: 10px;
  }
}

.product-details-content .product-details-action-wrap.pro-dec-action-mrg {
  margin: 16px 0 0px;
}

.product-details-content .product-details-info {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  padding: 12px 0 14px;
}

.product-details-content .product-details-info a {
  color: #535353;
  font-size: 14px;
  margin-right: 10px;
}

.product-details-content .product-details-info a:last-child {
  margin-right: 0;
}

.product-details-content .product-details-info a i {
  font-size: 16px;
  padding-right: 8px;
  position: relative;
  top: 3px;
}

.product-details-content .product-details-info a:hover {
  color: #ff6e21;
}

.product-details-content .product-details-meta {
  margin: 25px 0 23px;
}

.product-details-content .product-details-meta > span {
  display: block;
  font-size: 14px;
  margin: 0 0 5px;
  color: #2f2f2f;
}

.product-details-content .product-details-meta > span.categories a {
  color: #a59044;
}

.product-details-content .product-details-meta > span.categories a:hover {
  color: #ff6e21;
  text-decoration: underline;
}

.product-details-content .product-details-meta > span a {
  color: #2f2f2f;
}

.product-details-content .product-details-meta > span a:hover {
  color: #ff6e21;
  text-decoration: underline;
}

.product-details-content .product-details-meta > span:last-child {
  margin: 0 0 0px;
}

.product-details-content .product-details-social-wrap {
  display: flex;
  align-items: center;
  margin: 18px 0 0;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-social-wrap {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-social-wrap {
    display: flex;
  }
}

.product-details-content .product-details-social-wrap span {
  color: #262626;
  font-weight: 600;
  margin-right: 10px;
  font-size: 12px;
}

.product-details-content .product-details-social-wrap .product-details-social a {
  border: 1px solid #e1e1e1;
  color: #1d1d1d;
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 100%;
  text-align: center;
  font-size: 16px;
  margin: 0 7px 0 0;
}

.product-details-content .product-details-social-wrap .product-details-social a:hover {
  color: #fff;
  border: 1px solid transparent;
}

.product-details-content .product-details-social-wrap .product-details-social a:hover.facebook {
  background-color: #3b5999;
}

.product-details-content .product-details-social-wrap .product-details-social a:hover.twitter {
  background-color: #55acee;
}

.product-details-content .product-details-social-wrap .product-details-social a:hover.pinterest {
  background-color: #b90f16;
}

.product-details-content .product-details-social-wrap .product-details-social a:hover.google-plus {
  background-color: #dd4b39;
}

.product-details-quality {
  margin: 7px 0 0;
}

@media only screen and (max-width: 767px) {
  .product-details-quality {
    margin: 0px 0 0;
  }
}

.product-details-quality .cart-plus-minus {
  display: inline-block;
  height: 60px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 85px;
  text-align: center;
  border: 1px solid #1d1d1d;
}

.product-details-quality .cart-plus-minus .qtybutton {
  color: #181818;
  cursor: pointer;
  float: inherit;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 24px;
}

.product-details-quality .cart-plus-minus .qtybutton.dec {
  height: 58px;
  top: 21px;
  left: 4px;
  font-weight: 400;
  font-size: 22px;
}

.product-details-quality .cart-plus-minus .qtybutton.inc {
  height: 58px;
  top: 20px;
  right: 4px;
  font-weight: 400;
  font-size: 22px;
}

.product-details-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #282828;
  font-size: 14px;
  height: 60px;
  line-height: 54px;
  margin: 0;
  padding: 0px;
  text-align: center;
  width: 85px;
  font-weight: bold;
}

.product-details-quality.pro-quality-border .cart-plus-minus input {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.product-details-quality.quality-width-cart .cart-plus-minus {
  width: 90px;
  border: none;
}

.product-details-quality.quality-width-cart .cart-plus-minus input {
  width: 90px;
  font-weight: 400;
}

.product-details-quality.quality-width-cart .cart-plus-minus .qtybutton.dec {
  left: 0;
}

.product-details-quality.quality-width-cart .cart-plus-minus .qtybutton.inc {
  right: 0;
}

.quickview-slider-active.owl-carousel > .owl-nav div {
  color: #262626;
  display: inline-block;
  font-size: 24px;
  left: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  border: 1px solid #e1e2e2;
  background-color: #ffffff;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100%;
}

.quickview-slider-active.owl-carousel > .owl-nav div.owl-next {
  right: 20px;
  left: auto;
}

.quickview-slider-active.owl-carousel > .owl-nav div:hover {
  color: #ff6e21;
}

.easyzoom-style {
  position: relative;
}

.easyzoom-style a.easyzoom-pop-up {
  position: absolute;
  right: 25px;
  bottom: 25px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background-color: #fff;
  color: #262626;
  font-size: 16px;
  text-align: center;
  border-radius: 100%;
  z-index: 999;
}

@media only screen and (max-width: 767px) {
  .easyzoom-style a.easyzoom-pop-up {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
}

.easyzoom-style a.easyzoom-pop-up.video-popup {
  color: #fff;
  font-size: 16px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #262626;
}

.easyzoom-style a.easyzoom-pop-up.img-popup-position {
  bottom: 100px;
}

.easyzoom-style a.easyzoom-pop-up:hover {
  background-color: #ff6e21;
  color: #fff;
}

.easyzoom-style .easyzoom {
  display: block;
}

.easyzoom-style .easyzoom > a {
  transition: none;
}

.easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.easyzoom-style .easyzoom-popup > a img {
  width: 100%;
}

.product-details-tab {
  display: block;
}

.product-details-tab.product-details-tab2 {
  overflow: inherit;
}

.product-dec-right {
  float: right;
  position: relative;
  width: calc(100% - 160px);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-dec-right {
    width: calc(100% - 120px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-right {
    width: calc(100% - 120px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-right {
    width: calc(100% - 130px);
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-right {
    width: calc(100% - 100px);
  }
}

.product-dec-left {
  position: relative;
  float: left;
  width: 160px;
  padding-right: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-dec-left {
    width: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-left {
    width: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-left {
    width: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-left {
    width: 100px;
  }
}

.product-dec-left .product-dec-small {
  margin: 0 0 20px;
  cursor: pointer;
}

.product-dec-left .product-dec-small img {
  width: 100%;
}

.product-dec-left .product-dec-small.slick-active {
  border: 2px solid transparent;
}

.product-dec-left .product-dec-small.slick-active.slick-current {
  border: 2px solid #aaa;
}

.product-dec-left .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-left span.pro-dec-icon {
  position: absolute;
  top: 100%;
  left: 50%;
  right: auto;
  font-size: 20px;
  cursor: pointer;
  color: #262626;
  z-index: 9;
  transform: translateX(-50%);
}

@media only screen and (max-width: 767px) {
  .product-dec-left span.pro-dec-icon {
    top: 93%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-left span.pro-dec-icon {
    top: 100%;
  }
}

.product-dec-left span.pro-dec-icon:hover {
  color: #ff6e21;
}

.product-dec-left span.pro-dec-icon.pro-dec-prev {
  margin-left: -30px;
}

.product-dec-left span.pro-dec-icon.pro-dec-next {
  margin-right: -30px;
}

.product-details-wrap {
  display: flex;
  flex-wrap: wrap;
}

.product-details-wrap .pro-dec-content-left {
  flex: 0 0 72%;
  max-width: 72%;
  padding-right: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-wrap .pro-dec-content-left {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-wrap .pro-dec-content-left {
    padding-right: 0px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.product-details-wrap .pro-dec-content-right {
  flex: 0 0 28%;
  max-width: 28%;
}

@media only screen and (max-width: 767px) {
  .product-details-wrap .pro-dec-content-right {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 30px;
  }
}

.pro-dec-banner-wrap {
  position: relative;
  overflow: hidden;
}

.pro-dec-banner-wrap a {
  display: block;
}

.pro-dec-banner-wrap a img {
  width: 100%;
  transition: all .5s ease 0s;
  transform: scale(1);
}

.pro-dec-banner-wrap .pro-dec-banner-content {
  position: absolute;
  left: 20px;
  bottom: 12px;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-dec-banner-wrap .pro-dec-banner-content {
    left: 12px;
  }
}

.pro-dec-banner-wrap .pro-dec-banner-content h4 {
  color: #fff;
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 10px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h4 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h4 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h4 {
    font-size: 20px;
  }
}

.pro-dec-banner-wrap .pro-dec-banner-content h2 {
  color: #fff;
  font-size: 45px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-dec-banner-wrap .pro-dec-banner-content h2 {
    font-size: 35px;
  }
}

.pro-dec-banner-wrap:hover a img {
  width: 100%;
  transform: scale(1.1);
}

.sidebar-subscribe-wrap {
  background-color: #E5F3EF;
  padding: 30px 20px 35px 20px;
  margin-top: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-subscribe-wrap {
    padding: 20px 10px 20px 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-subscribe-wrap {
    padding: 20px 10px 20px 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-subscribe-wrap {
    padding: 20px 10px 20px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-subscribe-wrap {
    padding: 20px 10px 20px 10px;
  }
}

.sidebar-subscribe-wrap .sidebar-subscribe-content i {
  color: #1D1D1D;
  font-size: 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content i {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content i {
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content i {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content i {
    font-size: 25px;
  }
}

.sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
  font-size: 22px;
  margin: 1px 0 5px;
  color: #1D1D1D;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-subscribe-wrap .sidebar-subscribe-content h5 {
    font-size: 16px;
  }
}

.sidebar-subscribe-wrap .sidebar-subscribe-content p {
  font-size: 14px;
  color: #1D1D1D;
  font-family: "Playfair Display", serif;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form {
  margin-top: 9px;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form > input {
  background-color: transparent;
  border: none;
  color: #777;
  border-bottom: 1px solid #858C8A;
  padding: 2px 0 2px 0;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form .mc-news {
  display: none;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form .clear {
  margin-top: 12px;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form .clear > input {
  border: none;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  background-color: #262626;
  height: 42px;
}

.sidebar-subscribe-wrap .sidebar-subscribe-form .mc-form .clear > input:hover {
  background-color: #ff6e21;
}

.product-dec-slider.slick-vertical .slick-slide,
.product-dec-small-style2.slick-vertical .slick-slide {
  border: 2px solid transparent;
}

.product-dec-slider .slick-slide,
.product-dec-small-style2 .slick-slide {
  border: 2px solid transparent;
}

.product-dec-col-38 {
  flex: 0 0 34%;
  max-width: 34%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-col-38 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-col-38 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.product-dec-col-62 {
  flex: 0 0 66%;
  max-width: 66%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-col-62 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-col-62 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.description-review-topbar {
  margin-bottom: 70px;
  border-bottom: 3px solid #D8D8D8;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-topbar {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-topbar {
    margin-bottom: 20px;
  }
}

.description-review-topbar a {
  font-size: 24px;
  color: #BEBEBE;
  line-height: 1;
  text-transform: uppercase;
  margin-right: 70px;
  letter-spacing: .86px;
  position: relative;
  padding-bottom: 33px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .description-review-topbar a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .description-review-topbar a {
    font-size: 20px;
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-review-topbar a {
    font-size: 17px;
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-topbar a {
    font-size: 17px;
    letter-spacing: 0px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-topbar a {
    font-size: 14px;
    letter-spacing: 0px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

.description-review-topbar a:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 10px;
  background-color: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-topbar a:before {
    height: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-topbar a:before {
    height: 2px;
  }
}

.description-review-topbar a:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .description-review-topbar a {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .description-review-topbar a {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-review-topbar a {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-topbar a {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-topbar a {
    margin-right: 10px;
  }
}

.description-review-topbar a.active {
  color: #1D1D1D;
}

.description-review-topbar a.active:before {
  background-color: #1D1D1D;
}

.pro-details-banner {
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-banner {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-details-banner {
    margin-bottom: 20px;
  }
}

.pro-details-banner a {
  position: relative;
}

.pro-details-banner a img {
  width: 100%;
  transition: all .5s ease 0s;
  transform: scale(1);
}

.pro-details-banner:hover a img {
  transform: scale(1.1);
}

.product-dec-content {
  padding-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-content {
    padding-left: 0px;
  }
}

.product-dec-content p {
  line-height: 32px;
  margin: 0;
  font-size: 18px;
  color: #777;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-content p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-content p {
    font-size: 15px;
    line-height: 28px;
  }
}

.product-dec-content .product-dec-list-wrap {
  margin: 48px 0 50px;
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-content .product-dec-list-wrap {
    margin: 18px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content .product-dec-list-wrap {
    margin: 18px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-content .product-dec-list-wrap {
    margin: 18px 0 10px;
    display: block;
  }
}

.product-dec-content .product-dec-list-wrap .product-dec-list {
  margin-right: 40px;
}

@media only screen and (max-width: 767px) {
  .product-dec-content .product-dec-list-wrap .product-dec-list {
    margin-right: 0px;
  }
}

.product-dec-content .product-dec-list-wrap .product-dec-list:last-child {
  margin-right: 0;
}

.product-dec-content .product-dec-list-wrap .product-dec-list ul {
  list-style-position: inside;
  margin: 0px 0 0px 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-content .product-dec-list-wrap .product-dec-list ul {
    margin: 15px 0 30px 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content .product-dec-list-wrap .product-dec-list ul {
    margin: 15px 0 30px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-content .product-dec-list-wrap .product-dec-list ul {
    margin: 15px 0 20px 12px;
  }
}

.product-dec-content .product-dec-list-wrap .product-dec-list ul li {
  margin-bottom: 25px;
  list-style: disc;
  line-height: 24px;
  font-size: 18px;
  color: #777;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-content .product-dec-list-wrap .product-dec-list ul li {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-content .product-dec-list-wrap .product-dec-list ul li {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-content .product-dec-list-wrap .product-dec-list ul li {
    font-size: 15px;
    margin-bottom: 12px;
  }
}

.product-dec-content .product-dec-list-wrap .product-dec-list ul li:last-child {
  margin-bottom: 0;
}

.additional-info ul li {
  margin-bottom: 20px;
  font-size: 18px;
  color: #777;
}

@media only screen and (max-width: 767px) {
  .additional-info ul li {
    font-size: 14px;
  }
}

.additional-info ul li:last-child {
  margin-bottom: 0;
}

.additional-info ul li span {
  width: 215px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .additional-info ul li span {
    width: 70px;
  }
}

.review-wrapper h2 {
  font-size: 30px;
  margin: 0 0 37px;
}

@media only screen and (max-width: 767px) {
  .review-wrapper h2 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

.single-review {
  margin: 0 0 60px;
  padding: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
}

@media only screen and (max-width: 767px) {
  .single-review {
    padding: 30px;
    display: block;
    margin: 0 0 30px;
  }
}

.single-review .review-img img {
  border-radius: 100%;
}

.single-review .review-content {
  margin-left: 50px;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content {
    margin-left: 0px;
  }
}

.single-review .review-content .review-top-wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content .review-top-wrap {
    display: block;
    margin: 10px 0 10px;
  }
}

.single-review .review-content .review-top-wrap .review-name h5 {
  font-size: 14px;
  margin: 0;
  color: #535353;
}

.single-review .review-content .review-top-wrap .review-name h5 span {
  font-weight: bold;
  font-size: 14px;
  color: #262626;
}

.single-review .review-content .review-top-wrap .review-rating {
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content .review-top-wrap .review-rating {
    margin: 10px 0 0px;
  }
}

.single-review .review-content .review-top-wrap .review-rating i {
  font-size: 14px;
  color: #d5d6d8;
  margin-left: 2px;
}

.single-review .review-content .review-top-wrap .review-rating i.yellow {
  color: #f5b223;
}

.single-review .review-content p {
  font-size: 16px;
  color: #777;
  line-height: 1.714286;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content p {
    font-size: 15px;
  }
}

.ratting-form-wrapper > span {
  display: block;
  line-height: 1;
}

.ratting-form-wrapper p {
  font-size: 12px;
  margin: 9px 0 0;
  color: #535353;
}

.ratting-form-wrapper p span {
  color: red;
}

.ratting-form-wrapper .ratting-form {
  margin: 30px 0 0;
}

.ratting-form-wrapper .ratting-form .star-box-wrap {
  display: flex;
  margin: 16px 0 29px;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star {
  margin-right: 17px;
  display: flex;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star a {
  color: #535353;
}

.ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star:hover i {
  color: #f5b223;
}

.ratting-form-wrapper .ratting-form .rating-form-style label {
  margin: 0 0 5px;
  color: #535353;
}

.ratting-form-wrapper .ratting-form .rating-form-style label span {
  color: red;
}

.ratting-form-wrapper .ratting-form .rating-form-style input, .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 50px;
  padding: 5px 10px;
}

.ratting-form-wrapper .ratting-form .rating-form-style input:focus, .ratting-form-wrapper .ratting-form .rating-form-style textarea:focus {
  border: 1px solid #262626;
}

.ratting-form-wrapper .ratting-form .rating-form-style textarea {
  height: 150px;
}

.ratting-form-wrapper .ratting-form .form-submit {
  margin-top: 20px;
}

.ratting-form-wrapper .ratting-form .form-submit input {
  padding: 10px 35px;
  line-height: 30px;
  color: #fff;
  border-width: 0;
  font-weight: 600;
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 14px;
  text-transform: uppercase;
  background: #262626;
}

.ratting-form-wrapper .ratting-form .form-submit input:hover {
  background: #ff6e21;
}

.pro-dec-brand-img img {
  width: 140px;
}

@media only screen and (max-width: 767px) {
  .pro-dec-brand-img {
    margin: 0 0 20px;
  }
}

.pro-dec-brand-content p {
  margin: 0;
  color: #777;
  font-size: 18px;
  line-height: 34px;
}

@media only screen and (max-width: 767px) {
  .pro-dec-brand-content p {
    line-height: 30px;
    font-size: 14px;
  }
}

.about-shiping-content p {
  color: #777;
  line-height: 34px;
  font-size: 16px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .about-shiping-content p {
    line-height: 30px;
    font-size: 14px;
  }
}

.about-shiping-content p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .description-review-area.pb-105 {
    padding-bottom: 40px;
  }
}

.product-dec-small-style2 {
  width: 450px;
  margin: 13px auto 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-small-style2 {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style2 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style2 {
    width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style2 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-small-style2 {
    width: 450px;
  }
}

.product-dec-small-style2 .product-dec-small {
  margin: 0 5px 0px 5px;
  cursor: pointer;
}

.product-dec-small-style2 .product-dec-small img {
  width: 100%;
}

.product-dec-small-style2 .product-dec-small.slick-active {
  border: 2px solid transparent;
}

.product-dec-small-style2 .product-dec-small.slick-active.slick-current {
  border: 2px solid #262626;
}

.product-dec-small-style2 .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-small-style2 span.pro-dec-icon {
  color: #262626;
  font-size: 20px;
  position: absolute;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
}

.product-dec-small-style2 span.pro-dec-icon:hover {
  color: #ff6e21;
}

.product-dec-small-style2 span.pro-dec-prev {
  left: -5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style2 span.pro-dec-prev {
    left: -3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style2 span.pro-dec-prev {
    left: -4%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style2 span.pro-dec-prev {
    left: -4%;
  }
}

.product-dec-small-style2 span.pro-dec-next {
  left: auto;
  right: -5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style2 span.pro-dec-next {
    right: -3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style2 span.pro-dec-next {
    right: -4%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style2 span.pro-dec-next {
    right: -4%;
  }
}

.pro-details-group {
  margin: 40px 0 0;
}

@media only screen and (max-width: 767px) {
  .pro-details-group {
    margin: 15px 0 0;
  }
}

.pro-details-group .single-group {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.pro-details-group .single-group .pro-dec-title-stock {
  margin: 5px 0 0 25px;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-title h5 {
  font-size: 14px;
  margin: 0 0 3px;
  text-transform: uppercase;
  color: #262626;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-title h5 a {
  color: #262626;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-title h5 a:hover {
  color: #ff6e21;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-price-stock span {
  color: #262626;
  font-size: 14px;
}

.pro-details-group .single-group .pro-dec-title-stock .pro-dec-price-stock span.old-price {
  text-decoration: line-through;
  margin-left: 5px;
}

.affiliate-btn {
  margin: 0 20px 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .affiliate-btn {
    margin: 0 20px 5px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .affiliate-btn {
    margin: 0 20px 20px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .affiliate-btn {
    margin: 0 20px 0 0px;
  }
}

.affiliate-btn a {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  padding: 15px 40px 20px;
  font-weight: 600;
  background-color: #262626;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .affiliate-btn a {
    padding: 20px 26px;
  }
}

@media only screen and (max-width: 767px) {
  .affiliate-btn a {
    padding: 20px 30px;
  }
}

.affiliate-btn a:hover {
  background-color: #ff6e21;
}

.configurable-wrap {
  margin: 33px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 27px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap {
    margin: 20px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .configurable-wrap {
    margin: 20px 0 0;
  }
}

.configurable-wrap .configurable-color {
  display: flex;
  align-items: center;
  margin: 0 0px 8px 0;
}

.configurable-wrap .configurable-color > span {
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-color > span {
    margin-right: 10px;
  }
}

.configurable-wrap .configurable-color ul li {
  display: inline-block;
  margin-right: 3px;
}

.configurable-wrap .configurable-color ul li:last-child {
  margin-bottom: 0;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 4px 10px 6px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.blue {
  background-color: #5097f7;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.brown {
  background-color: #7a5816;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.green {
  background-color: #81d742;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.pink {
  background-color: #ff0c94;
}

.configurable-wrap .configurable-color ul li a .swatch-anchor.violet {
  background-color: #8224e3;
}

.configurable-wrap .configurable-size {
  display: flex;
  align-items: center;
}

.configurable-wrap .configurable-size > span {
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-size > span {
    margin-right: 10px;
  }
}

.configurable-wrap .configurable-size ul li {
  display: inline-block;
  margin-right: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .configurable-wrap .configurable-size ul li {
    margin-right: 5px;
  }
}

.configurable-wrap .configurable-size ul li:last-child {
  margin-bottom: 0;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor {
  font-size: 14px;
  display: inline-block;
  position: relative;
  color: #262626;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 4px 10px 6px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.configurable-wrap .configurable-size ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.sidebar-active.col-xl-6 {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-active.col-xl-6.is-affixed {
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar-active.col-xl-6.is-affixed .inner-wrapper-sticky {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-dec-res-mrg-top {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-dec-res-mrg-top {
    margin-top: 30px;
  }
  .pro-dec-res-mrg-top2 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-dec-res-mrg-top {
    margin-top: 30px;
  }
  .pro-dec-res-mrg-top2 {
    margin-top: 30px;
  }
}

/*-------- 20. Breadcrumb style ---------*/
.breadcrumb-ptb-1 {
  padding: 75px 0 85px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-1 {
    padding: 45px 0 55px;
  }
}

.breadcrumb-ptb-2 {
  padding: 0px 0 23px;
}

.breadcrumb-content {
  position: relative;
  z-index: 9;
}

.breadcrumb-content .breadcrumb-title h2 {
  font-size: 46px;
  color: #343434;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 30px;
  }
}

.breadcrumb-content ul li {
  display: inline-block;
  margin: 0 2px;
  text-transform: capitalize;
  color: #737373;
}

.breadcrumb-content ul li span {
  margin-right: 4px;
  display: inline-block;
  margin: 6px 4px 0 0;
  line-height: 1;
  position: relative;
  top: 1px;
}

.breadcrumb-content ul li a {
  text-transform: capitalize;
  color: #181818;
}

.breadcrumb-content ul li a:hover {
  color: #ff6e21;
}

/*-------- 22. Shop style ---------*/
.shop-top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar {
    display: block;
  }
}

.shop-top-bar .shop-top-bar-left {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: flex;
  }
}

.shop-top-bar .shop-top-bar-left .shop-page-list {
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-top: 0px;
  }
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li {
  display: inline-block;
  color: #1d1d1d;
  margin-right: 3px;
  font-size: 14px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li:last-child {
  margin-right: 0px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active a {
  color: #262626;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li a {
  color: #1d1d1d;
  font-size: 14px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li a:hover {
  color: #ff6e21;
}

.shop-top-bar .shop-top-bar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right {
    display: flex;
  }
}

.shop-top-bar .shop-top-bar-right .shop-filter {
  line-height: 1;
  position: relative;
  display: inline-block;
}

.shop-top-bar .shop-top-bar-right .shop-filter a {
  color: #1B1B1B;
  display: inline-block;
  border: 1px solid #d8d8d8;
  padding: 14px 20px 14px 20px;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i {
  color: #1B1B1B;
  margin-left: 20px;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active {
  color: #ff6e21;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i {
  color: #ff6e21;
}

.shop-top-bar .shop-top-bar-right .shop-filter:hover a {
  color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-filter.ml-25 {
    margin-left: 0;
  }
}

.shop-top-bar .shop-top-bar-right .shop-short-by {
  position: relative;
  display: inline-block;
  line-height: 1;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span {
  color: #1B1B1B;
  position: relative;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #d8d8d8;
  padding: 14px 20px 14px 20px;
  transition: all .3s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i {
  color: #1B1B1B;
  margin-left: 20px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul {
  right: 0;
  top: 100%;
  position: absolute;
  background-color: #fff;
  padding: 7px;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-out;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-short-by ul {
    right: auto;
    left: 0;
  }
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li {
  display: block;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a {
  padding: 5px 10px;
  white-space: pre;
  display: block;
  border-radius: 4px;
  font-size: 0.92em;
  color: #777777;
  line-height: 24px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a:hover {
  color: #fff;
  background-color: #ff6e21;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li.active a {
  color: #fff;
  background-color: #ff6e21;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover > span {
  color: #ff6e21;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover > span i {
  color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-short-by.ml-40 {
    margin-left: 0;
    margin-top: 12px;
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-short-by.ml-40 {
    margin-left: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.shop-top-bar .shop-top-bar-right .shop-tab a {
  color: #BEBEBE;
  font-size: 22px;
  margin-right: 20px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a:last-child {
  margin-right: 0;
}

.shop-top-bar .shop-top-bar-right .shop-tab a.active {
  color: #191919;
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 12px;
  padding: 48px 40px 18px;
  display: none;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

.product-filter-wrapper .product-filter h5 {
  font-size: 16px;
  margin: 0 0 30px;
  text-transform: capitalize;
  font-weight: 600;
  color: #1B1B1B;
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper .product-filter h5 {
    margin: 0 0 10px;
  }
}

.product-filter-wrapper .product-filter .price-filter ul li, .product-filter-wrapper .product-filter .product-size ul li, .product-filter-wrapper .product-filter .product-color ul li, .product-filter-wrapper .product-filter .product-brands ul li {
  margin-bottom: 7px;
  display: block;
}

.product-filter-wrapper .product-filter .price-filter ul li:last-child, .product-filter-wrapper .product-filter .product-size ul li:last-child, .product-filter-wrapper .product-filter .product-color ul li:last-child, .product-filter-wrapper .product-filter .product-brands ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-filter .price-filter ul li a, .product-filter-wrapper .product-filter .product-size ul li a, .product-filter-wrapper .product-filter .product-color ul li a, .product-filter-wrapper .product-filter .product-brands ul li a {
  color: #1B1B1B;
  font-size: 14px;
}

.product-filter-wrapper .product-filter .price-filter ul li a:hover, .product-filter-wrapper .product-filter .product-size ul li a:hover, .product-filter-wrapper .product-filter .product-color ul li a:hover, .product-filter-wrapper .product-filter .product-brands ul li a:hover {
  color: #ff6e21;
}

.product-filter-wrapper .product-filter .product-color ul, .product-filter-wrapper .product-filter .product-size ul, .product-filter-wrapper .product-filter .product-brands ul {
  width: 100%;
}

.product-filter-wrapper .product-filter .product-color ul li, .product-filter-wrapper .product-filter .product-size ul li, .product-filter-wrapper .product-filter .product-brands ul li {
  width: 50%;
  display: inline-block;
  float: left;
}

.shop-list-content h3 {
  font-size: 24px;
  margin: 0 0 20px;
  line-height: 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-list-content h3 {
    margin: 0 0 18px;
    font-size: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-content h3 {
    margin: 0 0 12px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content h3 {
    font-size: 19px;
    margin: 0 0 10px;
    line-height: 28px;
  }
}

.shop-list-content .pro-list-price span {
  font-size: 18px;
}

.shop-list-content .pro-list-price span.old {
  color: #777;
  margin-right: 5px;
}

.shop-list-content p {
  line-height: 27px;
  color: #737373;
  margin: 12px 0 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-list-content p {
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-content p {
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content p {
    margin: 10px 0 20px;
  }
}

.shop-list-content .product-list-action {
  display: flex;
  align-items: center;
}

.shop-list-content .product-list-action > a {
  display: inline-block;
  font-weight: bold;
  color: #2f2f2f;
  font-size: 25px;
  text-align: center;
}

.shop-list-content .product-list-action > a:hover {
  color: #ff6e21;
}

.shop-list-content .product-list-action .pro-list-actioncart {
  margin: 0 30px 0;
}

.shop-list-content .product-list-action .pro-list-actioncart a {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  padding: 22px 50px;
  background-color: #262626;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-content .product-list-action .pro-list-actioncart a {
    padding: 22px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-content .product-list-action .pro-list-actioncart a {
    padding: 18px 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-content .product-list-action .pro-list-actioncart a {
    padding: 15px 20px;
  }
}

.shop-list-content .product-list-action .pro-list-actioncart a:hover {
  background-color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .shop-list-content {
    margin-top: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-content {
    margin-top: 0px;
  }
}

.shop-list-wrap:hover .shop-list-quickview a {
  transform: scale(1);
  opacity: 1;
}

.shop-list-wrap:hover .product-img::after {
  right: 24px;
  left: 24px;
  top: 24px;
  bottom: 24px;
  opacity: 1;
}

.pro-pagination-style ul li {
  display: inline-block;
  margin: 0 8px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li {
    margin: 0 4px;
  }
}

.pro-pagination-style ul li a {
  border: 1px solid #CdCdCd;
  color: #1B1B1B;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 100%;
}

.pro-pagination-style ul li a.active, .pro-pagination-style ul li a:hover {
  background-color: #232830;
  color: #fff;
  border: 1px solid #232830;
}

.pro-pagination-style ul li a i {
  line-height: 38px;
}

.pro-pagination-style ul li:last-child {
  position: relative;
  top: 2px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-30 {
    margin-top: 0;
  }
}

.shop-collections-img {
  overflow: hidden;
}

.shop-collections-img a {
  display: block;
}

.shop-collections-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.shop-collections-content span {
  font-size: 16px;
  font-weight: 300;
  color: #1D1D1D;
}

.shop-collections-content h2 {
  font-size: 46px;
  font-family: "Playfair Display", serif;
  font-style: italic;
  color: #1D1D1D;
  margin: 5px 0 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collections-content h2 {
    font-size: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collections-content h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collections-content h2 {
    font-size: 28px;
    margin: 5px 0 12px;
  }
}

.shop-collections-content h2 a {
  color: #1D1D1D;
}

.shop-collections-content h2 a:hover {
  color: #ff6e21;
}

.shop-collections-content p {
  font-size: 16px;
  line-height: 34px;
  color: #707070;
  margin: 0 0 31px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collections-content p {
    font-size: 15px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collections-content p {
    font-size: 15px;
    line-height: 30px;
    margin: 0 0 15px;
  }
}

.shop-collections-content .collections-btn a {
  display: inline-block;
  background-color: #1D1D1D;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 20px 35px;
  line-height: 1;
  z-index: 2;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collections-content.ml-70 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collections-content.ml-70 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collections-content {
    margin-top: 10px;
  }
  .shop-collections-content.ml-70 {
    margin-left: 0;
  }
}

.shop-collections-wrap:hover .shop-collections-img a img {
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collections-wrap.mb-90 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collections-wrap.mb-90 {
    margin-bottom: 50px;
  }
}

.shop-instagram-wrap a {
  display: block;
}

.shop-instagram-wrap a img {
  width: 100%;
}

.toggle-item-active {
  display: none;
}

.instra-btn a {
  text-transform: uppercase;
  font-weight: bold;
  color: #1D1D1D;
  font-size: 12px;
  line-height: 1;
  border: 1px solid #D2D2D2;
  padding: 23px 50px;
  display: inline-block;
}

.instra-btn a:hover {
  background-color: #ff6e21;
  border: 1px solid #ff6e21;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .instra-btn.mt-50 {
    margin-top: 10px;
  }
}

/*-------- 23. Sidebar style ---------*/
.sidebar-widget {
  display: block;
  overflow: hidden;
}

.sidebar-widget h4.pro-sidebar-title {
  font-size: 24px;
  margin: 0;
  color: #1D1D1D;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget h4.pro-sidebar-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget h4.pro-sidebar-title {
    font-size: 20px;
    letter-spacing: 1px;
  }
}

.sidebar-widget h4.pro-sidebar-title2 {
  font-size: 18px;
  margin: 0;
  color: #535353;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 5px;
  border-bottom: 2px solid #262626;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget h4.pro-sidebar-title2 {
    font-size: 20px;
    letter-spacing: 1px;
  }
}

.sidebar-widget .sidebar-widget-categories > ul > li {
  display: block;
  margin-bottom: 17px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-categories > ul > li {
    margin-bottom: 12px;
  }
}

.sidebar-widget .sidebar-widget-categories > ul > li:last-child {
  margin-bottom: 0px;
}

.sidebar-widget .sidebar-widget-categories > ul > li > a {
  color: #1D1D1D;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-categories > ul > li > a {
    font-size: 15px;
    letter-spacing: 1px;
  }
}

.sidebar-widget .sidebar-widget-categories > ul > li > a span.menu-expand {
  margin-left: 12px;
}

.sidebar-widget .sidebar-widget-categories > ul > li ul {
  margin-top: 20px;
  margin-bottom: 35px;
}

.sidebar-widget .sidebar-widget-categories > ul > li ul li {
  margin-bottom: 11px;
}

.sidebar-widget .sidebar-widget-categories > ul > li ul li:last-child {
  margin-bottom: 0px;
}

.sidebar-widget .sidebar-widget-categories > ul > li ul li a {
  color: #707070;
  font-size: 12px;
  letter-spacing: 1px;
}

.sidebar-widget .sidebar-widget-categories > ul > li ul li a:hover {
  color: #1D1D1D;
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-categories.mt-50 {
    margin-top: 20px;
  }
}

.sidebar-widget .price-filter #slider-range {
  background: #eee none repeat scroll 0 0;
  border: medium none;
  border-radius: 50px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: auto;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #1D1D1D none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 20px;
  margin-left: 0;
  width: 20px;
  border: 1px solid #979797;
  top: -8px;
  cursor: ew-resize;
}

.sidebar-widget .price-filter .price-slider-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 0;
}

.sidebar-widget .price-filter .price-slider-amount .label-input {
  display: flex;
  align-items: center;
}

.sidebar-widget .price-filter .price-slider-amount .label-input span {
  font-size: 14px;
  margin-right: 5px;
  color: #1D1D1D !important;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  width: 60%;
  height: auto;
  color: #1D1D1D !important;
}

.sidebar-widget .price-filter .price-slider-amount button {
  background-color: transparent;
  padding: 6px 39px;
  border: 2px solid #1D1D1D;
  font-size: 11px;
  font-weight: bold;
  color: #1D1D1D;
  text-transform: uppercase;
  transition: all 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .price-filter .price-slider-amount button {
    padding: 5px 22px;
  }
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  background-color: #ff6e21;
  color: #fff;
  border: 2px solid #ff6e21;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .price-filter.mt-60 {
    margin-top: 30px;
  }
}

.sidebar-widget .sidebar-widget-size ul li, .sidebar-widget .sidebar-widget-tags ul li {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 10px;
}

.sidebar-widget .sidebar-widget-size ul li a, .sidebar-widget .sidebar-widget-tags ul li a {
  border: 1px solid #D8D8D8;
  padding: 13px 20px;
  display: block;
  transition: all 0.3s;
  color: #1D1D1D;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget .sidebar-widget-size ul li a, .sidebar-widget .sidebar-widget-tags ul li a {
    padding: 9px 14px;
  }
}

.sidebar-widget .sidebar-widget-size ul li a.widget-more-content, .sidebar-widget .sidebar-widget-tags ul li a.widget-more-content {
  border: none;
  color: #C3832D;
  padding: 13px 10px;
}

.sidebar-widget .sidebar-widget-size ul li a.widget-more-content:hover, .sidebar-widget .sidebar-widget-tags ul li a.widget-more-content:hover {
  background-color: transparent;
  color: #ff6e21;
  border: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-size ul li a, .sidebar-widget .sidebar-widget-tags ul li a {
    padding: 6px 10px;
  }
}

.sidebar-widget .sidebar-widget-size ul li a:hover, .sidebar-widget .sidebar-widget-tags ul li a:hover {
  background-color: #ff6e21;
  color: #fff;
  border: 1px solid #ff6e21;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-size.mt-55, .sidebar-widget .sidebar-widget-tags.mt-55 {
    margin-top: 25px;
  }
}

.sidebar-widget .sidebar-widget-brand ul li {
  align-items: center;
  position: relative;
  padding: 0px 0 13px;
}

.sidebar-widget .sidebar-widget-brand ul li:last-child {
  padding: 0px 0 0px;
}

.sidebar-widget .sidebar-widget-brand ul li .sidebar-widget-list-left {
  position: relative;
}

.sidebar-widget .sidebar-widget-brand ul li .sidebar-widget-list-left input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: auto;
}

.sidebar-widget .sidebar-widget-brand ul li .sidebar-widget-list-left input:checked ~ .checkmark {
  background-color: #ff6e21;
  border: 1px solid #ff6e21;
}

.sidebar-widget .sidebar-widget-brand ul li .sidebar-widget-list-left input:checked ~ .checkmark::after {
  display: block;
}

.sidebar-widget .sidebar-widget-brand ul li .sidebar-widget-list-left .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #979797;
}

.sidebar-widget .sidebar-widget-brand ul li .sidebar-widget-list-left .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.sidebar-widget .sidebar-widget-brand ul li .sidebar-widget-list-left .checkmark::after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.sidebar-widget .sidebar-widget-brand ul li a {
  margin-left: 25px;
  display: block;
  color: #1D1D1D;
  letter-spacing: 1px;
}

.sidebar-widget .sidebar-widget-brand ul li a.brand-more {
  color: #C3832D;
}

.sidebar-widget .sidebar-widget-brand ul li a.brand-more:hover {
  color: #ff6e21;
}

.sidebar-widget .sidebar-widget-brand ul li a:hover {
  color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-brand.mt-50 {
    margin-top: 20px;
  }
}

.sidebar-widget .sidebar-widget-color ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sidebar-widget .sidebar-widget-color ul li {
  margin-right: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget .sidebar-widget-color ul li {
    margin-right: 16px;
    margin-bottom: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .sidebar-widget-color ul li {
    margin-right: 16px;
    margin-bottom: 12px;
  }
}

.sidebar-widget .sidebar-widget-color ul li:last-child {
  margin-right: 0;
}

.sidebar-widget .sidebar-widget-color ul li a {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.sidebar-widget .sidebar-widget-color ul li a.color-more {
  color: #C3832D;
  font-size: 14px;
  width: auto;
  height: auto;
  letter-spacing: 1px;
}

.sidebar-widget .sidebar-widget-color ul li a.color-more:hover {
  color: #ff6e21;
}

.sidebar-widget .sidebar-widget-color ul li a.active::before {
  color: #fff;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 14px;
  position: absolute;
  z-index: 9;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.sidebar-widget .sidebar-widget-color ul li a.black {
  background-color: #1D1D1D;
}

.sidebar-widget .sidebar-widget-color ul li a.blue {
  background-color: #6090B6;
}

.sidebar-widget .sidebar-widget-color ul li a.green {
  background-color: #75B660;
}

.sidebar-widget .sidebar-widget-color ul li a.orange {
  background-color: #E79A9A;
}

.sidebar-widget .sidebar-widget-color ul li a.pink {
  background-color: #FF5A9F;
}

.sidebar-widget .sidebar-widget-color ul li a.gray {
  background-color: #D8D8D8;
}

.sidebar-widget .sidebar-widget-color ul li a.white {
  background-color: #f3f3f3;
}

.sidebar-widget .sidebar-widget-color ul li a.yellow {
  background-color: #A59044;
}

.sidebar-widget .sidebar-widget-color ul li a.violet {
  background-color: #714DC6;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-color.mt-50 {
    margin-top: 20px;
  }
}

.sidebar-widget .shop-sidebar-banner {
  position: relative;
  overflow: hidden;
}

.sidebar-widget .shop-sidebar-banner a img {
  width: 100%;
  transform: scale(1);
  transition: all .35s ease 0s;
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content {
  position: absolute;
  left: 20px;
  bottom: 10px;
  width: 100%;
  z-index: 99;
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content h5 {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  margin: 0 0 10px;
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h5 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h5 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h5 {
    font-size: 25px;
  }
}

.sidebar-widget .shop-sidebar-banner .shop-sidebar-content h2 {
  color: #fff;
  font-size: 150px;
  margin: 0 0 0px;
  letter-spacing: 4.69px;
  line-height: 106px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h2 {
    font-size: 100px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h2 {
    font-size: 80px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h2 {
    font-size: 80px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .shop-sidebar-banner .shop-sidebar-content h2 {
    font-size: 90px;
    line-height: 80px;
  }
}

.sidebar-widget .shop-sidebar-banner:hover a img {
  transform: scale(1.1);
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post {
  display: flex;
  border-bottom: 1px solid #DCDCDC;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
  flex: 0 0 100px;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img img {
  width: 100%;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content {
  margin-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content {
    margin-left: 10px;
  }
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
  font-size: 18px;
  color: #2f2f2f;
  font-family: "Playfair Display", serif;
  margin: 0 0 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
    font-size: 16px;
  }
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 a {
  color: #2f2f2f;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 a:hover {
  color: #ff6e21;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content span {
  color: #2f2f2f;
  font-size: 12px;
}

.sidebar-widget .sidebar-widget-list-2 ul li {
  color: #1d1d1d;
  font-family: "Playfair Display", serif;
  padding-bottom: 20px;
}

.sidebar-widget .sidebar-widget-list-2 ul li:last-child {
  padding-bottom: 0px;
}

.sidebar-widget .sidebar-widget-list-2 ul li a {
  color: #1d1d1d;
}

.sidebar-widget .sidebar-widget-list-2 ul li a:hover {
  color: #ff6e21;
}

.sidebar-widget .sidebar-widget-list-2 ul li span {
  float: right;
}

.sidebar-widget .archives-wrap select {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #535353;
  font-size: 14px;
  height: 50px;
  padding: 2px 20px;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 18px center;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .archives-wrap.mt-40 {
    margin-top: 20px;
  }
}

.sidebar-widget .sidebar-widget-tags-2 ul li {
  display: inline-block;
  margin: 0 0 6px;
}

.sidebar-widget .sidebar-widget-tags-2 ul li a {
  color: #535353;
}

.sidebar-widget .sidebar-widget-tags-2 ul li a:hover {
  color: #ff6e21;
  text-decoration: underline;
}

.sidebar-widget .blog-sidebar-banner {
  position: relative;
  overflow: hidden;
}

.sidebar-widget .blog-sidebar-banner a {
  display: block;
}

.sidebar-widget .blog-sidebar-banner a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.sidebar-widget .blog-sidebar-banner .blog-sidebar-content {
  position: absolute;
  left: 30px;
  bottom: 50px;
}

.sidebar-widget .blog-sidebar-banner .blog-sidebar-content h3 {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  margin: 0 0 5px;
}

.sidebar-widget .blog-sidebar-banner .blog-sidebar-content h5 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin: 0 0 0px;
}

.sidebar-widget .blog-sidebar-banner:hover a img {
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget.mt-60 {
    margin-top: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget.mb-60 {
    margin-bottom: 35px;
  }
  .sidebar-widget.mb-50 {
    margin-bottom: 25px;
  }
  .sidebar-widget.mb-55 {
    margin-bottom: 30px;
  }
  .sidebar-widget.mb-65 {
    margin-bottom: 40px;
  }
  .sidebar-widget.mt-60 {
    margin-top: 35px;
  }
}

.sidebar-search form {
  position: relative;
}

.sidebar-search form input {
  background-color: transparent;
  color: #262626;
  height: 41px;
  border: 1px solid #cdcdcd;
  padding: 2px 40px 2px 10px;
  font-size: 12px;
}

.sidebar-search form input::-moz-input-placeholder {
  color: #262626;
  opacity: 1;
}

.sidebar-search form input::-webkit-input-placeholder {
  color: #262626;
  opacity: 1;
}

.sidebar-search form .button-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: #2f2f2f;
  background-color: transparent;
  border: none;
  padding: 5px 20px;
  transition: all .3s ease 0s;
}

.sidebar-search form .button-search:hover {
  color: #ff6e21;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-sidebar-style.mr-50 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-style.mr-50 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sidebar-style {
    margin-top: 50px;
  }
  .shop-sidebar-style.mr-50 {
    margin-right: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sidebar-style {
    margin-top: 50px;
  }
  .shop-sidebar-style.mr-50 {
    margin-right: 0px;
  }
}

/*-------- 24. Cart style ---------*/
@media only screen and (max-width: 767px) {
  .cart-main-area.pb-100 {
    padding-bottom: 60px;
  }
}

h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 12px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  color: #535353;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 0 54px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 0 0 33px 0;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 20px 33px 20px;
  }
}

.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 0px 0px 48px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    padding: 0px 0px 40px;
    text-align: center;
  }
}

.cart-table-content table tbody > tr td.product-remove {
  width: 40px;
}

.cart-table-content table tbody > tr td.product-remove a {
  color: #2f2f2f;
}

.cart-table-content table tbody > tr td.product-remove a:hover {
  color: #ff6e21;
}

.cart-table-content table tbody > tr td.product-img {
  width: 130px;
}

.cart-table-content table tbody > tr td.product-name {
  width: 260px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 200px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 12px;
  color: #2f2f2f;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #ff6e21;
}

.cart-table-content table tbody > tr td.product-price {
  width: 100px;
}

.cart-table-content table tbody > tr td.product-price span {
  color: #2f2f2f;
  font-size: 14px;
}

.cart-table-content table tbody > tr td.cart-quality {
  width: 187px;
}

.cart-table-content table tbody > tr td.product-total {
  width: 70px;
}

.cart-table-content table tbody > tr td.product-total span {
  font-weight: bold;
  color: #2f2f2f;
  font-size: 14px;
}

.cart-table-content table tbody > tr td.product-wishlist-cart {
  width: 100px;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #232323;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  padding: 7px 8px;
  text-transform: capitalize;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #ff6e21;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #CDCDCD;
  padding: 13px 0 0;
  margin: 10px 0 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
  }
}

.cart-shiping-update-wrapper .discount-code {
  position: relative;
  flex: 0 0 50%;
}

.cart-shiping-update-wrapper .discount-code input {
  padding: 0 10px 0 0;
  border: none;
  height: 36px;
  font-size: 12px;
  color: #2f2f2f;
  background-color: transparent;
  border-bottom: 1px solid #D8D8D8;
}

.cart-shiping-update-wrapper .discount-code button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: bold;
  font-size: 14px;
  color: #2f2f2f;
  transition: all .3s ease 0s;
}

.cart-shiping-update-wrapper .discount-code button:hover {
  color: #ff6e21;
}

.cart-shiping-update-wrapper .cart-clear a {
  display: inline-block;
  margin: 8px 0 0 20px;
  color: #8A8A8A;
}

.cart-shiping-update-wrapper .cart-clear a:first-child {
  margin: 8px 0 0 0px;
}

.cart-shiping-update-wrapper .cart-clear a:hover {
  color: #ff6e21;
}

.cart-shiping-update-wrapper .cart-clear a.update-cart {
  font-weight: bold;
  font-size: 14px;
  color: #2f2f2f;
}

.cart-shiping-update-wrapper .cart-clear a.update-cart:hover {
  color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear {
    margin-top: 20px;
  }
}

.grand-total-wrap {
  background: #F9F9F9;
  padding: 35px 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

.grand-total-wrap h4 {
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  color: #262626;
}

.grand-total-wrap .grand-total-content {
  padding: 48px 0 50px;
}

.grand-total-wrap .grand-total-content > ul > li {
  font-weight: bold;
  padding-bottom: 33px;
  font-size: 14px;
  color: #1D1D1D;
  display: flex;
  justify-content: space-between;
}

.grand-total-wrap .grand-total-content > ul > li:last-child {
  border-top: 1px solid #DBDBDB;
  padding-top: 15px;
  padding-bottom: 0px;
}

.grand-total-wrap .grand-total-content > ul > li > ul > li {
  display: flex;
  font-size: 12px;
  color: #777;
  margin: 0 0 5px;
  font-weight: 400;
}

.grand-total-wrap .grand-total-content > ul > li > ul > li:last-child {
  margin: 0 0 0;
}

.grand-total-wrap .grand-total-content > ul > li > ul > li input {
  width: auto;
  height: auto;
  margin-right: 20px;
}

.grand-total-wrap .grand-total-content > ul > li span {
  float: right;
  font-size: 14px;
  color: #262626;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grand-total-wrap .grand-total-content > ul > li span {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap .grand-total-content > ul > li span {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap .grand-total-content > ul > li span {
    margin-right: 0px;
  }
}

.grand-total-wrap .grand-btn {
  margin-left: -30px;
  margin-right: -30px;
}

.grand-total-wrap .grand-btn a {
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 21px 10px 21px;
  text-transform: uppercase;
  font-weight: bold;
}

.grand-total-wrap .grand-btn a:hover {
  background-color: #ff6e21;
}

/*-------- 25. Order tracking style ---------*/
.order-tracking-content p {
  line-height: 24px;
  margin: 0 auto;
  text-align: center;
  width: 86%;
  color: #2f2f2f;
}

.order-tracking-content .order-tracking-form {
  margin: 30px 0 0;
}

.order-tracking-content .order-tracking-form .sin-order-tracking {
  margin: 0 0 36px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking label {
  color: #2f2f2f;
  font-weight: bold;
  margin: 0 0 10px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input {
  background: transparent;
  border: 1px solid #cecece;
  height: 70px;
  padding: 10px 20px;
  color: #2f2f2f;
  font-size: 14px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input:focus {
  border: 1px solid #262626;
}

.order-tracking-content .order-tracking-form .order-track-btn {
  text-align: center;
}

.order-tracking-content .order-tracking-form .order-track-btn a {
  display: block;
  font-weight: bold;
  color: #fff;
  background-color: #262626;
  line-height: 1;
  padding: 22px 50px;
  text-transform: capitalize;
}

.order-tracking-content .order-tracking-form .order-track-btn a:hover {
  background-color: #ff6e21;
}

/*-------- 26. Login register style ---------*/
.login-register-wrap h3 {
  font-size: 20px;
  margin: 0;
  color: #1D1D1D;
}

.login-register-wrap h3 i {
  margin-right: 10px;
}

.login-register-wrap h3 a {
  color: #969696;
}

.login-register-wrap h3 a:hover {
  color: #ff6e21;
}

.login-register-wrap .login-register-form {
  margin: 30px 0 0;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form {
    margin: 10px 0 0;
  }
}

.login-register-wrap .login-register-form .sin-login-register {
  margin: 0 0 10px;
}

.login-register-wrap .login-register-form .sin-login-register input {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 70px;
  padding: 5px 20px;
  color: #777;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form .sin-login-register input {
    height: 50px;
  }
}

.login-register-wrap .login-register-form .sin-login-register input:focus {
  border: 1px solid #262626;
}

.login-register-btn button {
  border: none;
  padding: 0;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  line-height: 1;
  margin-right: 7px;
}

@media only screen and (max-width: 767px) {
  .login-register-btn button {
    margin-bottom: 10px;
  }
}

.login-register-btn button.login {
  padding: 18px 50px;
  background-color: #262626;
}

.login-register-btn button.login-fb {
  padding: 18px 30px;
  background-color: #435DA7;
}

.login-register-btn button:hover {
  background-color: #ff6e21;
}

.remember-lost-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 23px 0 45px;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .remember-lost-wrap {
    display: block;
    margin: 12px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .remember-lost-wrap {
    display: flex;
    margin: 22px 0 25px;
  }
}

.remember-lost-wrap .remember-wrap {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .remember-lost-wrap .remember-wrap {
    display: block;
  }
}

.remember-lost-wrap .remember-wrap input {
  width: 16px;
  height: auto;
}

.remember-lost-wrap .remember-wrap label {
  line-height: 1;
  margin: 0 0 0 10px;
  color: #1D1D1D;
}

.remember-lost-wrap .lost-wrap a {
  line-height: 1;
  color: #D25B5B;
}

.remember-lost-wrap .lost-wrap a:hover {
  text-decoration: underline;
}

.register-form-wrap {
  display: none;
}

/*-------- 27. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #ccc;
  border-bottom: none;
  font-weight: 600;
  font-size: 13px;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ccc;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #ff6e21;
  border-color: #ff6e21;
  color: #ffffff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h3 {
  font-size: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 600;
}

.myaccount-content .welcome a:hover {
  color: #ff6e21;
}

.myaccount-content .welcome strong {
  font-weight: 600;
  color: #ff6e21;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 20px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #343538;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #ff6e21;
  text-transform: uppercase;
  font-weight: 600;
  padding: 9px 25px;
  color: #fff;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #1f2226;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #ff6e21;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- Start My Account Page Wrapper --------*/
/*-------- 28. Checkout style ---------*/
.customer-zone > p {
  font-size: 14px;
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
  position: relative;
  color: #373737;
}

@media only screen and (max-width: 767px) {
  .customer-zone > p {
    padding: 14px 30px 14px 40px;
  }
}

.customer-zone > p:before {
  content: "\e64d";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: 'themify';
  left: 15px;
  top: 15px;
  font-size: 18px;
}

.checkout-login-info {
  margin: 20px 0 0;
  display: none;
}

.checkout-login-info p {
  font-size: 14px;
  margin: 0 0 12px;
}

.checkout-login-info form {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .sin-checkout-login {
  margin-bottom: 30px;
}

.checkout-login-info form .sin-checkout-login label {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.checkout-login-info form .sin-checkout-login label span {
  color: red;
}

.checkout-login-info form .sin-checkout-login input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
}

.checkout-login-info form .sin-checkout-login input:focus {
  border: 1px solid #343538;
}

.checkout-login-info form .button-remember-wrap {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .button-remember-wrap button {
  border: 1px solid #aaa;
  font-size: 13px;
  padding: 10px 30px 9px;
  display: inline-block;
  float: left;
  margin-right: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.checkout-login-info form .button-remember-wrap button:hover {
  background-color: #ff6e21;
  color: #fff;
  border: 1px solid #ff6e21;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn {
  display: flex;
  margin: 8px 0 0;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn input {
  height: 15px;
  margin: 0;
  position: relative;
  top: 5px;
  width: 17px;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn label {
  font-size: 14px;
  margin: 0 0 0 5px;
}

.checkout-login-info form .lost-password {
  margin: 20px 0 11px;
}

.checkout-login-info form .lost-password a {
  font-size: 14px;
}

.checkout-login-info .checkout-login-social span {
  font-size: 14px;
  margin: 0 0 13px;
  display: block;
}

.checkout-login-info .checkout-login-social ul li {
  display: inline-block;
  margin: 0 14px 0 0;
}

.checkout-login-info .checkout-login-social ul li:last-child {
  margin: 0 0px 0 0;
}

@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li {
    margin: 0 5px 0 0;
  }
}

.checkout-login-info .checkout-login-social ul li a {
  background-color: #f5f5f5;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  float: left;
  font-size: 13px;
  padding: 20px;
  padding: 24px 106px 25px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 24px 70px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 15px 13px 16px;
  }
}

.checkout-login-info .checkout-login-social ul li a:hover {
  background-color: #ff6e21;
  color: #fff;
}

.checkout-login-info2 {
  display: none;
  margin: 20px 0 0;
}

.checkout-login-info2 ul li {
  display: inline-block;
}

.checkout-login-info2 ul li a {
  color: #8a8a8a;
  font-size: 12px;
}

.checkout-login-info2 ul li a:hover {
  color: #ff6e21;
}

.checkout-login-info3 {
  display: none;
  margin-top: 20px;
}

.checkout-login-info3 form input[type="text"] {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  width: 48%;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info3 form input[type="text"]:focus {
  border: 1px solid #1f2226;
}

.checkout-login-info3 form input[type="submit"] {
  background: #1f2226 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 6px;
  padding: 5px 30px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  width: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 16px;
  }
}

.checkout-login-info3 form input[type="submit"]:hover {
  background-color: #ff6e21;
}

.billing-info-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 0 0 55px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap h3 {
    margin-bottom: 40px;
  }
}

.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #ff6e21;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 14px;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .billing-select select {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #333;
  font-size: 14px;
  height: 50px;
  padding: 2px 20px;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 18px center;
  cursor: pointer;
}

.billing-info-wrap .billing-select select option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  color: #333;
  font-size: 12px;
}

.billing-info-wrap .checkout-account input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
  color: #333;
}

.billing-info-wrap .checkout-account span {
  color: #333;
  font-weight: 400;
  margin: 0 0 0 12px;
  font-size: 14px;
  text-transform: uppercase;
}

.billing-info-wrap .checkout-account-toggle label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .checkout-account-toggle label abbr {
  color: red;
}

.billing-info-wrap .checkout-account-toggle input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  color: #333;
  font-size: 14px;
  padding: 2px 20px;
}

.billing-info-wrap .checkout-account-toggle input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .different-address {
  display: none;
}

.billing-info-wrap .additional-info-wrap {
  margin: 22px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  color: #010225;
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #333;
  font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-50 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

.your-order-area {
  padding: 40px 50px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 0 0 45px;
  text-transform: uppercase;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #ff6e21;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #e8e8e8;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  font-size: 14px;
  padding: 0 0 7px;
  text-transform: uppercase;
  font-weight: 500;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
  color: #ff6e21;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping {
  padding: 15px 0 13px;
  display: block;
  overflow: hidden;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping ul li > p {
  float: right;
  font-size: 14px;
  text-align: right;
  color: #333;
  text-transform: capitalize;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #e8e8e8;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 17px 0 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 20px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 0 0 21px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}

.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 14px;
  margin: 0;
}

.your-order-area .Place-order a {
  background-color: #ff6e21;
  display: block;
  cursor: pointer;
  padding: 16px 50px 17px;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #333;
}

@media only screen and (max-width: 767px) {
  .checkout-main-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 29. Coming soon style ---------*/
.coming-soon-content h1 {
  font-size: 100px;
  color: #fff;
  line-height: 1;
  margin: 0;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-content h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-content h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content h1 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content h1 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coming-soon-content h1 {
    font-size: 40px;
  }
}

.coming-soon-content .coming-soon-timer {
  margin: 60px 0 36px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content .coming-soon-timer {
    margin: 40px 0 36px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content .coming-soon-timer {
    margin: 30px 0 30px;
  }
}

.coming-soon-content .coming-soon-timer div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.coming-soon-content .coming-soon-timer div > span {
  color: #fff;
  margin: 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content .coming-soon-timer div > span {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content .coming-soon-timer div > span {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content .coming-soon-timer div > span {
    margin: 0 10px;
  }
}

.coming-soon-content .coming-soon-timer div > span > span {
  font-size: 32px;
}

.coming-soon-content .coming-soon-timer div > span > p {
  color: #fff;
  font-size: 18px;
  margin: 7px 0 0;
}

.coming-soon-btn a, .error-btn a {
  display: inline-block;
  line-height: 1;
  color: #fff;
  border: 2px solid #fff;
  text-transform: uppercase;
  z-index: 2;
  font-size: 16px;
  padding: 18px 33px;
  transition: all .5s ease-in-out 0s;
}

.coming-soon-btn a:hover, .error-btn a:hover {
  border: 2px solid transparent;
}

/*-------- 30. Contact style ---------*/
.contact-form-area {
  margin-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-area {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-area {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-area {
    margin-right: 0px;
  }
}

.contact-form-area h2 {
  font-weight: bold;
  font-size: 32px;
  margin: 0 0 42px;
  color: #262626;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-area h2 {
    font-size: 25px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-area h2 {
    font-size: 25px;
    margin: 0 0 20px;
  }
}

.contact-form-area input, .contact-form-area textarea, .contact-form-area select {
  height: 70px;
  background-color: transparent;
  border: 1px solid #D8D8D8;
  color: #5d5d5d;
  padding: 2px 23px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .contact-form-area input, .contact-form-area textarea, .contact-form-area select {
    height: 50px;
  }
}

.contact-form-area input::-webkit-input-placeholder, .contact-form-area textarea::-webkit-input-placeholder, .contact-form-area select::-webkit-input-placeholder {
  color: #5d5d5d;
  opacity: 1;
}

.contact-form-area textarea {
  padding: 20px 20px;
  height: 180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-area textarea {
    height: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-area textarea {
    height: 120px;
  }
}

.contact-form-area select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-size: 9px 6px;
  background-position: right -10px center;
  background-origin: content-box;
  background-repeat: no-repeat;
  max-width: 100%;
}

.contact-form-area button.submit {
  background-color: #181818;
  color: #fff;
  padding: 13px 45px 13px;
  border: none;
  font-weight: bold;
}

.contact-form-area button.submit:hover {
  background-color: #ff6e21;
}

@media only screen and (max-width: 767px) {
  .contact-info-area {
    margin: 30px 0 0px;
  }
}

.contact-info-area h2 {
  font-weight: bold;
  font-size: 32px;
  margin: 0 0 42px;
  color: #262626;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area h2 {
    font-size: 25px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area h2 {
    font-size: 25px;
    margin: 0 0 20px;
  }
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-address {
  display: flex;
  align-items: center;
  margin: 0 0 13px;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-address i {
  color: #2F2F2F;
  font-size: 20px;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap .contact-address span {
  font-weight: bold;
  color: #282828;
  margin: 0 0 0 14px;
  display: inline-block;
}

.contact-info-area .contact-info-top .sin-contact-info-wrap p {
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.contact-info-area .contact-info-bottom {
  border-top: 1px solid rgba(119, 119, 119, 0.2);
  margin-top: 34px;
  padding-top: 33px;
}

.contact-info-area .contact-info-bottom ul li {
  font-size: 12px;
  margin: 0 0 15px;
  color: #262626;
}

.contact-info-area .contact-info-bottom ul li:last-child {
  margin-bottom: 0;
}

.contact-info-area .contact-info-bottom ul li i {
  font-size: 20px;
  position: relative;
  top: 5px;
  margin-right: 14px;
}

.contact-info-area .contact-info-bottom .contact-info-social {
  margin: 40px 0 0;
}

.contact-info-area .contact-info-bottom .contact-info-social a {
  font-size: 20px;
  margin-right: 17px;
}

#contact-map {
  height: 300px;
}

/*-------- 31. Faq style ---------*/
.faq-title h2 {
  font-weight: bold;
  font-size: 32px;
  margin: 0 0 42px;
  color: #000;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-title h2 {
    font-size: 25px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-title h2 {
    font-size: 25px;
    margin: 0 0 20px;
  }
}

.faq-accordion {
  border: 1px solid #e3e3e3;
  border-radius: 0px;
  background-color: #fff;
}

.faq-accordion .panel-heading h4 {
  font-size: 14px;
  margin: 0;
  background-color: #fff;
}

.faq-accordion .panel-heading h4 a {
  letter-spacing: 1px;
  color: #ff6e21;
  font-size: 12px;
  display: block;
  position: relative;
  padding: 27px 30px;
  font-weight: bold;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .faq-accordion .panel-heading h4 a {
    padding: 20px 30px 20px 20px;
  }
}

.faq-accordion .panel-heading h4 a.collapsed {
  position: relative;
  color: #181818;
  font-weight: 400;
}

.faq-accordion .panel-heading h4 a.collapsed::before {
  position: absolute;
  right: 13px;
  height: 2px;
  background-color: #111111;
  width: 12px;
  top: 50%;
  margin-top: -2px;
  content: "";
  transition: all .3s ease 0s;
}

.faq-accordion .panel-heading h4 a.collapsed::after {
  content: "";
  width: 2px;
  height: 12px;
  position: absolute;
  background-color: #111111;
  top: 49%;
  transform: translateY(-50%);
  right: 18px;
  transition: all .3s ease 0s;
}

.faq-accordion .panel-heading h4 a::before {
  position: absolute;
  right: 13px;
  height: 2px;
  background-color: #343538;
  width: 12px;
  top: 50%;
  margin-top: -2px;
  content: "";
  transition: all .3s ease 0s;
}

.faq-accordion .panel-collapse .panel-body p {
  line-height: 18px;
  font-size: 12px;
  margin: 0;
  color: #8a8a8a;
  text-transform: uppercase;
  padding: 0px 60px 42px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-accordion .panel-collapse .panel-body p {
    padding: 0px 30px 42px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-accordion .panel-collapse .panel-body p {
    padding: 0px 30px 42px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-accordion .panel-collapse .panel-body p {
    padding: 0px 30px 42px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-faq-wrap.pt-120 {
    padding-top: 70px;
  }
  .single-faq-wrap.pb-100 {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-faq-wrap .faq-title.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-faq-wrap.pt-120 {
    padding-top: 50px;
  }
  .single-faq-wrap.pb-100 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-faq-wrap .faq-title h2 {
    font-size: 21px;
  }
  .single-faq-wrap .faq-title.mb-60 {
    margin-bottom: 20px;
  }
}

/*-------- 32. Compare style ---------*/
.compare-table .table tbody tr th.first-column {
  min-width: 200px;
  color: #262626;
  margin: 0;
  font-weight: 500;
  padding: 10px 10px;
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 150px;
    padding: 10px 20px;
  }
}

.compare-table .table tbody tr:first-child th {
  padding-top: 122px;
}

.compare-table .table tbody tr td {
  text-align: center;
  padding: 11px 15px;
  vertical-align: middle;
  border-color: #e7e7e7;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 293px;
  vertical-align: bottom;
  padding: 9px 10px 17px;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 220px;
  }
}

.compare-table .table tbody tr td.product-image-title .compare-remove a {
  font-size: 13px;
  color: #535353;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a i {
  padding-right: 2px;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a:hover {
  color: #ff6e21;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin: 2px 0 6px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: auto;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #ff6e21;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .pro-title {
  display: block;
  overflow: hidden;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a {
  float: left;
  clear: both;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 5px;
  display: block;
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a:hover {
  color: #ff6e21;
}

.compare-table .table tbody tr td.product-image-title .compare-btn {
  margin: 14px 0 0;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a {
  background-color: #343538;
  padding: 5px 13px 6px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a:hover {
  background-color: #ff6e21;
}

.compare-table .table tbody tr td.pro-desc p {
  margin: 0;
  font-size: 13px;
  line-height: 1.88;
  color: #535353;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 14px;
  font-weight: 400;
  color: #343538;
}

.compare-table .table tbody tr td.pro-sku, .compare-table .table tbody tr td.pro-weight, .compare-table .table tbody tr td.pro-dimensions {
  font-size: 13px;
  font-weight: 400;
  color: #535353;
}

.compare-table .table tbody tr td.pro-stock p {
  font-size: 13px;
  color: #535353;
  line-height: 1;
}

/*------ end Compare Page Wrapper -----*/
